import styles from "./AboutEcoSticker.module.scss";
import { Container } from "@mui/material";
import MainImage from "../assets/acticle/pic-2.jpeg";
import pic2 from "../assets/aboutecosticker/ac-2.png";
import pic3 from "../assets/aboutecosticker/ac-3.png";
import pic4 from "../assets/aboutecosticker/ac-4.png";
import pic5 from "../assets/aboutecosticker/ac-5.png";
import pic6 from "../assets/aboutecosticker/ac-6.png";
import pic7 from "../assets/aboutecosticker/ac-7.png";

import mainInfo from "./Articles/images/main-eco-1.jpeg";
import know_Eco_Sticker from "./Articles/images/know-eco-sticker.png";
import know_Eco_Sticker4 from "./Articles/images/knowing-eco-4.png";
import application_eco from "./Articles/images/new-icon.png";

import pic24 from "../assets/acticle/pic-24.png";

import { Page } from "../components/GobalElement";
import { Link } from "react-router-dom";

const AboutEcoSticker = () => {
	return (
		<Page title="รอบรู้เรื่อง “ECO Sticker”">
			<div className={styles.pageContent}>
				<Container>
					<Link to="/article/news-eco-sticker" className={styles.mainBlock}>
						<div className={styles.mainBlockLeft}>
							<img src={mainInfo} alt="main_image" draggable="false" className={styles.mainImage} />
						</div>

						<div className={styles.mainBlockRight}>
							<div>ECO Sticker ยกระดับการคุ้มครองผู้บริโภคในประเทศไทย สู่ความโปร่งใส</div>
							<span>NOV 23 ,2021</span>
						</div>
					</Link>

					<div className={styles.subContainer}>
						{/* new */}
						<Link to="/article/flow-request-eco" className={styles.subBlock}>
							<img
								src={know_Eco_Sticker4}
								alt="sub6_image"
								draggable="false"
								className={styles.subBlockImg}
							/>
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>ขั้นตอนการขอ ECO Sticker</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link>
						<Link to="/article/knowing-eco-sticker" className={styles.subBlock}>
							<img
								src={know_Eco_Sticker}
								alt="sub1_image"
								draggable="false"
								className={styles.subBlockImg}
							/>
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>
									ระบบป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (ECO Sticker)
								</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link>

						<Link to="/article/eco-sticker-ability" className={styles.subBlock}>
							<img src={pic2} alt="sub1_image" draggable="false" className={styles.subBlockImg} />
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>สมรรถนะ “สะอาด ประหยัด ปลอดภัย” คืออะไร</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link>

						<Link to="/article/eco-sticker-benefit" className={styles.subBlock}>
							<img src={pic3} alt="sub2_image" draggable="false" className={styles.subBlockImg} />
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>ประโยชน์ของ Ecosticker ในแต่ละมิติ</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link>

						<Link to="/article/what-is-tis" className={styles.subBlock}>
							<img src={pic4} alt="sub3_image" draggable="false" className={styles.subBlockImg} />
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>มาตรฐานสิ่งแวดล้อมใน Ecosticker มีอะไรบ้าง</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link>

						{/* <Link to="/article/eco-sticker-safety" className={styles.subBlock}>
							<img src={pic24} alt="sub4_image" draggable="false" className={styles.subBlockImg} />
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>มาตรฐานความปลอดภัยใน Ecosticker มีอะไรบ้าง</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link> */}

						<Link to="/article/what-is-international-standard" className={styles.subBlock}>
							<img src={pic6} alt="sub5_image" draggable="false" className={styles.subBlockImg} />
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>มาตรฐานสากลใน Ecosticker มีอะไรบ้าง</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link>

						<Link to="/article/what-is-tax" className={styles.subBlock}>
							<img src={pic7} alt="sub6_image" draggable="false" className={styles.subBlockImg} />
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>อัตราภาษีสรรพสามิต</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link>

						<Link to="/article/mobile-application" className={styles.subBlock}>
							<img
								src={application_eco}
								alt="sub6_image"
								draggable="false"
								className={styles.subBlockImg}
							/>
							<div className={styles.subBlockContent}>
								<div className={styles.subTextBox}>Mobile Application</div>
								<span>NOV 23 ,2021</span>
							</div>
						</Link>
					</div>
				</Container>
			</div>

			{/* <div className={styles.justifyContentCenter}>
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid container justifyContent="center" item xs={2} sm={9} md={9} spacing={0}>
                            <div className={styles.mainBlock}>
        
                                
                            </div>
                        </Grid>
                    </Grid>
                </div> */}

			{/* <div className={styles.justifyContentCenter}>
                    <div style={{ backgroundColor: 'red', height: '425px', width: '1254px' }}>
                    
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid container justifyContent="center" item xs={2} sm={4} md={3} spacing={0}>
                                <div className={styles.subBlock}>
                                    <img src={Sub1} alt="sub1_image"/>
                                    <div className={styles.subBlockContent}>
                                        <div>
                                            Ecosticker คืออะไร? มีประโยชน์อะไร
                                            ที่เป็นประโยชน์ต่อผู้บริโภคบ้าง
                                        </div>
                                        <a>
                                            NOV 23 ,2021
                                        </a>
                                    </div>
                                </div>
                            </Grid>

                            <Grid container justifyContent="center" item xs={2} sm={4} md={3} spacing={0}>
                                <div className={styles.subBlock}>
                                    <img src={Sub2} alt="sub2_image"/>
                                    <div className={styles.subBlockContent}>
                                        <div>
                                            Ecosticker คืออะไร? มีประโยชน์อะไร
                                            ที่เป็นประโยชน์ต่อผู้บริโภคบ้าง
                                        </div>
                                        <a>
                                            NOV 23 ,2021
                                        </a>
                                    </div>
                                </div>
                            </Grid>

                            <Grid container justifyContent="center" item xs={2} sm={4} md={3} spacing={0}>
                                <div className={styles.subBlock}>
                                    <img src={Sub3} alt="sub3_image"/>
                                    <div className={styles.subBlockContent}>
                                        <div>
                                            Ecosticker คืออะไร? มีประโยชน์อะไร
                                            ที่เป็นประโยชน์ต่อผู้บริโภคบ้าง
                                        </div>
                                        <a>
                                            NOV 23 ,2021
                                        </a>
                                    </div>
                                </div> 
                            </Grid>
                        </Grid>
                    </div>
                </div> */}
			{/* </div> */}
		</Page>
	);
};

export default AboutEcoSticker;
