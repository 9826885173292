import { Slide } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React, { createRef } from 'react';
import { Outlet } from 'react-router-dom';
import SnackbarContents from 'src/components/SnackbarContent';
import { DetailProvider } from '../contexts/detailContext';

const DetailLayout = () => {
	const notistackRef = createRef();
	return (
		<DetailProvider>
			<SnackbarProvider
				ref={notistackRef}
				maxSnack={3}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				TransitionComponent={Slide}
				content={(key, option) => (
					<SnackbarContents id={key} option={option} />
				)}
			>
				<Outlet />
			</SnackbarProvider>

		</DetailProvider>
	);
};

export default DetailLayout;
