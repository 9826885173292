import { Button, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { Page } from "src/components/GobalElement";
import styles from "./Articles.module.scss";
import styled from "@emotion/styled";
import { Box, borderRadius } from "@mui/system";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ImageContent } from "./KnowingEcoSticker";
import ImageMainPage from "./images/main-page.png";
// image
import ImageEcoSticker1 from "./images/knowing-eco-1.png";
import ImageEcoSticker2 from "./images/knowing-eco-2.png";
import ImageEcoSticker3 from "./images/knowing-eco-3.png";
import ImageEcoSticker4 from "./images/knowing-eco-4.png";
import ImageEcoSticker5 from "./images/knowing-eco-5.png";
import ImageEcoSticker6 from "./images/knowing-eco-6.png";
//
import ImageEcoSticker7 from "./images/content-flow-eco1.png";
import ImageEcoSticker8 from "./images/content-flow-eco2.png";
import { Link } from "react-router-dom";

const DATA_CONTENT_SECTION_1 = [
	"ผู้ผลิตหรือผู้นำเข้า ลงทะเบียนเปิด Account -> ได้รับ Username & Password",
	"ผู้ผลิตหรือผู้นำเข้า Login เข้าสู่ระบบ และยื่นข้อมูล หลักฐาน เอกสาร ของรถยนต์ที่จะขอ ECO Sticker",
	"สำนักงานเศรษฐกิจอุตสาหกรรม สำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม กรมสรรพสามิต และ บริษัท Technical Service ของ UN ทั่วโลก เข้าตรวจเอกสารที่หน่วยงานนั้นเป็นผู้รับผิดชอบ",
	"ระบบสร้าง ECO Sticker และส่งกลับให้ ผู้ผลิตหรือผู้นำเข้าที่ยื่นขอ",
	"ผู้ผลิตหรือผู้นำเข้า นำ ECO Sticker ไปยื่นเป็นหลักฐานขอลดหย่อนภาษี กับ กรมศุลกากรหรือกรมสรรพสามิต",
	"ผู้ผลิตหรือผู้นำเข้านำ ECO Sticker ไปติดที่กระจกรถยนต์ใหม่คันนั้น ก่อนส่งไปยังสถานที่แสดงหรือจัดจำหน่ายรถยนต์",
];

const DATA_CONTENT_SECTION_2 = [
	"การลงทะเบียนของผู้ประกอบการ (ผู้ผลิต/ผู้นำเข้า)",
	"การยื่นเอกสารผลทดสอบของผู้ประกอบการ",
	"ระบบแสดงสถานะผลการตรวจสอบของแต่ละหน่วยงาน",
	"การสร้างใบปะหน้าสรุปเอกสารผลทดสอบเพื่ออำนวยความสะดวกในการยื่นชำระภาษีสรรพสามิต",
	"การสร้าง ECO Sticker เพื่อติดแสดงบนรถยนต์ทุกคัน",
	"ฐานข้อมูลเพื่อการติดตามตรวจสอบของภาครัฐ",
];

const DATA_CONTENT_SECTION_TABLE = [
	{
		header: "บริษัท/บุคคล",
		data: [
			"ชื่อ (ไทย/อังกฤษ)",
			"ที่อยู่, เบอร์โทร, เว็บไซต์",
			"เลขประจำตัวผู้เสียภาษี, เลขทะเบียนสรรพสามิต, เลขทะเบียนกรมพัฒนาธุรกิจการค้า",
			"โลโก้บริษัท, ใบประกอบกิจการบริษัท, หนังสือรับรองบริษัท,ใบทะเบียนสรรพสามิตบริษัท/ใบทะเบียนกรมพัฒนาธุรกิจการค้า (เอกสารแนบ)",
		],
	},
	{
		header: "ผู้บริหาร",
		data: ["ชื่อ", "เลขประจำตัวประชาชน", "เบอร์โทร, อีเมล", "สำเนาบัตรประชาชน (เอกสารแนบ)"],
	},
	{
		header: "ผู้ประสานงาน",
		data: [
			"ชื่อ",
			"เลขประจำตัวประชาชน",
			"เบอร์โทร, อีเมล",
			"สำเนาบัตรประชาชน (เอกสารแนบ)",
			"หนังสือมอบอำนาจให้ผู้ประสานงานดำเนินการเกี่ยวกับป้ายข้อมูลรถยนต์ (เอกสารแนบ)",
		],
	},
];

const DATA_CONTENT_BACKGROUD = [
	"มติคณะรัฐมนตรี เมื่อวันที่ 18 ธันวาคม 2555 ได้อนุมัติในหลักการร่างประกาศกระทรวงการคลัง เรื่อง การปรับโครงสร้างภาษีสรรพสามิตรถยนต์ (ภาษี CO2) ตามที่กระทรวงการคลังเสนอ ควบคู่ไปกับการมอบหมายให้กระทรวงอุตสาหกรรมกำหนดแนวทางให้ผู้ประกอบอุตสาหกรรมยานยนต์และผู้นำเข้าต้องติดป้ายแสดงการประหยัดพลังงานและปริมาณการปล่อยก๊าซคาร์บอนไดออกไซด์ (CO2) หรือ ECO Sticker เพื่อเป็นกลไกสำคัญที่จะนำมาใช้ดำเนินการควบคู่กัน เพื่อให้สามารถบรรลุวัตถุประสงค์ของมติคณะรัฐมนตรีดังกล่าวได้อย่างมีประสิทธิภาพ",
	"กระทรวงอุตสาหกรรม (สำนักงานเศรษฐกิจอุตสาหกรรม สำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม และสถาบันยานยนต์) และกระทรวงการคลัง (กรมสรรพสามิต กรมศุลกากร) ได้ร่วมมือกันในการเตรียมระบบงานเพื่อรองรับการดำเนินการตามมติคณะรัฐมนตรี",
	"การเตรียมการในส่วนของ ECO Sticker และระบบเทคโนโลยีสารสนเทศเพื่อรองรับการอนุมัติ ECO Sticker พร้อมแล้ว โดยสามารถรับยื่นเอกสารข้อมูลจากผู้ประกอบการรถยนต์ และสร้าง ECO Sticker ได้โดยอัตโนมัติ รวมทั้ง หน่วยงาน ที่เกี่ยวข้องสามารถเข้าถึงฐานข้อมูลเพื่อการตรวจสอบ และนำไปใช้ประโยชน์ในการจัดเก็บภาษีสรรพสามิตรถยนต์ตามปริมาณการปล่อย CO2 ของรถยนต์ภายใต้โครงสร้างภาษีสรรพสามิตรถยนต์ใหม่ ที่มีผลบังคับใช้เมื่อวันที่ 1 มกราคม 2559 นี้ได้โดยสะดวก",
	"สำนักงานเศรษฐกิจอุตสาหกรรมได้ออกแบบและพัฒนาระบบดิจิทัลสำหรับการขออนุมัติ ECO Sticker ตามแนวทาง Government 4.0 ซึ่งเป็นการดำเนินงานตามมติคณะรัฐมนตรี ที่ได้มอบหมายให้กระทรวงอุตสาหกรรมพัฒนาระบบป้ายข้อมูลเพื่อใช้ในการจัดเก็บภาษีสรรพสามิตรถยนต์ใหม่",
];

const PROCESS_ECO_STICKER = [
	"เมื่อลงทะเบียนผ่านแล้ว จะต้องเพิ่มข้อมูลกิจการหรือโรงงานของตนเอง หลังจากนั้น ไปที่หน้าหลักของระบบ i-Industry เลือกบริการยื่นขอใบอนุญาตออนไลน์ (e-License) เลือกคลิกเมนู ECO Sticker ตามประเภทผลิตภัณฑ์",
	"ลงทะเบียนในระบบป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (ECO Sticker) อีกครั้ง หลังจากนั้น ระบบจะส่ง Username และ Password ให้กับผู้ผลิต/ผู้นำเข้าทางอีเมล (โดย Username นี้จะนำมาใช้ในการเข้าใช้งานระบบ ECO Sticker ในครั้งแรกเท่านั้น)",
	"ผู้ผลิต/ผู้นำเข้ายื่นข้อมูล หลักฐาน เอกสารของรถยนต์ที่จะขอ ECO Sticker",
	"สำนักงานเศรษฐกิจอุตสาหกรรม (สศอ.) สำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม (สมอ.) กรมสรรพสามิต และหน่วยทดสอบ (Technical Service) เข้าตรวจเอกสารที่หน่วยงานนั้นเป็นผู้รับผิดชอบ",
	"ระบบสร้าง ECO Sticker และส่งกลับให้ ผู้ผลิต/ผู้นำเข้า ที่ยื่นขอ",
	"ผู้ผลิต/ผู้นำเข้า นำ ECO Sticker ไปยื่นเป็นหลักฐานขอลดหย่อนภาษีกับกรมสรรพสามิต หรือกรมศุลกากร",
	"ผู้ผลิต/ผู้นำเข้า นำ ECO Sticker ไปติดไว้ที่กระจกบังลมด้านหน้าหรือกระจกด้านข้าง ในตำแหน่งที่สามารถอ่านข้อมูลของป้ายจากด้านนอกตัวรถได้อย่างสะดวกและชัดเจน บนรถยนต์ใหม่ทุกคัน ก่อนที่จะส่งไปยังผู้จำหน่ายรถยนต์ในเครือข่าย (Dealer) หรือสถานที่จัดแสดงรถยนต์ หรือสถานที่จำหน่ายรถยนต์",
];

const FlowReuestEcoSticker = () => {
	return (
		<Page title="ขั้นตอนการขอ ECO Sticker">
			<div className={styles.pageContent}>
				<Container>
					<Stack spacing={4}>
						<Typography
							textAlign={"center"}
							variant="span"
							fontSize={48}
							fontWeight={400}
							color={"#008000"}
						>
							ขั้นตอนการขอ ECO Sticker
						</Typography>
						<Stack spacing={2}>
							<Typography
								textAlign={"center"}
								variant="span"
								fontSize={32}
								fontWeight={400}
								color={"#008000"}
							>
								ความเป็นมา
							</Typography>
							{DATA_CONTENT_BACKGROUD.map((data, idx) => (
								<Stack key={idx} direction={"row"} alignItems="baseline" spacing={1}>
									<FiberManualRecordIcon fontSize={"16"} color="success" />
									<Typography fontSize={16} fontWeight={400} variant="p">
										{data}
									</Typography>
								</Stack>
							))}
							<Stack spacing={3} mt={2}>
								<BoxDigitalEconomy>
									<Typography fontSize={18} fontWeight={400} variant="p">
										การดำเนินงานในช่วงปี 2556-2558
									</Typography>
								</BoxDigitalEconomy>
								<Stack direction={"row"} spacing={1}>
									<ImageContent>
										<img src={ImageEcoSticker7} />
									</ImageContent>
									<ImageContent>
										<img src={ImageEcoSticker8} />
									</ImageContent>
								</Stack>
							</Stack>
						</Stack>
						<Stack spacing={2}>
							<BoxDigitalEconomy>
								<Typography fontSize={18} fontWeight={400} variant="p">
									Cloud Based Application
								</Typography>
							</BoxDigitalEconomy>
							<Typography sx={{ textIndent: "50px" }} fontSize={18} fontWeight={400} variant="p">
								ออกแบบระบบ ECO Sticker เป็น Cloud Based Application อย่างสมบูรณ์แบบ
							</Typography>
							{DATA_CONTENT_SECTION_2.map((data, idx) => (
								<Stack direction={"row"} alignItems={"center"} spacing={1}>
									<FiberManualRecordIcon fontSize={"16"} color="success" />
									<Typography fontSize={16} fontWeight={400} variant="p">
										{data}
									</Typography>
								</Stack>
							))}
							<Stack alignItems={"center"}>
								<ImageContent width={"70%"}>
									<img src={ImageEcoSticker2} />
								</ImageContent>
							</Stack>
						</Stack>
						<Stack spacing={2}>
							<BoxDigitalEconomy>
								<Typography fontSize={18} fontWeight={400} variant="p">
									ข้อมูลและเอกสาร
								</Typography>
							</BoxDigitalEconomy>
							<Stack p={2} width={"100%"}>
								<TableContainer>
									{DATA_CONTENT_SECTION_TABLE.map((item, i) => {
										return (
											<tr>
												<td>
													<Typography fontSize={16} fontWeight={400} variant="p">
														{item.header}
													</Typography>
												</td>
												<td align="left">
													{item.data.map((data, idx) => (
														<Stack direction={"row"} alignItems={"center"} spacing={1}>
															<FiberManualRecordIcon fontSize={"16"} color="success" />
															<Typography fontSize={16} fontWeight={400} variant="p">
																{data}
															</Typography>
														</Stack>
													))}
												</td>
											</tr>
										);
									})}
								</TableContainer>
							</Stack>
							<Stack direction={"row"} alignItems={"center"} spacing={1}>
								<FiberManualRecordIcon fontSize={"16"} color="success" />

								<Typography fontSize={16} fontWeight={400} variant="p">
									ทั้งนี้ ระบบได้นำข้อมูลบางส่วนจากระบบทะเบียนลูกค้ากระทรวงอุตสาหกรรม (i-Industry)
									มาแสดง (โดยไม่ต้องกรอกข้อมูลใหม่) เพื่อความสะดวก และรวดเร็วมากขึ้น
								</Typography>
							</Stack>
							<Stack alignItems={"center"}>
								<ImageContent width={"70%"}>
									<img src={ImageEcoSticker3} />
								</ImageContent>
							</Stack>
						</Stack>
						<BoxDigitalEconomy>
							<Typography fontSize={18} fontWeight={400} variant="p">
								Digital Economy : Cloud Based Application
							</Typography>
						</BoxDigitalEconomy>
						<Stack p={3} pt={0} spacing={1}>
							<Stack spacing={2}>
								<Typography sx={{ textIndent: 50 }} fontSize={16} fontWeight={400} variant="p">
									การนำนวัตกรรมและเทคโนโลยี Cloud Based Application
									ซึ่งเป็นระบบปฏิบัติการที่มีความทันสมัย มีความคล่องตัว สะดวกรวดเร็ว
									และมีประสิทธิภาพในการใช้ทรัพยากร มาใช้ในการขออนุมัติ ECO Sticker
									ทางออนไลน์อย่างเต็มรูปแบบในทุกขั้นตอน
								</Typography>
								<Typography texti variant="span" fontSize={20} fontWeight={400} color={"#008000"}>
									ขั้นตอนการดำเนินงานเกี่ยวกับ ECO Sticker
								</Typography>
								<Stack key={"link"} direction={"row"} alignItems="baseline" spacing={1}>
									<FiberManualRecordIcon fontSize={"16"} color="success" />
									<Typography fontSize={16} fontWeight={400} variant="p">
										ลงทะเบียนในระบบทะเบียนลูกค้ากระทรวงอุตสาหกรรม (i-Industry) ได้ที่ลิงค์{" "}
										<TextLink
											onClick={() => {
												window.open("https://i.industry.go.th/register/");
											}}
										>
											https://i.industry.go.th/register/
										</TextLink>
									</Typography>
								</Stack>
								{PROCESS_ECO_STICKER.map((data, idx) => (
									<Stack key={idx} direction={"row"} alignItems="baseline" spacing={1}>
										<FiberManualRecordIcon fontSize={"16"} color="success" />
										<Typography fontSize={16} fontWeight={400} variant="p">
											{data}
										</Typography>
									</Stack>
								))}
							</Stack>
						</Stack>
					</Stack>
				</Container>
			</div>
		</Page>
	);
};

export const BoxDigitalEconomy = styled(Box)({
	padding: 15,
	color: "#31708f",
	backgroundColor: "#d9edf7",
	borderRadius: 10,
	// border-bottom: solid 3px #019fb6;
});

const TableContainer = styled("table")({
	borderCollapse: "collapse",
	border: "1px solid #ddd",
	tr: {
		padding: 10,
		borderRadius: "5px",
		border: "1px solid #ddd",
	},
	td: {
		padding: 10,
		borderRadius: "5px",
		border: "1px solid #ddd",
	},
});

const TextLink = styled("a")({
	color: "green",
	cursor: "pointer",
	":hover": {
		color: "blue",
	},
});

export default FlowReuestEcoSticker;

//
