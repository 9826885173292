import { createRef } from 'react';
import { Outlet } from 'react-router-dom';
import { MainStyle, RootStyle } from '../components/GobalElement';
import { Footer, Navbar, NavbarSm } from '../components/layout';
import { Hidden } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import SnackbarContents from 'src/components/SnackbarContent';
import { Slide } from '@mui/material';

export const CompanyLayout = () => {
    const notistackRef = createRef();
    return (
        <RootStyle>
            <Hidden implementation='css' smDown>
                <Navbar />
            </Hidden>
            <Hidden implementation='css' smUp>
                <NavbarSm />
            </Hidden>
            <MainStyle>
                <SnackbarProvider
                    ref={notistackRef}
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    TransitionComponent={Slide}
                    content={(key, option) => (
                        <SnackbarContents id={key} option={option} />
                    )}
                >
                    <Outlet />
                </SnackbarProvider>
            </MainStyle>
            <Footer />
        </RootStyle>
    );
}
export default CompanyLayout