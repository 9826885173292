import styles from "./NotFound.module.scss";
import Pic from "../assets/not-found-page.png";
import { Page } from "./GobalElement";
import { Link } from "react-router-dom";
import classNames from "classnames";

const NotFound = ({ code, detail, goBack = true, sm, title }) => {
    return (
        <Page title={title}>
            <div className={styles.pageContainer}>
                <div className={styles.pageBox}>
                    <img src={Pic} className={classNames(styles.pageImg, { [styles.pageImgSm]: sm })} />
                    {code && <span className={styles.pageCode}>{code}</span>}
                    <span className={styles.pageDetail}>{detail ? `${detail}` : 'ไม่พบหน้าที่ต้องการ'}</span>
                    {
                        goBack &&
                        <>
                            <div className={styles.pageEnd} />
                            <Link to="/" className={styles.pageGoHome}>
                                กลับหน้าแรก
                            </Link>
                        </>
                    }

                </div>
            </div>
        </Page>
    );
};

export default NotFound