import { Container, Breadcrumbs } from '@mui/material';
import styles from "./AutomotiveEventWithAPI.module.scss";
import { Page } from "../../components/GobalElement";
import React, { useEffect, useState } from "react";
import { useLocation, useParams, Link } from "react-router-dom";
import { EventData } from './AutomotiveEventJSON'
import ModalViewImg from '../../components/ModalViewImg';
import { getNewsById } from 'src/helper/allAPI';
import { useSnackbar } from 'notistack';
import { ErrorSnackBarContent } from 'src/components/SnackbarContent';

import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";
import "../../styles/image-gallery.css"
import "./AutomotiveEventWithAPI.module.scss"
// # scss file import
// import "~react-image-gallery/styles/scss/image-gallery.scss";
// css file import
// import "~react-image-gallery/styles/css/image-gallery.css";
// js file import (using webpack)
// import "react-image-gallery/styles/css/image-gallery.css";
const justifyCenter = {
    display: 'flex',
    justifyContent: 'center'
}

const AutomotiveEventWithAPI = () => {
    const location = useLocation()
    const params = useParams()
    const parse = require('html-react-parser');

    const [modalOpen, setModalOpen] = useState(false);
    const [dataSet, setDataSet] = useState([]);
    const [newsData, setNewsData] = useState({})
    const [modalImageIndex, setModalImageIndex] = useState(0)
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        initNewsDataState()
    }, [])

    useEffect(() => {
        imageList()
        let elem = document.getElementsByTagName('body')
        if (modalOpen) {
            elem[0].classList.add("on-modale-open")
        } else {
            setModalImageIndex(0)
            elem[0].classList.remove("on-modale-open")
        }
    }, [modalOpen])

    const viewImage = (imageIndex) => {
        setModalOpen(true)
    }

    const imageList = () => {
        if (EventData[parseInt(params.id) - 1]) {
            const img = EventData[parseInt(params.id) - 1].img
            let fetchData = img.map((el) => (
                el.path
            ));
            setDataSet(fetchData)
        } else {
            if (newsData.img && newsData.img.length > 0) {
                setDataSet(newsData.img.map((el) => (el.path)))
            }
        }
    }

    const initNewsDataState = async () => {
        // const newsOld = EventData[parseInt(params.id) - 1]
        // if (newsOld) {
        //     setNewsData({
        //         ...newsOld,
        //         gallery: newsOld.img.map((el) => ({ original: el.path, thumbnail: el.path }))
        //     })
        // } else {
        //     // fetch data from api
        // }
        const news = await fetchNewsById(params.id)
        console.log("news:", news)
        setNewsData({
            id: news.id,
            title: news.title,
            display: news?.news_files[0]?.file_url,
            displayType: "img",
            detail: '',
            fullDetail: news.content,
            img: news.news_files.map((el) => ({ id: el.id, path: el.file_url })),
            gallery: news.news_files.map((el) => ({ original: el.file_url, thumbnail: el.file_url, thumbnailWidth: "" })),
            iframe: [],
            date: news.created_at,
        })
    }

    const fetchNewsById = async id => {
        try {
            const res = await getNewsById(id);
            return res.data.data
        } catch (error) {
            if (error?.response?.status === '500') {
                enqueueSnackbar({
                    text: 'เกิดข้อผิดพลาด',
                    variant: 'error',
                    content: <ErrorSnackBarContent detail="ระบบขัดข้อง กรุณาติดต่อเจ้าหน้าที่" />,
                });
            } else {
                enqueueSnackbar({
                    text: 'เกิดข้อผิดพลาด',
                    variant: 'error',
                    content: <ErrorSnackBarContent detail={error?.response?.data?.error} />,
                });
            }
        }
    }

   
    return (
        <Page title='ข่าววงการรถยนต์'>
            <div className={styles.pageContent}>
                <Container>
                    <div className={styles.container}>
                        <div>
                            <Breadcrumbs aria-label="breadcrumb">
                                <Link to='/news' className={styles.breadcrumbsInActive}>
                                    ข่าวประกาศ
                                </Link>
                                <div className={styles.breadcrumbsActive}>
                                    {newsData.title}
                                </div>
                            </Breadcrumbs>
                        </div>
                        <div className={styles.eventHeader}>{newsData.title}</div>
                        <div className={styles.text}>
                            {newsData.fullDetail && parse(newsData.fullDetail)}
                        </div>

                        {/* <div className={styles.justifyContentCenter}>
                            <ul className={styles.galleryContainer}>
                                {(Array.isArray(newsData.img) && newsData.img.length > 0) && newsData.img.map((data, index) => {
                                    return (
                                        <li className={styles.galleryBlock} key={index} onClick={() => viewImage(index)}>
                                            <img src={data.path} className={styles.galleryPicture} />
                                            <div className={styles.textOverlay}>
                                                คลิกดูภาพเต็ม
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div> */}
                    
                        {(Array.isArray(newsData.gallery) && newsData.gallery.length > 0) && (
                            <div className={styles.justifyContentCenter}>
                                <div className={styles['image-gallery-wrapper']} >
                                    <ImageGallery
                                        items={newsData.gallery}
                                        // showIndex={true}
                                        showPlayButton={false}
                                        showNav={false}
                                        showBullets={true}
                                        showFullscreenButton={true}
                                        slideDuration={450}
                                        slideInterval={2000}
                                        infinite={true}
                                    />
                                </div>
                            </div>
                        )}


                        <div className={styles.justifyContentCenter}>
                            {(Array.isArray(newsData.iframe) && newsData.iframe.length > 0) && newsData.iframe.map((data, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <div style={{ width: '45%', height: '350px' }}>
                                            <iframe
                                                width="100%"
                                                height="100%"
                                                src={data.path}
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen
                                            />
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>

                        <ModalViewImg
                            open={modalOpen}
                            list={dataSet}
                            now={modalImageIndex}
                            onClose={setModalOpen}
                            callback={setModalImageIndex}
                        />
                    </div>
                </Container>
            </div>
        </Page>
    );
};

export default AutomotiveEventWithAPI