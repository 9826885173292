import React from 'react';
import { Container } from '@mui/material';
import styles from "./Footer.module.scss";
import Logo from "../../assets/logo_web_2.png";
import News1 from "../../assets/mockup/news_footer_1.png";
import News2 from "../../assets/mockup/news_footer_2.png";

const mock_news = [
    {
        img: News1,
        detail: 'กระทรวงอุตสาหกรรมได้รับรางวัลเลิศรัฐ ประจำปี 2562 สาขาบริการภาครัฐในประเภทพัฒนาการบริาร จากสำนักงานคณะกรรมการพัฒนาระบบราชการ (ก.พ.ร.) ในผลงานระบบเทคโนโลยีสารสนเทศเพื่อรองรับป้ายข้อมูลรถยนต์ตามมาตรฐานสากล หรือ ECO sticker ของสำนักงานเศรษฐกิจอุตสาหกรรม เพื่อมุ่งมั่นพัฒนาการบริการภาครัฐสู่ Government 4.0',
        date: 'เมื่อวันที่ 13 ธันวาคม 2562'
    }
]

const NewsContent = ({ data }) => {
    const { detail, date } = data
    return (
        <div className={styles.newsContent}>
            
            <div className={styles.newsContentDetailBox}>
                <span>เมื่อวันที่ {date}</span>
                <span className={styles.newsContentDetail}>{detail}</span>
            </div>
        </div>
    )
}

const Footer = () => {
    return (
        <div className={styles.footerContainer}>
            <Container>
                <div className={styles.footerUpper}>
                    <div className={styles.footerUpperItem}>
                        <img src={Logo} className={styles.footerUpperLogo} />
                        <span>ป้ายข้อมูลรถยนต์ตามมาตรฐานสากล</span>
                        <span>"สะอาด ประหยัด ปลอดภัย"</span>
                    </div>
                    <div className={styles.footerUpperItem}>
                        <span className={styles.footerUpperTopic}>ข่าววงการรถยนต์</span>
                        <div className={styles.footerUpperNewsBox}>
                            {
                                mock_news.map((item, key) => (
                                    <NewsContent key={key} data={item} />
                                ))
                            }
                        </div>
                    </div>
                    <div className={styles.footerUpperItem}>
                        <span className={styles.footerUpperTopic}>ติดต่อเรา</span>
                        <p className={styles.footerAddress}>
                            สำนักงานเศรษฐกิจอุตสาหกรรม
                            75/6 ถนนพระรามที่ 6 เขตราชเทวี
                            กรุงเทพฯ 10400
                        </p>
                        <span>Phone: <a className={styles.footerContact} href='tel:02644942'>02-6449420</a></span>
                        <span>Email: <a className={styles.footerContact} href='mailto:infocar@oie.go.th'>infocar@oie.go.th</a></span>
                    </div>
                </div>

            </Container>
            <div className={styles.footerLowwerContainer}>
                <Container>
                    <div className={styles.footerLowwer}>
                        <div className={styles.footerLowwerItem}>
                            <span>2022 © ลิขสิทธิ์สำนักงานเศรษฐกิจอุตสาหกรรม</span>
                            <span className={styles.footerSecondary}>Privacy Policy | สงวนลิขสิทธิ์ตามพระราชบัญญัติ</span>
                        </div>
                        <div className={styles.footerLowwerItem}>
                            <div className={styles.footerLowwerIcon}>
                                <i className="fa-brands fa-facebook-f"></i>
                            </div>
                            <div className={styles.footerLowwerIcon}>
                                <i className="fa-brands fa-line"></i>
                            </div>
                            <div className={styles.footerLowwerIcon}>
                                <i className="fa-solid fa-envelope"></i>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default Footer;