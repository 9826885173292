import styles from './AnnouncementAPI.module.scss';
import AnnouncementImage from '../assets/unsplash_ovrOPhu8vSw.png';
import { Page } from '../components/GobalElement';
import ModalViewContent from '../components/ModalViewContent';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import D6 from '../assets/news/award2.jpeg';
import D5 from '../assets/news/D5-1.jpeg';
import D4 from '../assets/news/pic0.png';
import D1 from '../assets/news/D1-1.jpeg';
import D2 from '../assets/news/D2-1.jpeg';
import D3 from '../assets/news/D3-1.jpeg';
import handleAxios from 'src/helper/Axios';
import { getNewsOnLandingPage } from 'src/helper/allAPI';

import { useSnackbar } from 'notistack';
import { ErrorSnackBarContent } from 'src/components/SnackbarContent';

const AnnouncementWithAPI = () => {

	const [openModal, setOpenModal] = useState(false);
	const [content, setContent] = useState('');
	const [searchParams] = useSearchParams();
	const [page, setPage] = useState(searchParams.get('page') || 1);
	const [rowsPerPage, setRowsPerPage] = useState(searchParams.get('row') || 9);
	const [search, setSearch] = useState(searchParams.get('search') || '');
	const { enqueueSnackbar } = useSnackbar();

	const [newsData, setNewsData] = useState([]);
	const [isNewsDataError, setIsNewsDataError] = useState(false);

	const handleCloseModal = (name) => {
		setOpenModal(false);
	};

	const handleOpenModal = (name) => {
		setOpenModal(true);
		if (name === 'tax2562') {
			setContent(
				<iframe src={require('../assets/acticle/eco-2555.pdf')} alt="eco-2555" width="100%" height="100%" />
			);
		}
	};

	const eventData = [
		{
			id: 1,
			title: '',
			display: D6,
			displayType: 'img',
			title: 'กระทรวงอุตสาหกรรมได้รับรางวัลเลิศรัฐ ประจำปี 2562 สาขาบริการภาครัฐในประเภทพัฒนาการบริาร จากสำนักงานคณะกรรมการพัฒนาระบบราชการ (ก.พ.ร.) ในผลงานระบบเทคโนโลยีสารสนเทศเพื่อรองรับป้ายข้อมูลรถยนต์ตามมาตรฐานสากล หรือ ECO sticker ของสำนักงานเศรษฐกิจอุตสาหกรรม เพื่อมุ่งมั่นพัฒนาการบริการภาครัฐสู่ Government 4.0',
			date: '13 ธันวาคม 2562',
		},
		{
			id: 2,
			title: '',
			display: 'https://www.youtube.com/watch?v=1pVmvi8T2h0&t=1s',
			displayType: 'iframe',
			title: 'งานสัมมนา TISI Transformation เรื่อง สมอ. 4.0 : e-license โดยสำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรมได้ร่วมกับสำนักงานเศรษฐกิจอุตสาหกรรม พัฒนาระบบขออนุมัติใบอนุญาต สมอ. สำหรับรถยนต์ทางออนไลน์ ต่อเนื่องจากระบบ ECO Sticker เพื่อยกระดับการบริการผู้ประกอบการภาคอุตสาหกรรมให้เกิดความสะดวกและเชื่อมโยงการทำงานของหน่วยงานภาครัฐ ภายใต้นโยบาย Government 4.0   ',
			date: '19 กุมภาพันธ์ 2561',
		},
		{
			id: 3,
			title: '',
			display: D5,
			displayType: 'img',
			title: 'สำนักงานเศรษฐกิจอุตสาหกรรม กระทรวงอุตสาหกรรมได้จัดงานเปิดตัว "TWO Application for Car Users" ซึ่งเป็น Mobile Application ที่พัฒนา ต่อยอดมาจาก ECO Sticker ประกอบด้วย (1) Fuel Tracker สำหรับ ผู้ใช้รถยนต์ที่ต้องการเก็บค่าใช้จ่ายจากการใช้น้ำมันเชื้อเพลิงและ (2) Car Search สำหรับผู้บริโภคที่กำลังมองหารถยนต์ใหม่',
			date: '31 มีนาคม 2560',
		},
		{
			id: 4,
			title: '',
			display: D4,
			displayType: 'img',
			title: 'กระทรวงอุตสาหกรรมได้มีการเปิดตัวเว็บไซต์ www.car.go.th เพื่อเป็นแหล่งรวบรวมข้อมูลป้ายข้อมูลรถยนต์ตามมาตรฐานสากล หรือ ECO Sticker',
			date: '25 ธันวาคม 2558',
		},
		{
			id: 5,
			title: '',
			display: D1,
			displayType: 'img',
			title: 'กระทรวงการคลังและกระทรวงอุตสาหกรรมร่วมแถลงแนวทางการจัดเก็บภาษีรถยนต์จากการปล่อยก๊าซคาร์บอนไดออกไซด์ (CO2) และการขอป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (ECO Sticker)',
			date: '9 ธันวาคม 2558',
		},
		{
			id: 6,
			title: '',
			display: D2,
			displayType: 'img',
			title: 'กระทรวงอุตสาหกรรมจัดงานเผยแพร่ประชาสัมพันธ์ “ECO Sticker มิติใหม่ของยานยนต์ไทยสู่ความยั่งยืน” ให้กับผู้จัดจำหน่ายในเครือข่าย (Dealer) ที่ปรึกษาการขาย (Sales) ของผู้ผลิตหรือผู้นำเข้ารถยนต์ทุกค่าย และสื่อมวลชน',
			date: '23 พฤศจิกายน 2558',
		},
		{
			id: 7,
			title: '',
			display: D3,
			displayType: 'img',
			title: 'กระทรวงการคลังและกระทรวงอุตสาหกรรมร่วมจัดงานเปิดตัวระบบป้ายข้อมูลรถยนต์ “ECO Sticker” และร่วมลงนามบันทึกความร่วมมือเพื่อส่งเสริมให้เกิดการใช้รถยนต์อย่างยั่งยืน (Sustainable Mobility) ด้วยกลไก ECO Sticker และภาษี CO2',
			date: '16 มีนาคม 2558',
		},
	];

	const parse = require('html-react-parser');

	const fetchNews = async () => {
		let queryStaring = new URLSearchParams({
			page,
			row: rowsPerPage,
			tab: "active",
			status: "active",
			search,
		}).toString();
		try {
			const res = await getNewsOnLandingPage(queryStaring)

			const dataFetched = res.data.data.news.map(news => ({
				id: news.id,
				title: news.title,
				release_at: news.release_at,
				display: news.news_files[0].file_url, // file 
				displayType: "img",
				titile: news.content,
				date: news.created_at,
			}))
			setNewsData([...dataFetched])
		} catch (error) {
			if (error?.response?.status === '500') {
				enqueueSnackbar({
					text: 'เกิดข้อผิดพลาด',
					variant: 'error',
					content: <ErrorSnackBarContent detail="ระบบขัดข้อง กรุณาติดต่อเจ้าหน้าที่" />,
				});
			} else {
				enqueueSnackbar({
					text: 'เกิดข้อผิดพลาด',
					variant: 'error',
					content: <ErrorSnackBarContent detail={error?.response?.data?.error} />,
				});
			}
		}

	}

	const thDateFormatConverter = (dateString) => {
		if (!dateString) {
			return "-"
		}
		const date = new Date(dateString);
		if (isNaN(date)) {
			return dateString
		}
		const options = { year: 'numeric', month: 'long', day: 'numeric' };
		const thaiDate = new Intl.DateTimeFormat('th-TH', options).format(date);
		return thaiDate
	}

	useEffect(() => {
		fetchNews()
	}, []);

	return (
		<Page title="ข่าว/ประกาศ">
			<ModalViewContent open={openModal} onClose={handleCloseModal} moreWidth={'content' == 'tax'}>
				{content}
			</ModalViewContent>
			<div className={styles.pageContent}>
				<div className={styles.anTitle}>ประกาศกระทรวง</div>

				<div className={styles.justifyContentCenter}>
					<div className={styles.anDivider} />
				</div>

				<div className={styles.anContainer}>
					<div className={styles.anBlock} onClick={() => handleOpenModal('tax2562')} style={{height: "100%"}}>
						<img src={AnnouncementImage} className={styles.anImage} alt="sub1_image" />
						{/* <div className={styles.anBlockContent}>
							<div>
								ข้อมูลรถยนต์ตามมาตรฐานสากล <br />
								18 ธันวาคม 2555
							</div>
							<p>DEC 18, 2012</p>
						</div> */}
						<div className={styles.anBlockContent} style={{ height: "auto" }}>
							<div className={styles["multiline-ellipsis"]}>
								ข้อมูลรถยนต์ตามมาตรฐานสากล <br />
								18 ธันวาคม 2555
							</div>
						</div>

						<div className={styles.anDate} style={{ height: "auto" }}>
							<p style={{ margin: "0" }}>18 ธันวาคม 2555</p>
						</div>
					</div>
				</div>

				{/* <div className={styles.seeAll}>
                    ดูทั้งหมด
                </div> */}

				<div className={styles.anTitle}>ข่าววงการรถยนต์</div>

				<div className={styles.justifyContentCenter}>
					<div className={styles.anDivider} />
				</div>

				<div className={styles.justifyContentCenter}>
					<div className={styles.anContainer}>
						{newsData.map((data, index) => {
							return (
								<Link
									to={`/news/event/${data.id}`}
									state={{ id: data.id }}
									key={index}
									className={styles.anBlock}
								>
									<div style={{ width: "100%", height: "80%", overflow: "hidden" }}>

										{data.displayType === 'img' ? (
											<img src={data.display} className={styles.anImage} alt="sub1_image" />
										) : (
											<iframe
												width="100%"
												height="100%"
												src="https://www.youtube.com/embed/1pVmvi8T2h0"
												title="YouTube video player"
												frameBorder="0"
												allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
												allowFullScreen
											/>
										)}
									</div>
									<div className={styles.anBlockContent} style={{ height: "auto" }}>
										<div className={styles["multiline-ellipsis"]}>
											{/* {data.title.substring(0, 70) + ' ... '} */}
											{data.title}
										</div>
									</div>

									<div className={styles.anDate} style={{ height: "auto" }}>
										<p style={{ margin: "0" }}>{thDateFormatConverter(data.release_at ? data.release_at : data.date)}</p>
									</div>
								</Link>
							);
						})}
					</div>
				</div>
				{/* <div className={styles.seeAll} style={{ marginBottom: '200px'}}>
                    ดูทั้งหมด
                </div> */}
			</div>
		</Page>
	);
};

export default AnnouncementWithAPI;
