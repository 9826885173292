import { Pagination, Stack, TablePagination } from '@mui/material';
import React from 'react';

const Paginations = ({
	page = 1,
	totalPages = 0,
	rowsPerPage,
	rowsPerPageOptions = [30, 90, 120],
	totalRows = 0,
	handleChangePage,
	handleChangeRowsPerPage,
}) => {
	return (
		<Stack direction="row" justifyContent="center" alignItems="center" mt={3}>
			<TablePagination
				component="div"
				labelRowsPerPage="จำนวนต่อหน้า:"
				labelDisplayedRows={({ from, to, count }) => {
					return count > 0 ? `${from} - ${to} จาก ${count}` : 'ไม่พบข้อมูล';
				}}
				rowsPerPageOptions={rowsPerPageOptions}
				count={totalRows}
				rowsPerPage={rowsPerPage}
				page={page - 1}
				onRowsPerPageChange={handleChangeRowsPerPage}
				backIconButtonProps={{ style: { display: 'none' } }}
				nextIconButtonProps={{ style: { display: 'none' } }}
				onPageChange={() => void 0}
                size='large'
				sx={{
					'.MuiTablePagination-selectLabel, .MuiTablePagination-select, .MuiTablePagination-displayedRows': {
						fontFamily: '"Kanit", sans-serif',
					},
				}}
			/>
			<Pagination
				page={page}
				count={totalPages}
				shape="rounded"
				showFirstButton={totalPages != 0}
				showLastButton={totalPages != 0}
				hideNextButton={totalPages == 0}
				hidePrevButton={totalPages == 0}
				onChange={handleChangePage}
                size='large'
				sx={{
					'.MuiPaginationItem-root': {
						fontFamily: '"Kanit", sans-serif',
					},
					'.MuiPaginationItem-root.Mui-selected, .MuiPaginationItem-root.Mui-selected:hover': {
						color: '#fff',
						backgroundColor: '#339f00',
					},
				}}
			/>
		</Stack>
	);
};

export default Paginations;
