import { Button, Container, Stack } from "@mui/material";
import React, { useState } from "react";
import { Page } from "../../components/GobalElement";
import styles from "./Articles.module.scss";
import pic7 from "../../assets/acticle/pic-7.png";
import pic8 from "../../assets/acticle/pic-8.png";
import pic9 from "../../assets/acticle/pic-9.png";
import { Link } from "react-router-dom";
import Modal from "src/components/Modal";
import EURO5 from "../../assets/acticle/EURO5.json";
import EURO6 from "../../assets/acticle/EURO6.json";

const WhatIsTIS = () => {
	const [openEuroModal, setOpenEuroModal] = useState(false);
	const [dataEuro, setDataEuro] = useState("");
	const [titleEuro, setTitleEuro] = useState("");
	const onOpenModalEuro = (data, name) => {
		setTitleEuro(name);
		setDataEuro(data);
		setOpenEuroModal(true);
	};
	const onCloseModal = () => {
		setOpenEuroModal(false);
	};
	return (
		<Page title="ข้อมูลรถยนต์ EURO 5, EURO 6">
			<Modal.EuroTableModal
				type={dataEuro}
				open={openEuroModal}
				onClose={onCloseModal}
			/>
			<div className={styles.pageContent}>
				<Container>
					<div className={styles.pageContainer}>
						<span className={styles.pageTopic}>ข้อมูลรถยนต์ EURO 5, EURO 6</span>

						<div className={styles.sectionBreak} />
						<Stack direction="row" spacing={2}>
							<Button
								sx={{ fontSize: "1.5rem", padding: "1rem 2rem" }}
								variant="contained"
								color="success"
								onClick={() => onOpenModalEuro("euro-6", "ข้อมูลรถยนต์ Euro 6")}
							>
								ข้อมูลรถยนต์ Euro 6
							</Button>
							<Button
								sx={{ fontSize: "1.5rem", padding: "1rem 2rem" }}
								variant="contained"
								color="success"
								onClick={() => onOpenModalEuro("euro-5", "ข้อมูลรถยนต์ Euro 5")}
							>
								ข้อมูลรถยนต์ Euro 5
							</Button>
						</Stack>
					</div>
				</Container>
			</div>
		</Page>
	);
};

export default WhatIsTIS;
