import React, { useEffect, useState } from 'react';
import { Collapse, Container, Stack } from '@mui/material';
import useCompareContext from '../contexts/compareContext';
import styles from "./CompareBar.module.scss";
import classNames from 'classnames';
import { SELECT_COMPARE_CONFIG } from '../configs/comparisonConfig';

const CompareBar = ({ handleComparison }) => {
    const [selected, setSelected] = useCompareContext()
    const [openBar, setOpenBar] = useState(false)

    const handelRemoveCar = (index) => {
        let temp = selected
        temp.splice(index, 1)
        setSelected(JSON.parse(JSON.stringify(temp)))
    }

    useEffect(() => {
        if (selected.length > 0 && !openBar) {
            setOpenBar(true)
        } else if (selected.length == 0) {
            setOpenBar(false)
        }
    }, [selected])

    return (
        <div className={classNames(styles.compareBarContainer, { [styles.compareBarOpen]: openBar })}>
            <Collapse in={openBar}>
                <Container className={styles.compareBarCollapse}>
                    <div className={styles.compareBarCollapseClose} onClick={() => setSelected([])}><i className="fa-solid fa-xmark"></i></div>
                    <Stack direction={{ xs: 'grid', sm: 'grid', md: 'row' }} spacing={{ xs: 0, sm: 1, md: 2 }} className={styles.compareBarBox}>
                        {
                            Array.from(Array(SELECT_COMPARE_CONFIG.MAX)).map((_, index) => {
                                let item = selected[index]
                                if (item) {
                                    return (
                                        <div key={index} className={styles.compareBarItem}>
                                            <span className={styles.compareBarBrand}>{item.name}</span>
                                            <span className={styles.compareBarNo}>{item.model}</span>
                                            <div className={styles.compareBarItemRemove} onClick={() => handelRemoveCar(index)}><i className="fa-solid fa-xmark"></i></div>
                                        </div>
                                    )
                                } else {
                                    return (
                                        <div key={index} className={styles.compareBarItem}>
                                            <span className={styles.compareBarBrandEmpty}><i className="fa-solid fa-plus"></i></span>
                                            <span className={styles.compareBarEmpty}>เพิ่มรถยนต์</span>
                                        </div>
                                    )
                                }
                            })
                        }
                        <button className={styles.compareBarCommit} disabled={selected.length < SELECT_COMPARE_CONFIG.MIN} onClick={() => handleComparison()}>
                            เปรียบเทียบ
                        </button>
                    </Stack>
                </Container>
            </Collapse>
        </div>
    );
};

export default CompareBar;