import classNames from 'classnames';
import React from 'react';
import styles from './ModalViewImg.module.scss'

const ModalViewContent = ({ open, onClose, moreWidth, children }) => {
    return (
        <div className={classNames(styles.modalBackdrop, { [styles.modalOpen]: open })} onClick={() => { if (open) onClose(false) }}>
            <div className={classNames(styles.modalContainer, { [styles.maxWidth]: moreWidth })} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalContent}>
                    <div className={styles.modalContentClose} onClick={() => onClose(false)}><i className="fa-solid fa-xmark"></i></div>
                    <div className={styles.modalContentImgBox}>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalViewContent