import React, { memo, useEffect, useRef, useState } from 'react';

import styles from "./FilterCar.module.scss";

import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import { ClickAwayListener, Collapse, FormControlLabel, Grid, Radio, RadioGroup, Stack, TextField } from '@mui/material';

import classNames from 'classnames';
import { numberWithCommaOnly } from '../helper/Number';
import ModalFilter from './ModalFilter';
import { getFilterList } from '../helper/allAPI';
import { request } from '../helper/Axios';

const SelectFilter = ({ filterKey, option, selected, handleSelect, placeholder }) => {
    const [open, setOpen] = useState(false)
    const [search, setSearch] = useState('')

    const handleOnOpenCollape = () => {
        setOpen(!open)
    }

    const handleOnChangeSearch = (target) => {
        setSearch(target.value)
    }

    useEffect(() => {
        if (!open) {
            setSearch('')
        }
    }, [open])

    const RenderOption = () => {
        let newOption = search ? option.filter(name => (name.trim().toLowerCase().search(search.trim().toLowerCase()) !== -1)) : option
        if (newOption.length == 0) {
            return (<div className={styles.selectOptionItem}>ไม่พบข้อมูล "{search.trim()}"</div>)
        }
        return (
            <>
                {
                    newOption.map((item, key) => {
                        if (option.length == selected.length) {
                            return (<div className={styles.selectOptionItem}>ไม่มีตัวเลือก</div>)
                        }

                        let index = selected?.findIndex(obj => obj === item.trim()) ?? -1
                        if (index == -1) {
                            return (
                                <div key={key} onClick={() => handleSelect(filterKey, item)} className={styles.selectOptionItem}>{item}</div>
                            )
                        }
                    })
                }
            </>
        )

    }

    return (
        <ClickAwayListener
            onClickAway={() => open && setOpen(false)}
        >
            <div className={styles.selectFilterContainer}>
                <Collapse className={classNames(styles.selectOptionContainer, { [styles.selectOpened]: open })} in={open} timeout={0}>
                    <div className={styles.selectOptionList}>
                        {
                            option?.length > 0 ?
                                <>
                                    {
                                        option.length == selected.length ?
                                            (<div className={styles.selectOptionItemNone}>ไม่มีตัวเลือก</div>)
                                            :
                                            <RenderOption />
                                    }
                                </>
                                :
                                <div className={styles.selectOptionItemNone}>ไม่มีตัวเลือก</div>
                        }
                    </div>
                </Collapse>
                <div className={classNames(styles.selectFilter, { [styles.selectOpened]: open })}>
                    {/* <span className={styles.selectSelected}>{placeholder}</span> */}
                    <input
                        className={styles.selectSelected}
                        value={search}
                        placeholder={placeholder}
                        onChange={({ target }) => handleOnChangeSearch(target)}
                        autoComplete='off'
                        onFocus={(e) => {
                            e.target.select()
                            setOpen(true)
                        }}
                    />
                    <div className={styles.selectBtnSection} onClick={handleOnOpenCollape}>
                        {open ? <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    )
}

const SelectedList = ({ filterKey, list, handleRemove }) => (
    <>
        {
            list.length > 0 ?
                <>
                    {
                        list.map((item, key) => (
                            <div key={key} className={classNames(styles.selectedListItem, styles.selectedListItemWithIcon)}>
                                {item}
                                <span
                                    className={styles.selectedListRemove}
                                    onClick={() => handleRemove(filterKey, key)}
                                ><i className="fa-solid fa-xmark"></i></span>
                            </div>
                        ))
                    }
                </>
                : (<div className={styles.selectedListItem}>ทั้งหมด</div>)
        }
    </>
)

const SelectedClear = ({ filterKey, handleClear, isEmpty }) => (
    <span
        className={classNames(styles.selectedClear, {
            [styles.selectedListItemEmpty]: isEmpty
        })}
        onClick={() => handleClear(filterKey)}
    >ล้างตัวกรอง</span>
)

const Tick = ({ tick, count }) => (
    <div className={styles.sliderTickItem}>
        <div
            style={{
                left: `${tick.percent}%`
            }}
            className={styles.sliderTickLine}
        />
        <div
            style={{
                marginLeft: `${-(100 / count) / 2}%`,
                width: `${100 / count}%`,
                left: `${tick.percent}%`
            }}
            className={styles.sliderTickLabel}
        >
            {numberWithCommaOnly(tick.value)}
        </div>
    </div>
);

const Track = ({
    source,
    target,
    getTrackProps,
    disabled
}) => (
    <div
        style={{
            left: `${source.percent}%`,
            width: `${target.percent - source.percent}%`
        }}
        className={classNames(styles.sliderTrack, { [styles.disabled]: disabled })}
        {...getTrackProps()}
    />
);

const Handle = ({
    domain: [min, max],
    handle: { id, value, percent },
    getHandleProps,
    disabled
}) => (
    <div
        role="slider"
        aria-valuemin={min}
        aria-valuemax={max}
        aria-valuenow={value}
        style={{
            left: `${percent}%`,

        }}
        className={classNames(styles.sliderHandleItem, { [styles.disabled]: disabled })}
        {...getHandleProps(id)}
    />
);

const RadioCustom = ({ ...props }) => (
    <Radio
        sx={{
            '&.Mui-checked': {
                color: '#339f00',
            },
        }}
        {...props}
    />
)

const FilterCar = memo(({ open, filterList, onSelect, onCloseFilter }) => {
    const [list, setList] = useState([])
    const [filter, setFilter] = useState({})
    const [range, setRange] = useState([])
    const [rangeInput, setRangeInput] = useState([400000, 2000000])
    const [priceType, setPriceType] = useState(1)

    const modalRef = useRef(null)

    const handleSelectOption = (key, option) => {
        
        let index = filter[key]?.findIndex(item => item === option) ?? -1

        if (index >= 0) {
            let temp = filter[key]
            temp.splice(index, 1)
            setFilter({ ...filter, [key]: temp })
        } else if (index == -1) {
            let temp = filter[key]
            if (temp) {
                temp.push(option.trim())
                setFilter({ ...filter, [key]: temp })
            } else {
                temp = []
                temp.push(option.trim())
                setFilter({ ...filter, [key]: temp })
            }
        }
    }

    const handleRemoveOption = (key, index) => {
        let temp = filter[key]
        temp.splice(index, 1)
        setFilter({ ...filter, [key]: temp })
    }

    const handleClearSelect = (key) => {
        setFilter({ ...filter, [key]: [] })
    }

    const handleUpdateFilter = () => {
        let tempPrice = { ...filter }
        if (priceType == 4) {
            tempPrice = {
                ...tempPrice,
                min_recomend_retail_price: range[0],
                max_recomend_retail_price: range[1]
            }
        } else if (priceType == 2) {
            tempPrice = {
                ...tempPrice,
                min_recomend_retail_price: '',
                max_recomend_retail_price: 400000
            }
        } else if (priceType == 3) {
            tempPrice = {
                ...tempPrice,
                min_recomend_retail_price: 2000000,
                max_recomend_retail_price: ''
            }
        } else {
            delete tempPrice.min_recomend_retail_price
            delete tempPrice.max_recomend_retail_price
        }
        onSelect(tempPrice)
        onCloseFilter(false)
    }

    const handleClearFilter = () => {
        onSelect({})
        onCloseFilter(false)
    }

    const onChange = (values) => {
        setRange(values)
    }

    const onChangeRadio = (e) => {
        setPriceType(e.target.value)
        setFilter({ ...filter, priceType: e.target.value })
    }

    const handleInputRangeChange = (e, index) => {
        let value = e.target.value
        if (!(/^\d*\.?\d*$/.test(value))) return false;
        let temp = rangeInput
        if (value != temp[index]) {
            temp[index] = value ? parseInt(value) : ''
            setRangeInput([...temp]);
        }
    }

    const handleInputRangeBlur = (e, index) => {
        let value = e.target.value
        let temp = range
        if (value != temp[index]) {
            if (parseInt(value) < 400000) {
                value = 400000
            } else if (parseInt(value) > 2000000) {
                value = 2000000
            }
            temp[index] = parseInt(value)
            setRange([...temp]);
        }
    }

    useEffect(async () => {
        let elem = document.getElementsByTagName('body')
        modalRef?.current?.scrollTo(0, 0)

        if (open) {
            elem[0].classList.add("on-modale-open")
            try {
                const res = await getFilterList()
                setList(res?.data?.data)
            } catch (error) {
                // console.log(error)
            }
            setFilter(filterList)
            setPriceType(filterList?.priceType ?? 1)
            setRange(filterList?.price ?? [400000, 2000000])
        } else {
            elem[0].classList.remove("on-modale-open")
        }

        return () => {
            if (request.length > 0) {
                request[0]('Change page')
            }
        }

    }, [open])

    useEffect(() => {
        setRangeInput([...range]);
    }, [range])

    return (
        <ModalFilter refs={modalRef} open={open} title='เลือกรายละเอียดรถยนต์' onCloseModal={onCloseFilter}>
            <div className={styles.filterBody}>
                <div className={styles.filterItem}>
                    <div className={styles.filterItemHeader}>
                        <div className={styles.filterItemHeaderLine} />
                        <span className={styles.filterItemHeaderText}>ยี่ห้อ</span>
                    </div>
                    <div className={styles.selectedListContainer}>
                        <SelectedList list={filter?.brand ?? []} filterKey='brand' handleRemove={handleRemoveOption} />
                        <div className={styles.selectContainer}>
                            <SelectFilter
                                filterKey='brand'
                                option={list?.brand ?? []}
                                selected={filter.brand ?? []}
                                handleSelect={handleSelectOption}
                                placeholder='ค้นหายี่ห้อ'
                            />
                            <SelectedClear filterKey='brand' handleClear={handleClearSelect} isEmpty={!filter?.brand?.length} />
                        </div>
                    </div>
                </div>
                <div className={styles.filterItem}>
                    <div className={styles.filterItemHeader}>
                        <div className={styles.filterItemHeaderLine} />
                        <span className={styles.filterItemHeaderText}>ประเภทเครื่องยนต์</span>
                    </div>
                    <div className={styles.selectedListContainer}>
                        <SelectedList list={filter?.engine_name ?? []} filterKey='engine_name' handleRemove={handleRemoveOption} />
                        <div className={styles.selectContainer}>
                            <SelectFilter
                                filterKey='engine_name'
                                option={list?.engine ?? []}
                                selected={filter.engine_name ?? []}
                                handleSelect={handleSelectOption}
                                placeholder='ค้นหาประเภทเครื่องยนต์'
                            />
                            <SelectedClear filterKey='engine_name' handleClear={handleClearSelect} isEmpty={!filter?.engine_name?.length} />
                        </div>
                    </div>
                </div>
                <div className={styles.filterItem}>
                    <div className={styles.filterItemHeader}>
                        <div className={styles.filterItemHeaderLine} />
                        <span className={styles.filterItemHeaderText}>ราคา</span>
                    </div>
                    <div className={styles.priceFilter}>

                        <Stack spacing={2}>
                            <RadioGroup
                                name="pricing"
                                value={priceType}
                                onChange={onChangeRadio}
                            >
                                <Stack spacing={3} direction='row' className={styles.stackRadio}>
                                    <FormControlLabel value={1} control={<RadioCustom />} label="ทั้งหมด" />
                                    <FormControlLabel value={2} control={<RadioCustom />} label="น้อยกว่า 400,000" />
                                    <FormControlLabel value={3} control={<RadioCustom />} label="มากกว่า 2,000,000" />
                                    <FormControlLabel value={4} control={<RadioCustom />} label="ช่วงราคา" />
                                </Stack>
                            </RadioGroup>
                            <div className={classNames(styles.priceInputContainer, { [styles.disabled]: priceType != 4 })}>
                                <div className={styles.priceInputContent}>
                                    <span className={styles.priceInputLabel}>ราคาเริ่มต้น</span>
                                    <input
                                        value={rangeInput[0] || ''}
                                        className={styles.priceInput}
                                        disabled={priceType != 4}
                                        onBlur={(e) => handleInputRangeBlur(e, 0)}
                                        onFocus={(e) => e.target.select()}
                                        onChange={(e) => handleInputRangeChange(e, 0)}
                                    />
                                </div>
                                <span className={styles.priceInputLine}>-</span>
                                <div className={styles.priceInputContent}>
                                    <span className={styles.priceInputLabel}>ราคาสูงสุด</span>
                                    <input
                                        value={rangeInput[1] || ''}
                                        className={styles.priceInput}
                                        disabled={priceType != 4}
                                        onBlur={(e) => handleInputRangeBlur(e, 1)}
                                        onFocus={(e) => e.target.select()}
                                        onChange={(e) => handleInputRangeChange(e, 1)}
                                    />
                                </div>
                            </div>

                        </Stack>
                    </div>
                    <div className={styles.sliderContainer}>
                        <Slider
                            mode={3}
                            step={10000}
                            domain={[400000, 2000000]}
                            className={styles.sliderMain}
                            onChange={onChange}
                            values={[...range]}
                            disabled={priceType != 4}
                        >
                            <Rail>
                                {
                                    ({ getRailProps }) => (
                                        <div className={styles.sliderRail} {...getRailProps()} />
                                    )
                                }
                            </Rail>
                            <Handles>
                                {
                                    ({ handles, getHandleProps }) => (
                                        <div className={styles.sliderHandleContainer}>
                                            {
                                                handles.map(handle => (
                                                    <Handle
                                                        key={handle.id}
                                                        handle={handle}
                                                        domain={[400000, 2000000]}
                                                        getHandleProps={getHandleProps}
                                                        disabled={priceType != 4}
                                                    />
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </Handles>
                            <Tracks left={false} right={false}>
                                {
                                    ({ tracks, getTrackProps }) => (
                                        <div className={styles.sliderInnerContainer}>
                                            {
                                                tracks.map(tracks => (
                                                    <Track
                                                        key={tracks.id}
                                                        source={tracks.source}
                                                        target={tracks.target}
                                                        getTrackProps={getTrackProps}
                                                        disabled={priceType != 4}
                                                    />
                                                ))
                                            }
                                        </div>
                                    )
                                }
                            </Tracks>
                            <Ticks>
                                {({ ticks }) => (
                                    <div className={styles.sliderOuterContainer}>
                                        {
                                            ticks.map(tick => (
                                                <Tick key={tick.id} tick={tick} count={ticks.length} />
                                            ))
                                        }
                                    </div>
                                )}
                            </Ticks>
                        </Slider>
                    </div>

                </div>
            </div>
            <div className={styles.filterFooter}>
                <button className={styles.filterFooterBtn} onClick={() => handleUpdateFilter()}>
                    ค้นหา
                </button>
                <span
                    className={classNames(styles.clearAllFilter, { [styles.disabled]: Object.keys(filterList).length == 0 })}
                    onClick={() => handleClearFilter()}
                >ล้างตัวกรองทั้งหมด</span>
            </div>
        </ModalFilter>
    );
});

export default FilterCar;