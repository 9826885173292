import React, { useState } from "react";
import { Box, styled } from "@mui/system";
import {
	Modal,
	Typography,
	Stack,
	Button,
	TableContainer,
	TableHead,
	TableRow,
	TableBody,
	Dialog,
	DialogActions,
	DialogTitle,
	DialogContentText,
	DialogContent,
	IconButton,
	Divider,
	Icon,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { BASE_URL_FONTEND_STICKER, getEuroContent } from "src/helper/allAPI";
import FacebookIcon from "../assets/social-icon/facebook.png";
import InstagramIcon from "../assets/social-icon/instagram.png";
import XIcon from "../assets/social-icon/twitter.png";
import LineIcon from "../assets/social-icon/line.png";
import { useEffect } from "react";
import Loading from "./Loading";

const ModalBackdropStandard = styled("div")({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	minWidth: 900,
	maxWidth: 1300,
	height: "90vh",
	backgroundColor: "#fff",
	borderRadius: 15,
	overflow: "hidden",
	padding: "1.5rem 2rem",
});

const Modals = styled(Modal)(() => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	zIndex: 99999999999999,
}));

const TableDetail = styled("table")(({ theme }) => ({
	width: "100%",
	border: "1px solid #ddd",
	borderRadius: 50,
	borderCollapse: "collapse",
	"& tr:nth-of-type(even)": {
		backgroundColor: "#F8F8F8",
	},
	"& th": {
		textAlign: "center",
		padding: "10px 15px",
		borderBottom: "1px solid #ddd",
		borderLeft: "1px solid #3333",
		fontWeight: 400,
		fontSize: 15,
	},
	" & td": {
		textAlign: "center",
		padding: "10px 15px",
		borderBottom: "1px solid #ddd",
		borderLeft: "1px solid #3333",
	},
	"& td.border": {
		borderLeft: "1px solid #E2E2E2",
		borderRight: "1px solid #E2E2E2",

		textAlign: "center",
	},
	"& td.header": {
		borderLeft: "1px solid #E2E2E2",
		textAlign: "center",
		color: "#fff",
	},
}));

const TableHeadSticky = styled(TableHead)({
	position: "sticky",
	top: 0,
	backgroundColor: "#4caf50",
	zIndex: 999,
});

const EuroTableModal = ({ open, onClose, type }) => {
	const [data, setData] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	useEffect(() => {
		if (type) {
			fetchDataEuro(type);
		}
	}, [open]);

	const fetchDataEuro = async (type) => {
		const queryString = new URLSearchParams({
			euro_standard: type,
		});
		setIsLoading(true);
		try {
			const response = await getEuroContent(queryString);
			setData(response.data);
			setIsLoading(false);
		} catch (error) {
			console.error(error);
			setIsLoading(false);
		}
	};

	function formatPrice(number) {
		return number.toLocaleString();
	}
	return (
		<Modals open={open} onClose={() => onClose()}>
			{isLoading ? (
				<ModalBackdropStandard>
					<Loading label={"กำลังโหลดข้อมูล Euro"} />
				</ModalBackdropStandard>
			) : (
				<ModalBackdropStandard>
					<Typography fontSize={30} fontWeight="500" textAlign="center" p={1}>
						{data.header_text}
					</Typography>
					<Typography fontSize={16} fontWeight="400" textAlign="center">
						{data.header_updated_at_text}
					</Typography>
					<TableContainer>
						<TableDetail>
							<TableHeadSticky>
								<TableRow>
									{data?.header?.map((item, i) => {
										return (
											<TableCell
												sx={{ color: "#fff", fontWeight: 500 }}
												className="header"
												align="center"
											>
												{item}
											</TableCell>
										);
									})}
								</TableRow>
							</TableHeadSticky>
							<TableBody>
								{data?.body?.map((row, i) => (
									<TableRow key={i}>
										<TableCell
											sx={{ width: 100, minWidth: 100 }}
											className="border"
											component="th"
											scope="row"
										>
											{row.eco_sticker_id}
										</TableCell>
										<TableCell className="border" align="center">
											{row.brand}
										</TableCell>
										<TableCell className="border" align="center">
											{row.model}
										</TableCell>
										<TableCell className="border" align="center">
											{row.cylinder}
										</TableCell>
										<TableCell className="border" align="center">
											{row.engine}
										</TableCell>
										<TableCell className="border" align="center">
											{row.gear}
										</TableCell>
										<TableCell className="border" align="center">
											{row.available_fuel}
										</TableCell>
										<TableCell className="border" align="center">
											{row.co2}
										</TableCell>
										<TableCell className="border" align="center">
											{row.consumes_oil}
										</TableCell>
										<TableCell className="border" align="center">
											{row.standard_r94}
										</TableCell>
										<TableCell className="border" align="center">
											{row.standard_r95}
										</TableCell>
										<TableCell className="border" align="center">
											{row.standard_r13h}
										</TableCell>
										<TableCell className="border" align="center">
											{formatPrice(row.price)}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</TableDetail>
					</TableContainer>
					<Stack width="100%" alignItems="center" justifyContent="center" pt={2}>
						<Button
							onClick={onClose}
							color="error"
							variant="outlined"
							sx={{ width: "20%", justifyContent: "center", alignItems: "center" }}
						>
							ปิด
						</Button>
					</Stack>
				</ModalBackdropStandard>
			)}
		</Modals>
	);
};

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ShareComponent = ({ open, onClose, id, data }) => {
	const [copied, setCopied] = useState(false);
	const url = window.location.href;

	const fnHandleCopy = async () => {
		try {
			if (navigator.clipboard) {
				await navigator.clipboard.writeText(url);
				setCopied(true);
				setTimeout(() => setCopied(false), 2000);
			} else {
				fallbackCopyTextToClipboard(url);
			}
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	const fallbackCopyTextToClipboard = (text) => {
		const textArea = document.createElement("textarea");
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.focus();
		textArea.select();

		try {
			const successful = document.execCommand("copy");
			if (successful) {
				setCopied(true);
				setTimeout(() => setCopied(false), 2000);
			} else {
				console.error("Fallback: Copy command was unsuccessful");
			}
		} catch (err) {
			console.error("Fallback: Oops, unable to copy", err);
		}

		document.body.removeChild(textArea);
	};

	const shareOnInstagram = () => {
		const instagramDmUrl = `https://www.instagram.com/direct/inbox/`;
		fnHandleCopy();
		window.open(instagramDmUrl, "_blank");
	};
	const shareOnFacebook = () => {
		const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
		window.open(facebookShareUrl, "_blank");
	};

	const shareOnTwitter = () => {
		const twitterShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
			url
		)}&text=${encodeURIComponent(`รถยนต์แบรนด์ ${data?.brand} อ่านรายละเอียดเพิ่มเติมได้ที่`)}`;
		window.open(twitterShareUrl, "_blank");
	};

	const shareToLine = () => {
		const message = `รถยนต์แบรนด์ ${data?.brand} อ่านรายละเอียดเพิ่มเติมได้ที่`;
		const lineShareUrl = "https://line.me/R/msg/text/?" + encodeURIComponent(message + " " + url);
		window.open(lineShareUrl, "_blank");
	};

	return (
		<Dialog
			open={open}
			TransitionComponent={Transition}
			keepMounted
			onClose={onClose}
			aria-describedby="alert-dialog-slide-description"
			maxWidth="md"
		>
			<DialogTitle>
				<Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
					<Typography fontSize={18} fontWeight={500}>
						Share
					</Typography>
					<IconButton onClick={onClose}>
						<CloseIcon color="error" />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent sx={{ borderBottom: "1px solid rgba(0,0,0,0.1)" }}>
				<DialogContentText id="alert-dialog-slide-description">
					<Box
						display={"flex"}
						justifyContent={"space-between"}
						alignItems={"center"}
						border={"1px solid rgba(0,0,0,0.1)"}
						width={"100%"}
						borderRadius={"12px"}
						p={1}
						pl={2}
						pr={2}
					>
						<Typography
							width={"80%"}
							whiteSpace={"nowrap"}
							sx={{ scrollbarWidth: "none" }}
							overflow={"auto"}
						>
							{url}
						</Typography>
						<Button
							onClick={fnHandleCopy}
							variant="contained"
							sx={{ borderRadius: 16, background: "#065fd4", textTransform: "none" }}
						>
							{copied ? "Copied" : "Copy"}
						</Button>
					</Box>
					<Divider sx={{ m: 2.5 }} variant="middle" />
					<Stack direction={"row"} spacing={2} justifyContent={"center"} alignItems={"center"}>
						<IconShareSocialMedia image={FacebookIcon} onClick={shareOnFacebook} />
						<IconShareSocialMedia image={InstagramIcon} onClick={shareOnInstagram} />
						<IconShareSocialMedia image={XIcon} onClick={shareOnTwitter} />
						<IconShareSocialMedia image={LineIcon} onClick={shareToLine} />
					</Stack>
				</DialogContentText>
			</DialogContent>
		</Dialog>
	);
};

const IconShareSocialMedia = ({ onClick, image }) => {
	return (
		<div
			style={{
				width: 50,
				height: 50,
				cursor: "pointer",
				boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
				borderRadius: "100%",
			}}
			onClick={onClick}
		>
			<img style={{ width: "100%", height: "100%" }} src={image} />
		</div>
	);
};

export default {
	EuroTableModal,
	ShareComponent,
};
