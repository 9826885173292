import React from 'react';
import { Outlet } from 'react-router-dom';
import { CompareProvider } from '../contexts/compareContext';

const ComparisonLayout = () => {
    return (
        <CompareProvider>
            <Outlet />
        </CompareProvider>
    );
};

export default ComparisonLayout