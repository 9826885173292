import classNames from 'classnames';
import React from 'react';
import styles from './ModalViewImg.module.scss'

const ModalViewImg = ({ open, now, list, callback, onClose }) => {
    return (
        <div className={classNames(styles.modalBackdrop, { [styles.modalOpen]: open })} onClick={() => { if (open) onClose(false) }}>
            <div className={styles.modalContainer} onClick={(e) => e.stopPropagation()}>
                <div className={styles.modalContent}>
                    <div className={styles.modalContentClose} onClick={() => onClose(false)}><i className="fa-solid fa-xmark"></i></div>
                    <div className={styles.modalContentImgBox}>
                        <img src={list[now]} className={styles.modalContentImg} />
                    </div>

                    <div className={styles.modalFooter}>
                        {
                            list.map((item, key) => (
                                <div key={key} className={classNames(styles.listBox, { [styles.active]: key == now })}>
                                    <img src={item} className={styles.listBoxImg} onClick={() => callback(key)} />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalViewImg