import React from 'react';
import styles from './TableCompare.module.scss';
import classNames from 'classnames';

const TableCompare = ({ columns, content, data, highlight }) => {
	return (
		<div
			className={classNames(styles.tableCompare, {
				[styles.tableCompareCol2]: columns == 2,
				[styles.tableCompareCol3]: columns == 3,
				[styles.tableCompareCol4]: columns == 4,
			})}
		>
			{content.map((topic) => {
				return data.map((item, index) => {
					if (topic.elem) {
						let elem = topic.elem(item, highlight);
						if (topic.colSpan) {
							if (index == columns - 1) {
								return (
									<div
										key={index}
										className={classNames(styles.tableCompareContent, {
											[styles.nonePadding]: topic.nonePadding,
											[styles.tableSpanCol2]: columns == 2 && topic.colSpan,
											[styles.tableSpanCol3]: columns == 3 && topic.colSpan,
											[styles.tableSpanCol4]: columns == 4 && topic.colSpan,
										})}
									>
										{topic.name && (
											<span className={styles.tableCompareContentLabel}>{topic.name}</span>
										)}
										{elem}
										{topic.unit && elem != '-' ? (
											<span>{topic.unit[item.cartype_name] ?? topic.unit}</span>
										) : (
											''
										)}
									</div>
								);
							}
						} else {
							return (
								<div
									key={index}
									className={classNames(styles.tableCompareContent, {
										[styles.nonePadding]: topic.nonePadding,
									})}
								>
									{topic.name && (
										<span className={styles.tableCompareContentLabel}>{topic.name}</span>
									)}
									{elem}
									{topic.unit && elem != '-' ? (
										<span>{topic.unit[item.cartype_name] ?? topic.unit}</span>
									) : (
										''
									)}
								</div>
							);
						}
					} else {
						if (topic.colSpan) {
							if (index == columns - 1) {
								return (
									<div
										key={index}
										className={classNames(styles.tableCompareContent, {
											[styles.nonePadding]: topic.nonePadding,
											[styles.tableSpanCol2]: columns == 2 && topic.colSpan,
											[styles.tableSpanCol3]: columns == 3 && topic.colSpan,
											[styles.tableSpanCol4]: columns == 4 && topic.colSpan,
										})}
									>
										<div className={styles.tableCompareContentBox}>
											<span className={styles.tableCompareContentLabel}>{topic.name}</span>
											<span
												className={classNames(styles.tableCompareContentSpan, {
													[styles.lSpan]: topic.size == 'l',
													[styles.mSpan]: topic.size == 'm',
													[styles.sSpan]: topic.size == 's',
												})}
											>
												{item[topic.selector] && item[topic.selector] != '-' ? (
													item[topic.selector]
												) : (
													<div className={styles.dashSpan} />
												)}
											</span>
											{topic.unit && item[topic.selector] ? (
												<span>{topic.unit[item.cartype_name] ?? topic.unit}</span>
											) : (
												''
											)}
										</div>
									</div>
								);
							}
						} else {
							return (
								<div key={index} className={styles.tableCompareContent}>
									<div className={styles.tableCompareContentBox}>
										<span className={styles.tableCompareContentLabel}>{topic.name}</span>
										<span
											className={classNames(styles.tableCompareContent, styles.mSpan, {
												[styles.nonePadding]: topic.nonePadding,
												[styles.lSpan]: topic.size == 'l',
												[styles.sSpan]: topic.size == 's',
											})}
										>
											{item[topic.selector] && item[topic.selector] != '-' ? (
												item[topic.selector]
											) : (
												<div className={styles.dashSpan} />
											)}
										</span>
										{topic.unit && item[topic.selector] ? (
											<span>{topic.unit[item.cartype_name] ?? topic.unit}</span>
										) : (
											''
										)}
									</div>
								</div>
							);
						}
					}
				});
			})}
		</div>
	);
};

export default TableCompare;
