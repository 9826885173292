import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import reportWebVitals from "./reportWebVitals";
// css
import "./index.css";
// routes
import Router from "./routes/router";
// components
import ScrollToTop from "./components/ScrollToTop";

import CookieConsent from "./components/cookieConsent/CookieConsent";


ReactDOM.render(
	<HelmetProvider>
		<BrowserRouter basename="/landing-page">
			<ScrollToTop />
			<Router />
			<CookieConsent />
		</BrowserRouter>
	</HelmetProvider>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
