import React from "react";
import styles from "./Loading.module.scss";

const Loading = ({ label }) => {
	return (
		<div
			style={{
				width: "100%",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}
		>
			<div className={styles.loader}></div>
			<div style={{ padding: "5px" }}>{label || "กำลังโหลดข้อมูล"}</div>
		</div>
	);
};

export default Loading;
