import { Container } from '@mui/material';
import React from 'react';
import { Page } from '../../components/GobalElement';
import styles from "./Articles.module.scss";
import pic1 from '../../assets/acticle/pic-1.png'
import pic2 from '../../assets/acticle/pic-2.jpeg'
import pic3 from '../../assets/acticle/pic-3.png'

const WhatIsEcoSticker = () => {
    return (
        <Page title='Ecosticker คืออะไร?'>
            <div className={styles.pageContent}>
                <Container>
                    <div className={styles.pageContainer}>
                        <span className={styles.pageTopic}>Ecosticker คืออะไร? </span>
                        <div className={styles.imgSection}>
                            <img src={pic1} className={styles.imgTop} />
                            <img src={pic2} className={styles.imgTop} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span><span className={styles.textGreen}>ECO Sticker</span> คืออะไร?</span>
                            <span>
                                ถ้าเอาตามประกาศกระทรวงอุตสาหกรรม เรื่องการแสดงข้อมูลรถยนต์ตามมาตรฐานสากล เมื่อวันที่ 19 สิงหาคม 2558 ก็จะหมายถึงว่า แผ่นป้ายที่มีทะเบียนป้ายข้อมูลรถยนต์ รวมถึงมีข้อความ แผนที่ หรือรูปภาพ ที่แสดงรายละเอียด คุณลักษณะ คุณสมบัติ และสมรรถนะของเครื่องยนต์ตามมาตรฐานสากลของสหประชาชาติ แปลให้อ่านง่ายก้คือ ป้ายที่แสดงคุณลักษรณะของรถคันนี้ ว่ามีสมรรถนะอย่างไร ราคาเท่าไหร่ และมีส่วนประกอบอะไรบ้างนั่นเอง
                            </span>
                        </div>

                        <div className={styles.paraghapBox}>
                            <span><span className={styles.textGreen}>ECO Sticker</span> มีไว้ทำอะไร?</span>
                            <span>
                                แล้วป้าย ECO Sticker เขาทำขึ้นมาเพื่อเอาไว้ใช้ทำอะไร ตามวัตถุประสงค์ของคณะรัฐมนตรี เมื่อวันที่ 18 ธันวาคม 2555 จะบอกเอาไว้ว่าเพื่อให้ผู้บริโภคและประชาชนทั่วไปได้รับข้อมูลสมรรถนะรถยนต์ที่เที่ยงตรง โปร่งใส และเป็นมาตรฐานเดียวกัน สามารถนำไปเปรียบเทียบคุณสมบัติของรถยนต์แต่ละรุ่น เพื่อประโยชน์ต่อการพิจารณาเลือกซื้อรถยนต์ได้ รวมทั้ง เพื่อเป็นการเพิ่มประสิทธิภาพของภาครัฐในการตรวจสอบมาตรฐานรถยนต์และการจัดเก็บภาษีตามโครงสร้างภาษีสรรพสามิตใหม่นั่นเอง
                            </span>
                        </div>
                        <div className={styles.mixSection}>
                            <img src={pic3} className={styles.imgMix} />
                            <div className={styles.paraghapBox}>
                                <span><span className={styles.textGreen}>ECO Sticker</span> มีอะไรบอกเอาไว้บ้าง?</span>
                                <br />
                                <span>
                                    1. ข้อมูลผู้ผลิตหรือผู้นำเข้า ทั้งชื่อบริษัท, ที่อยู่, เว็บไซต์, เครื่องหมายการค้า
                                </span>
                                <span>
                                    2. ข้อมูลพื้นฐานของตัวรถ ทั้ง ยี่ห้อ, รุ่น, แบบ, หมายเลขตัวถัง, รหัสโครงรถ, รหัสเครื่องยนต์, เครื่องยนต์, ระบบเกียร์, น้ำหนักรถ, ขนาดยางล้อ, จำนวนที่นั่ง, ประเภทเชื้อเพลิง และโรงงานที่ผลิต หรือถ้าผู้ขอ <span className={styles.textGreen}>ECO Sticker</span> อยากเพิ่มเติมมากกว่านี้ก็ทำได้
                                </span>
                                <span>
                                    3. อุปกรณ์ที่ติดตั้งจากโรงงาน ไม่ว่าจะเป็น อุปกรณ์มาตรฐานและอุปกรณ์เสริม, ระบบอิเล็กทรอนิกส์ทั้ง Hardware และ Software, ล้อและยาง, อุปกรณ์อื่น ๆ
                                </span>
                                <span>
                                    4. ข้อมูลแสดงสมรรถนะของรถยนต์ใหม่ ทั้งอัตราใช้น้ำมันในรูปแบบ ลิตรต่อ 100 กิโลเมตร นอกเมือง ในเมือง และสภาวะรวม, อัตราการปล่อยก๊าซคาร์บอนไดออกไซด์, ระดับมาตรฐานการปล่อยสารมลพิษ, ระดับความปลอดภัยของรถยนต์
                                </span>
                                <span>
                                    5. หมายเลขทะเบียนหรือ ID ของ <span className={styles.textGreen}>ECO Sticker</span> และ <span className={styles.textGreen}>QR Code</span> ของป้ายนั้น
                                </span>
                                <span>
                                    6. ราคาขายปลีกแนะนำและอัตราภาษีสรรพสามิต
                                </span>
                            </div>
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span><span className={styles.textGreen}>ECO Sticker</span> มีขนาดเท่าไหร่?</span>
                            <span>
                                ถ้าเอาแบบตามข้อกำหนดเป๊ะ ๆ ก็คือกว้างไม่น้อยกว่า 190 มม. สูงไม่น้อยกว่า 270 มม. หรือจำง่าย ๆ คือ เท่ากับขนาดกระดาษ A4 นั่นเอง
                            </span>
                        </div>
                        <div className={styles.paraghapBox}>
                            <span><span className={styles.textGreen}>ECO Sticker</span></span>
                            <span>
                                อาจจะไม่ได้มีรายละเอียดมากพอที่จะช่วยให้ตัดสินใจซื้อรถได้จากการดูเพียงแค่หน้ากระดาษเดียว แต่อย่างน้อยก็เป็นตัวกำหนดชัดเจนว่า รถคันนี้มีคุณสมบัติอย่างไรบ้าง ราคาเท่าไหร่ เสียค่าสรรพสามิตเท่าไหร่ มีอุปกรณ์อะไรบ้าง ถ้าขาดอันใดอันหนึ่งไป ก็สามารถร้องเรียนกับหน่วยงานที่ดูแลได้เลย
                            </span>
                        </div>
                    </div>
                </Container>
            </div>
        </Page>
    );
};

export default WhatIsEcoSticker;