import { Button, Container, Stack } from "@mui/material";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Page } from "../components/GobalElement";
import styles from "./AboutUsPage.module.scss";

import Pic1 from "../assets/aboutus/1.jpeg";
import Pic1_1 from "../assets/aboutus/1-1.jpeg";
import Pic2 from "../assets/aboutus/2.png";
import Pic3 from "../assets/aboutus/3.png";
import Pic4 from "../assets/aboutus/4.png";
import Pic4_1 from "../assets/aboutus/4-1.jpg";
import Pic5 from "../assets/aboutus/5.png";
import Pic6 from "../assets/aboutus/6.jpeg";
import Pic7 from "../assets/aboutus/7.jpeg";
import Pic8 from "../assets/aboutus/8.jpeg";
import Pic9 from "../assets/aboutus/9.jpeg";
import Pic10 from "../assets/aboutus/10.jpeg";
import Pic11 from "../assets/aboutus/11.jpeg";
import Pic12 from "../assets/aboutus/12.png";
import Pic13 from "../assets/aboutus/13.jpeg";
import Pic14 from "../assets/aboutus/14.jpeg";
import Pic15 from "../assets/aboutus/15.jpeg";
import Pic16 from "../assets/aboutus/16.png";
import Pic17 from "../assets/aboutus/17.png";
import Pic18 from "../assets/aboutus/18.jpeg";
import Pic19 from "../assets/aboutus/19.jpeg";
import Pic20 from "../assets/aboutus/20.jpeg";
import Pic21 from "../assets/aboutus/21.png";
import Pic22 from "../assets/aboutus/22.jpeg";
import Pic23 from "../assets/aboutus/23.png";
import Pic24 from "../assets/aboutus/24.png";
import Pic25 from "../assets/aboutus/25.png";
import Pic26 from "../assets/aboutus/pic8_1.jpg";
import Pic27 from "../assets/aboutus/pic8_2.jpg";
import Pic28 from "../assets/aboutus/pic8_3.jpg";
//
import Pic29 from "../assets/aboutus/pic_2566_1.jpg";
import Pic30 from "../assets/aboutus/pic_2566_2.jpg";
import Pic31 from "../assets/aboutus/pic_2566_3.jpg";
import Pic32 from "../assets/aboutus/pic_2566_4.jpg";
import Pic33 from "../assets/aboutus/pic_2566_5.jpg";
import ModalViewImg from "../components/ModalViewImg";

const Timline = [
	{
		date: "31 สิงหาคม 2566",
		taxt: (
			<div>
				<span className={styles.textBlue}>สำนักงานเศรษฐกิจอุตสาหกรรม (สศอ.)</span> ร่วมกับ{" "}
				<span className={styles.textGreen}>บริษัท อินเตอร์เน็ตประเทศไทย จำกัด </span>{" "}
				ในฐานะที่ปรึกษาโครงการปรับระบบการดำเนินงานป้ายข้อมูลยานยนต์ตามมาตรฐานสากล (ECO Sticker)
				ได้จัดการประชุมหารือกับผู้ประกอบการรถยนต์ เพื่อรับฟังข้อคิดเห็นในการนำระบบ{" "}
				<span className={styles.textGreen}>ECO Sticker </span>
				ไปไว้บนแพลตฟอร์มกลางของกระทรวงอุตสาหกรรม (i-Industry) และหารือแนวทางการดำเนินงานในการยื่นขอ{" "}
				<span className={styles.textGreen}>ECO Sticker </span>
				ในช่วงเปลี่ยนผ่านมาตรฐานมลพิษไปสู่ Euro 5 และ 6 ในปี 2567 และ 2568 ตามลำดับ
				ตามแผนงานของกระทรวงในการแก้ไขปัญหา PM 2.5 อันเนื่องจากภาคขนส่ง โดยมีนายดุสิต อนันตรักษ์ 
				ผู้อำนวยการศูนย์เทคโนโลยีสารสนเทศและการสื่อสาร สำนักงานปลัดกระทรวงอุตสาหกรรม นายอุษิณ วิโรจน์เตชะ
				นักวิเคราะห์นโยบายและแผนชำนาญการพิเศษ สศอ. พร้อมด้วย เจ้าหน้าที่กองนโยบายอุตสาหกรรมรายสาขา 1 สศอ.
				ในฐานะคณะกรรมการตรวจรับโครงการฯ เข้าร่วมการประชุมดังกล่าว ร่วมกับผู้ประกอบการกว่า 50 คน  ณ ห้องประชุม
				601 ชั้น 6 สศอ.
			</div>
		),
		img: [Pic29, Pic30, Pic31, Pic32, Pic33],
	},
	{
		date: "8 มิถุนายน 2565",
		taxt: (
			<div>
				<span className={styles.textBlue}>สำนักงานเศรษฐกิจอุตสาหกรรม (สศอ.)</span> และผู้ประกอบการยานยนต์
				ร่วมกันหารือ และรับฟังความเห็น ในการพัฒนาระบบการยื่นขอ{" "}
				<span className={styles.textGreen}>ECO Sticker</span> ให้มีความสะดวก และทันสมัย ตอบสนองนโยบายรัฐบาล
				และความต้องการของผู้ประกอบการ
			</div>
		),
		img: [Pic26, Pic27, Pic28],
	},
	{
		date: "10 กุมภาพันธ์ 2564",
		taxt: (
			<div>
				<span className={styles.textBlue}>สำนักงานเศรษฐกิจอุตสาหกรรม (สศอ.)</span> และผู้ประกอบการยานยนต์
				หารือเรื่องการปรับปรุงระบบการยื่นขอ ECO Sticker, เว็บไซต์ และ Mobile Application
				ให้มีความสะดวกในการใช้งานเพิ่มขึ้น รวมถึง สศอ. มีประเด็นหารือเกี่ยวกับภารกิจของ สศอ.
				และการดำเนินงานของระบบ <span className={styles.textGreen}>ECO Sticker</span> ขั้นต่อไป
			</div>
		),
		img: [Pic1, Pic1_1],
	},
	{
		date: "1 มีนาคม 2563",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม ร่วมกับหน่วยงานที่เกี่ยวข้อง มีการพัฒนาระบบ ECO Sticker โดยการเชื่อมระบบ ECO
				Sticker <span className={styles.textGreen}>รถยนต์และยางรถยนต์</span> และสามารถดูข้อมูลยางได้จากการ{" "}
				<span className={styles.textGreen}>Scan QR Code</span> ที่อยู่บนป้าย ECO Sticker
			</div>
		),
		img: [Pic2],
	},
	{
		date: "27 กันยายน 2562",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม รวมกับหน่วยงานที่เกี่ยวข้อง มีการพัฒนาระบบ ECO Sticker สำหรับป้าย{" "}
				<span className={styles.textGreen}>PHEV</span> และเปิดให้ผู้ประกอบการยานยนต์ยื่นเข้ามาในระบบ
			</div>
		),
		img: [Pic3],
	},
	{
		date: "26 เมษายน 2562",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม กรมสรรพสามิตและ ผู้ประกอบการยานยนต์ หารือเรื่องการปรับปรุงระบบ ECO Sticker
				สำหรับ<span className={styles.textGreen}>รถปิคอัพ</span>ที่ใช้น้ำมัน
				<span className={styles.textRed}>ดีเซล B20</span>, การยื่นขอ ECO Sticker ภายใต้มาตรการส่งเสริม{" "}
				<span className={styles.textGreen}>xEV</span> และการยื่นขอ ECO Sticker สำหรับรถยนต์{" "}
				<span className={styles.textGreen}>PPV</span>, <span className={styles.textGreen}>Hybrid</span>
			</div>
		),
		img: [Pic4_1],
	},
	{
		date: "29 ตุลาคม 2561",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม ร่วมกับหน่วยงานที่เกี่ยวข้อง มีการพัฒนาระบบ ECO Sticker สำหรับป้าย BEV
				และเปิดให้ผู้ประกอบการยานยนต์ยื่นเข้ามาในระบบ
			</div>
		),
		img: [Pic4],
	},
	{
		date: "16 กันยายน 2560",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม รวมกับหน่วยงานที่เกี่ยวข้อง มีการพัฒนา ECO Sticker รูปแบบใหม่
				โดยเพิ่มเติมข้อมูลราคาขายปลีกแนะนำ (บาท) และอัตราภาษีสรรพสามิต (ร้อยละ)
			</div>
		),
		img: [Pic5],
	},
	{
		date: "30 สิงหาคม 2560",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม กรมสรรพสามิต และผู้ประกอบการยานยนต์ หารือเรื่องระบบ ECO Sticker
				เพื่อรองรับการจัดเก็บภาษีสรรพสามิตจากราคาขายปลีกแนะนำ
			</div>
		),
		img: [Pic6],
	},
	{
		date: "11 สิงหาคม 2560",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม กรมสรรพสามิต และผู้ประกอบการยานยนต์ หารือเรื่องระบบ ECO Sticker
				เพื่อรองรับการจัดเก็บภาษีสรรพสามิตจากราคาขายปลีกแนะนำ
			</div>
		),
		img: [Pic7],
	},
	{
		date: "31 มีนาคม 2560",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม กระทรวงอุตสาหกรรมได้จัดงานเปิดตัว "TWO Application for Car Users" ซึ่งเป็น
				Mobile Application ที่พัฒนาต่อยอดมาจาก ECO Sticker ประกอบด้วย (1) Fuel Trackerสำหรับผู้ใช้รถยนต์
				ที่ต้องการเก็บค่าใช้จ่ายจากการใช้น้ำมันเชื้อเพลิง และ (2) Car
				Searchสำหรับผู้บริโภคที่กำลังมองหารถยนต์ใหม่
			</div>
		),
		img: [Pic8],
	},
	{
		date: "14 มีนาคม 2560",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรม ร่วมกับสำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรมจัดประชุมหารือกับผู้ผลิต/ผู้นำเข้า
				เรื่อง การปรับปรุงระบบป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (ECO Sticker), การพัฒนาระบบการยื่นขอใบอนุญาต สมอ.
				เป็นระบบ Cloud Base Application (ในลักษณะเดียวกับระบบ ECO Sticker) และพัฒนา Mobile Application จำนวน 2
				Applications ได้แก่ Fuel Tracker Application และ Car Search Application
			</div>
		),
		img: [Pic9],
	},
	{
		date: "8 กันยายน 2559",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรมจัดงานประชาสัมพันธ์และอบรมความรู้เพิ่มเติมเกี่ยวกับระบบไอทีป้ายข้อมูลรถยนต์ให้กับผู้ผลิต/ผู้นำเข้ารถยนต์
				เพื่อเป็นการให้คำแนะนำ รวมทั้งตอบข้อสงสัยในประเด็นที่เกี่ยวข้องกับระบบไอที
			</div>
		),
		img: [Pic10],
	},
	{
		date: "2 กุมภาพันธ์ 2559",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรมจัดประชุมหารือเพื่อปรับปรุงระบบป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (Eco Sticker)
				ร่วมกับหน่วยงานภาครัฐที่เกี่ยวข้อง รวมทั้งผู้ผลิตและผู้นำเข้ารถยนต์
			</div>
		),
		img: [Pic11],
	},
	{
		date: "25 ธันวาคม 2558",
		taxt: (
			<div>
				กระทรวงอุตสาหกรรมได้มีการเปิดตัวเว็บไซต์ www.car.go.th
				เพื่อเป็นแหล่งรวบรวมข้อมูลป้ายข้อมูลรถยนต์ตามมาตรฐานสากล หรือ ECO Sticker
				ของรถยนต์ที่มีการจำหน่ายในประเทศ
				สำหรับผู้บริโภคหรือประชาชนทั่วไปผู้ประกอบการยานยนต์และหน่วยงานที่เกี่ยวข้อง
			</div>
		),
		img: [Pic12],
	},
	{
		date: "9 ธันวาคม 2558",
		taxt: (
			<div>
				กระทรวงการคลังและกระทรวงอุตสาหกรรมร่วมแถลงแนวทางการจัดเก็บภาษีรถยนต์จากการปล่อยก๊าซคาร์บอนไดออกไซด์ (CO
				<sub>2</sub>) และการขอป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (ECO Sticker)
			</div>
		),
		img: [Pic13],
	},
	{
		date: "23 พฤศจิกายน 2558",
		taxt: (
			<div>
				กระทรวงอุตสาหกรรมจัดงานเผยแพร่ประชาสัมพันธ์ “ECO Sticker มิติใหม่ของยานยนต์ไทยสู่ความยั่งยืน”
				ให้กับผู้จัดจำหน่ายและตัวแทนของผู้ผลิตหรือผู้นำเข้ารถยนต์ทุกค่าย
			</div>
		),
		img: [Pic14],
	},
	{
		date: "1 ตุลาคม 2558",
		taxt: (
			<div>
				บริษัทผู้ผลิตหรือผู้นำเข้ารถยนต์เริ่มติดป้ายข้อมูลรถยนต์ตามมาตรฐานสากล เป็นการทั่วไป
				สำหรับรถยนต์ที่ผลิตใหม่หรือนำเข้ามาในประเทศไทย ตั้งแต่วันที่ 1 ตุลาคม 2558 แล้ว
				ทั้งนี้เพื่อให้การดำเนินโครงสร้างภาษีสรรพสามิตรถยนต์ใหม่ ซึ่งจะเริ่มมีผลบังคับใช้ในวันที่ 1 มกราคม 2559
				เป็นไปอย่างราบรื่นตามกำหนดเวลา
			</div>
		),
		img: [Pic15],
	},
	{
		date: "19 สิงหาคม 2558",
		taxt: <div>กระทรวงอุตสาหกรรมได้ออกประกาศ เรื่อง การแสดงข้อมูลรถยนต์ตามมาตรฐานสากล</div>,
		img: [Pic16],
	},
	{
		date: "มีนาคม 2558",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรมได้จัดทำ Site Visit ณ
				บริษัทผู้ผลิตหรือผู้นำเข้ารถยนต์เพื่อรับฟังปัญหาและข้อเสนอแนะเกี่ยวกับการระบบป้ายข้อมูลรถยนต์ตามมาตรฐานสากล
			</div>
		),
		img: [Pic17],
	},
	{
		date: "16 มีนาคม 2558",
		taxt: (
			<div>
				กระทรวงการคลังและกระทรวงอุตสาหกรรมร่วมจัดงานเปิดตัวระบบป้ายข้อมูลรถยนต์ “ECO Sticker”
				และร่วมลงนามบันทึกความร่วมมือเพื่อส่งเสริมให้เกิดการใช้รถยนต์อย่างยั่งยืน (Sustainable Mobility)
				ด้วยกลไก ECO Sticker และภาษี CO<sub>2</sub>
			</div>
		),
		img: [Pic18],
	},
	{
		date: "21-27 มกราคม 2558",
		taxt: (
			<div>
				สำนักงานเศรษฐกิจอุตสาหกรรมได้จัดทำ Workshop ระบบป้ายข้อมูลรถยนต์ ให้กับผู้ผลิตหรือผู้นำเข้ารถยนต์ จำนวน
				18 กลุ่มบริษัท เพื่อให้ความรู้ความเข้าใจเกี่ยวกับการระบบป้ายข้อมูลรถยนต์ตามมาตรฐานสากล
				และรับข้อเสนอแนะ/ข้อคิดเห็นจากผู้ผลิตหรือผู้นำเข้าแต่ละราย
			</div>
		),
		img: [Pic19],
	},
	{
		date: "13 มกราคม 2558",
		taxt: (
			<div>
				กระทรวงอุตสาหกรรม โดยสำนักงานเศรษฐกิจอุตสาหกรรมได้มีการจัดงานสัมมนาเรื่อง
				“ระบบป้ายข้อมูลรถยนต์เพื่อรองรับโครงสร้างภาษีสรรพสามิตรถยนต์ใหม่”
				เพื่อเปิดตัวระบบป้ายข้อมูลรถยนต์อย่างเป็นทางการ
			</div>
		),
		img: [Pic20],
	},
	{
		date: "28 สิงหาคม 2557",
		taxt: (
			<div>
				กระทรวงอุตสาหกรรม โดยสำนักงานเศรษฐกิจอุตสาหกรรม ได้ร่วมกับบริษัท อินเทอร์เน็ตประเทศไทย จำกัด (มหาชน)
				พัฒนาระบบป้ายข้อมูลรถยนต์ให้เป็น Cloud Based Application อย่างสมบูรณ์แบบ
				เพื่ออำนวยความสะดวกให้กับผู้ผลิตรถยนต์ ตั้งแต่ส่วนของการลงทะเบียนของผู้ประกอบการ การลงทะเบียนรถยนต์
				จนถึงการสร้าง ECO Sticker เพื่อติดแสดงบนรถยนต์ทุกคัน
			</div>
		),
		img: [Pic21],
	},
	{
		date: "2556-2558",
		taxt: (
			<div>
				กระทรวงอุตสาหกรรม (สำนักงานเศรษฐกิจอุตสาหกรรม สำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม และสถาบันยานยนต์)
				กระทรวงการคลัง (กรมสรรพสามิต และกรมศุลกากร) และสมาคมผู้ผลิตยานยนต์ไทย
				และผู้ผลิต/ผู้นำเข้ารถยนต์รายบริษัท ได้ร่วมหารือเพื่อกำหนดแนวทางการแสดงค่า CO<sub>2</sub>{" "}
				และความต้องการใช้งานระบบป้ายข้อมูลรถยนต์
			</div>
		),
		img: [Pic22],
	},
	{
		date: "7 มกราคม 2557",
		taxt: (
			<div>
				กรมสรรพสามิตได้ออกประกาศกรมสรรพสามิต เรื่อง
				กำหนดหลักเกณฑ์และเงื่อนไขของรถยนต์ประเภทที่กำหนดให้ได้รับการลดอัตราภาษีสรรพสามิต โดยระบุว่า
				“เป็นรถยนต์ที่มีป้ายแสดงข้อมูลรถยนต์ตามหลักเกณฑ์ที่กระทรวงอุตสาหกรรมประกาศกำหนด
				โดยอย่างน้อยต้องมีป้ายซึ่งแสดงอัตราการปล่อยก๊าซคาร์บอนไดออกไซด์ (CO<sub>2</sub>)
				ข้อมูลอัตราการใช้พลังงาน ประเภทเครื่องยนต์ และระบบความปลอดภัยเชิงป้องกันก่อนเกิดเหตุ (Active Safety)
				(ในกรณีที่รถยนต์ต้องมีระบบความปลอดภัยดังกล่าว เพื่อให้ได้รับการลดอัตราภาษีสรรพสามิต) ติดไว้บนกระจกรถยนต์
				ณ จุดขาย (Point of Sales)”
			</div>
		),
		img: [Pic23],
	},
	{
		date: "24 เมษายน 2556",
		taxt: (
			<div>
				กระทรวงการคลังได้ออกประกาศกระทรวงการคลัง เรื่อง ลดอัตราและยกเว้นภาษีสรรพสามิต (ฉบับที่ ๑๐๙)
				โดยมีสาระสำคัญเกี่ยวกับการกำหนดอัตราภาษีของรถยนต์ประเภทต่างๆ ตามความจุกระบอกสูบ
				ปริมาณการปล่อยก๊าซคาร์บอนไดออกไซด์ (CO<sub>2</sub>) และมาตรฐานความปลอดภัย
			</div>
		),
		img: [Pic24],
	},
	{
		date: "18 ธันวาคม 2555",
		taxt: (
			<div>
				คณะรัฐมนตรีได้อนุมัติในหลักการร่างประกาศกระทรวงการคลัง เรื่อง ลดอัตราและยกเว้นภาษีสรรพสามิต (ฉบับที่ ..)
				(การปรับโครงสร้างภาษีสรรพสามิตรถยนต์) ตามที่กระทรวงการคลังเสนอ
				และมอบหมายให้กระทรวงอุตสาหกรรมกำหนดแนวทางให้ผู้ประกอบอุตสาหกรรมรถยนต์และผู้นำเข้าจะต้องติดป้ายแสดงการประหยัดพลังงานและปริมาณการปล่อยก๊าซคาร์บอนไดออกไซด์
				(CO<sub>2</sub>) ควบคู่ไปกับการปรับโครงสร้างภาษี CO<sub>2</sub>
			</div>
		),
		img: [Pic25],
	},
];

const SwitchSection = ({ now, onChange }) => {
	const handleOnChangSection = (val) => {
		if (val != now) onChange(val);
	};

	return (
		<div className={styles.switchContainer}>
			<div className={styles.switchBackdrop} />
			<div
				className={classNames(styles.switchItem, { [styles.switchActive]: now == 0 })}
				onClick={() => handleOnChangSection(0)}
			>
				จุดเริ่มต้น
			</div>
			<div
				className={classNames(styles.switchItem, { [styles.switchActive]: now == 1 })}
				onClick={() => handleOnChangSection(1)}
			>
				ปัจจุบัน
			</div>
		</div>
	);
};

const TimelineBox = ({ mode, handleView }) => {
	let list = [...Timline];
	if (!mode) {
		list.reverse();
	}
	return (
		<div className={styles.timelineContent}>
			<div className={styles.timelineBackdrop} />
			{list.map((item, key) => {
				return <TimelineItem data={item} key={key} mode={mode} onView={handleView} />;
			})}
		</div>
	);
};

const TimelineItem = ({ data, mode, onView }) => (
	<div className={styles.timelineItem}>
		{Boolean(data?.date) && (
			<div className={classNames(styles.timelineDate, { [styles.timelineReverse]: !mode })}>
				<div className={styles.timelineDot} />
				<span className={styles.timelineDateSpan}>{data.date}</span>
			</div>
		)}
		<div className={styles.timelineItemContent}>
			<div className={styles.itemText}>{data?.taxt}</div>
			<div className={styles.itemImg}>
				<img src={data?.img[0]} className={styles.itemImgContent} />
				<div className={styles.fadeUp} onClick={() => onView(data.img)}>
					ดูรูปภาพ
				</div>
			</div>
		</div>
		{/* {Boolean(data?.date && mode) && (
			<div className={styles.timelineDate}>
				<div className={styles.timelineDot} />
				<span className={styles.timelineDateSpan}>{data.date}</span>
			</div>
		)} */}
	</div>
);

const AboutUsPage = () => {
	const [now, setNow] = useState(1);
	const [viewOpen, setViewOpen] = useState(false);
	const [viewIndex, setViewIndex] = useState(0);
	const [viewState, setViewState] = useState([]);

	useEffect(() => {
		let elem = document.getElementsByTagName("body");
		if (viewOpen) {
			elem[0].classList.add("on-modale-open");
		} else {
			setViewIndex(0);
			elem[0].classList.remove("on-modale-open");
		}
	}, [viewOpen]);

	const handleViewImage = (list) => {
		setViewState(list);
		setViewOpen(true);
	};

	const openEcoMotor = () => {
		window.open("https://motorcycle.go.th");
	};

	const openEcoTyre = () => {
		window.open("https://tyre.go.th");
	};

	return (
		<Page title="เกี่ยวกับเรา">
			<div className={styles.pageContent}>
				<Container>
					<div className={styles.pageContainer}>
						<div className={styles.pageTop}>
							<div className={styles.pageTopItem}>
								<div className={styles.tickTopText}>
									<span className={styles.tickText}>ความเป็นมา</span>ของ
								</div>
								<span className={styles.gradientText}>ecosticker</span>
							</div>

							<div className={classNames(styles.pageTopItem, styles.center)}>
								<SwitchSection now={now} onChange={setNow} />
							</div>
						</div>
						<Stack width="100%" spacing={2} direction="row" justifyContent="flex-start" p={2}>
							<Button
								onClick={openEcoMotor}
								sx={{
									padding: "1rem",
									border: "1px solid green",
									boxSizing: "border-box",
									borderRadius: 40,
									fontSize: 16,
								}}
								color="success"
								variant="contained"
							>
								Eco Sticker Motor
							</Button>
							<Button
								onClick={openEcoTyre}
								sx={{
									padding: "1rem",
									border: "1px solid green",
									boxSizing: "border-box",
									borderRadius: 40,
									fontSize: 16,
								}}
								color="success"
								variant="contained"
							>
								Eco Sticker Tyre
							</Button>
						</Stack>

						<TimelineBox mode={now} handleView={handleViewImage} />
					</div>
				</Container>
				<ModalViewImg
					open={viewOpen}
					list={viewState}
					now={viewIndex}
					onClose={setViewOpen}
					callback={setViewIndex}
				/>
			</div>
		</Page>
	);
};

export default AboutUsPage;
