import { Box, Container, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "./Articles.module.scss";
import { Page } from "src/components/GobalElement";
import styled from "@emotion/styled";
import imageKnow1 from "../Articles/images/know-eco-sticker.png";
import imageKnow2 from "../Articles/images/know-eco-sticker2.png";

const DATA_CONTENT_SECTION_1 = [
	{
		url: "https://www.youtube.com/embed/jZ_ImqPdMuk",
		title: "สะอาด",
		// <iframe width="560" height="315" src="https://www.youtube.com/embed/jZ_ImqPdMuk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	},
	{
		url: "https://www.youtube.com/embed/1opHft9JQ7o",
		title: "ประหยัด",
		// <iframe width="560" height="315" src="https://www.youtube.com/embed/1opHft9JQ7o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	},
	{
		url: "https://www.youtube.com/embed/zVnXTLv66w4",
		title: "ปลอดภัย",
		// <iframe width="560" height="315" src="https://www.youtube.com/embed/zVnXTLv66w4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	},
	{
		url: "https://www.youtube.com/embed/cWyz0oBc4Ac",
		title: "Q&A",
		// <iframe width="560" height="315" src="https://www.youtube.com/embed/cWyz0oBc4Ac" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	},
];

const KnowingEcoSticker = () => {
	return (
		<Page title="รอบรู้เรื่อง “ECO Sticker”">
			<div className={styles.pageContent}>
				<Container>
					<Stack spacing={4}>
						<Typography variant="span" fontSize={48} fontWeight={400} color={"#008000"}>
							ระบบป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (ECO Sticker)
						</Typography>
						<Stack spacing={2} alignItems={"center"} justifyContent={"center"} direction={"row"}>
							{DATA_CONTENT_SECTION_1.map((item, i) => (
								<CardContainer>
									<Stack justifyContent={"center"}>
										<iframe
											height={"100%"}
											width="100%"
											src={item.url}
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											allowfullscreen
										/>
										<Typography
											p={2}
											variant="p"
											fontSize={22}
											fontWeight={400}
											textAlign={"center"}
										>
											{item.title}
										</Typography>
									</Stack>
								</CardContainer>
							))}
						</Stack>
						<Stack direction={"row"} spacing={2}>
							<BoxMainContent width={"50%"}>
								<Stack spacing={2}>
									<Typography fontSize={20} variant="span" textAlign={"center"}>
										ระบบป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (ECO Sticker)
									</Typography>
									<ImageContent width={"100%"}>
										<img style={{ objectFit: "cover" }} src={imageKnow1} />
									</ImageContent>
								</Stack>
							</BoxMainContent>
							<BoxMainContentRight width={"50%"}>
								<Stack spacing={2}>
									<Typography fontSize={20} variant="span" textAlign={"center"}>
										ประเทศที่มีป้ายข้อมูลรถยนต์
									</Typography>
									<ImageContent width={"100%"}>
										<img style={{ objectFit: "cover" }} src={imageKnow2} />
									</ImageContent>
								</Stack>
							</BoxMainContentRight>
						</Stack>
					</Stack>
				</Container>
			</div>
		</Page>
	);
};

const CardContainer = styled(Box)({
	width: 270,
	overflow: "hidden",
	boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
	borderRadius: 5,
});

const BoxMainContent = styled(Box)({
	// backgroundColor: "#F5F5F5",
	boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
	// boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
	padding: 15,
	borderRadius: 5,
});
const BoxMainContentRight = styled(Box)({
	padding: 15,
});

export const ImageContent = styled(Box)({
	borderRadius: 5,
	overflow: "hidden",
	img: {
		borderRadius: 5,

		width: "100%",
		height: "100%",
		objectFit: "cover",
	},
});

export default KnowingEcoSticker;
