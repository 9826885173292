import React, { useEffect, useRef, useState } from "react";
import { Container, IconButton, Tooltip } from "@mui/material";
import NotFound from "../components/NotFound";
import Header from "src/components/car-detail/Header";
import Section1 from "src/components/car-detail/Section-1";
import CarListLoading from "src/components/loading/CarListLoading";
import Section2 from "src/components/car-detail/Section-2";
import classNames from "classnames";
import { Footer } from "src/components/layout";
import { Page } from "src/components/GobalElement";
import useDetailContext from "src/contexts/detailContext";
import useSticker from "src/helper/stickers";
import { useParams } from "react-router-dom";
import { getCarDetail, getDetailtyre } from "src/helper/allAPI";
import { useSnackbar } from "notistack";
import ShareIcon from "@mui/icons-material/Share";
import Modal from "src/components/Modal";

export const ErrorContent = ({ detail }) => (
	<span style={{ fontSize: 14 }}>{detail || "ระบบขัดข้อง กรุณาติดต่อเจ้าหน้าที่"}</span>
);

const CarDetailV2 = () => {
	const [detailContext, setDetailContext] = useDetailContext();
	const { error, isLoaded, data } = detailContext;
	const [scrolled, setScrolled] = useState(false);
	const { enqueueSnackbar } = useSnackbar();
	const [open_modals_share, setOpenModalsShare] = useState(false);
	const [data_modals_share, setDataModalsShare] = useState({});
	const pageRef = useRef(null);

	const { getSticker, isGenerating } = useSticker();

	let { id } = useParams();

	const getEnergyConsumptionSuffix = (type) => {
		let res = { consumption_suffix: "", consumption_urban_suffix: "" };
		switch (type) {
			case "BEV":
				res.consumption_suffix = "วัตต์/100 กม.";
				res.consumption_urban_suffix = "วัตต์/100 กม.";
				break;
			case "PHEV":
				res.consumption_suffix = "วัตต์/100 กม. | ลิตร/100 กม.";
				res.consumption_urban_suffix = "ลิตร/100 กม.";
				break;
			default:
				res.consumption_suffix = "ลิตร/100 กม.";
				res.consumption_urban_suffix = "ลิตร/100 กม.";
				break;
		}
		return res;
	};

	const fnHandleOpenModalsShare = (data) => {
		setDataModalsShare(data);
		setOpenModalsShare(true);
	};

	const fnHnadleonCloseModalsShare = () => {
		setOpenModalsShare(false);
		setDataModalsShare({});
	}

	const fetchData = async () => {
		try {
			const {
				data: { data },
			} = await getCarDetail(id);
			const responseSticker = await getSticker(id, true);
			const consumptionSuffix = getEnergyConsumptionSuffix(data.cartype_name);
			setDetailContext({
				...detailContext,
				sticker: responseSticker,
				data: { ...data, ...consumptionSuffix },
				isLoaded: true,
			});
		} catch (e) {
			setDetailContext({
				...detailContext,
				error: {
					fetchError: true,
					stickerError: false,
				},
				isLoaded: true,
			});
		}
	};

	// useEffect(() => {
	// 	setDetailContext({
	// 		...detailContext,
	// 		isGenerating,
	// 	});
	// }, [isGenerating]);

	const onScroll = () => {
		const offsetPage = parseInt(window.scrollY);
		if (offsetPage == 0) {
			setScrolled(false);
		} else {
			setScrolled(true);
		}
	};

	const handleGetDetailTyre = async (id, name) => {
		if (!id) {
			enqueueSnackbar({
				text: "เกิดข้อผิดพลาด",
				variant: "error",
				content: <ErrorContent detail={"ไม่พบ eco sticker id"} />,
			});
		} else {
			try {
				let tyreCar = await getDetailtyre([{ name: name, value: id }]);
				if (tyreCar?.data?.value[0].note === "") {
					window.open(`https://tyre.go.th/company/sticker-public-preview/${tyreCar?.data?.value[0].size_id}`);
				} else {
					enqueueSnackbar({
						text: "เกิดข้อผิดพลาด",
						variant: "error",
						content: <ErrorContent detail={tyreCar?.data?.value[0].note} />,
					});
				}
			} catch (error) {
				enqueueSnackbar({
					text: "เกิดข้อผิดพลาด",
					variant: "error",
					content: <ErrorContent detail={"ไม่พบ eco sticker id"} />,
				});
			}
		}
	};

	useEffect(() => {
		if (id) {
			fetchData();
		} else {
			setDetailContext({
				...detailContext,
				error: {
					fetchError: true,
					stickerError: false,
				},
				isLoaded: true,
			});
		}

		if (pageRef?.current && pageRef?.current?.offsetWidth > 1024) {
			window.addEventListener("scroll", onScroll);
		}

		return () => {
			window.removeEventListener("scroll", onScroll);
		};
	}, []);

	return (
		<Page title={isLoaded ? `${data?.brand} ${data?.model}` : "ข้อมูลรถยนต์"} ref={pageRef}>
			<Modal.ShareComponent open={open_modals_share} id={id} data={data} onClose={fnHnadleonCloseModalsShare} />
			<Container sx={{ position: "relative", minHeight: "100vh", padding: "0 !important" }}>
				<Header />
				{isLoaded ? (
					<>
						{error.fetchError ? (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									minHeight: "100vh",
								}}
							>
								<NotFound detail="ไม่พบข้อมูลรถ" />
							</div>
						) : (
							<>
								<Section1 parentClass={classNames({ scrolled })} />

								<Section2 handleGetDetailTyre={handleGetDetailTyre} />
							</>
						)}
					</>
				) : (
					<div
						style={{ display: "flex", alignItems: "center", justifyContent: "center", minHeight: "100vh" }}
					>
						<CarListLoading key={0} />
					</div>
				)}
			</Container>
			<div
				style={{
					position: "sticky",
					bottom: 0,
					width: "100%",
					display: "flex",
					justifyContent: "flex-end",
					alignItems: "center",
					padding: "30px",
				}}
			>
				<div
					style={{
						borderRadius: 50,
						zIndex: 99999,
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Tooltip title="Share" placement="top">
						<IconButton onClick={fnHandleOpenModalsShare} sx={{ width: "70px", height: "70px",background:"#fff", color: "#12c205",boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px" }} size="large">
							<ShareIcon sx={{ fontSize: "30px" }} />
						</IconButton>
					</Tooltip>
				</div>
			</div>
			{isLoaded && <Footer />}
		</Page>
	);
};

export default CarDetailV2;
