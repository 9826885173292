import { Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Page } from "../../components/GobalElement";
import styles from "./Articles.module.scss";
import pic22 from "../../assets/acticle/pic-22.png";
import tax2560 from "../../assets/acticle/Taxrate2560_All.pdf";
import tax2562 from "../../assets/acticle/Taxrate2562.pdf";
import tax2565 from "../../assets/acticle/Taxrate2565.pdf";
import picTax from "../../assets/acticle/pickup-tax.png";
import ModalViewContent from "../../components/ModalViewContent";

const ContentType = {
	tax: picTax,
	tax2560: tax2560,
	tax2562: tax2562,
	tax2565: tax2565,
};

const WhatIsTax = () => {
	const [openModal, setOpenModal] = useState(false);
	const [content, setContent] = useState("");

	const handleCloseModal = (state) => {
		setOpenModal(state);
		setContent("");
	};

	const handleOpenModal = (type) => {
		setContent(type);
		setOpenModal(true);
	};

	useEffect(async () => {
		let elem = document.getElementsByTagName("body");

		if (openModal) {
			elem[0].classList.add("on-modale-open");
		} else {
			elem[0].classList.remove("on-modale-open");
		}
	}, [openModal]);

	return (
		<Page title="ภาษีสรรพสามิตคืออะไร">
			<ModalViewContent open={openModal} onClose={handleCloseModal} moreWidth={content == "tax"}>
				{content && (
					<>
						{content == "tax" ? (
							<div className={styles.boxImageContent}>
								<img src={ContentType[content]} alt="tax" className={styles.imgContent} />
							</div>
						) : (
							<iframe src={ContentType[content]} alt="tax" className={styles.pdfContent} />
						)}
					</>
				)}
			</ModalViewContent>
			<div className={styles.pageContent}>
				<Container>
					<div className={styles.pageContainer}>
						<span className={styles.pageTopic}>ภาษีสรรพสามิตคืออะไร</span>
						<div className={styles.imgSection}>
							<img src={pic22} className={styles.imgTopSecondary} />
						</div>
						<div className={styles.sectionBreak} />
						<div className={styles.sectionButton}>
							<span className={styles.contentButton} onClick={() => handleOpenModal("tax")}>
								อัตราภาษีสรรพสามิตรถยนต์ปัจจุบัน
							</span>
							<span className={styles.contentButton} onClick={() => handleOpenModal("tax2560")}>
								กฎกระทรวงพ.ศ. 2560
							</span>
							<span className={styles.contentButton} onClick={() => handleOpenModal("tax2562")}>
								กฎกระทรวงพ.ศ. 2562
							</span>
							<span className={styles.contentButton} onClick={() => handleOpenModal("tax2565")}>
								กฎกระทรวงพ.ศ. 2565
							</span>
						</div>
						<div className={styles.sectionBreak} />
					</div>
				</Container>
			</div>
		</Page>
	);
};

export default WhatIsTax;
