import { Fragment, useEffect, useState } from "react";
import styles from "../css/Sticker_ICE_new.module.css";
import fa from "../fontawesome/css/font-awesome.min.module.css";
import ecoLogo from "../images/icon-eco-sticker.jpeg";
import iconOil from "../images/icon-oil.png";
const Sticker = ({
	id,
	data: {
		standard_main,
		standard,
		basic_information,
		equipment_factory,
		company,
		other_condition,
		qr_src,
		logo_src,
		size_sticker,
		is_gray_scale,
	},
}) => {
	const [newVersionChrome, setNewVersionChrome] = useState(false);
	useEffect(() => {
		var userAgent = window.navigator.userAgent;

		if (userAgent.indexOf("Chrome") !== -1) {
			// Extract the version number
			var versionIndex = userAgent.indexOf("Chrome") + 7;
			var version = userAgent.substring(versionIndex).split(" ")[0];

			var inputString = version;
			var versionString = inputString.replace("Google Chrome version: ", "");
			var versionNumber = parseFloat(versionString);
			if (versionNumber == 117) {
				setNewVersionChrome(true);
			} else {
				setNewVersionChrome(false);
			}
		} else {
			console.log("This is not Google Chrome.");
		}
	}, []);

	return (
		<Fragment>
			<div
				{...(is_gray_scale ? { style: { filter: "grayscale(1)" } } : null)}
				name={id}
				id={styles[size_sticker === "A5" ? "car-body-a5" : "car-body"]}
			>
				<div
					id="water-mark"
					className={styles["water-mark"]}
					style={{
						display: other_condition.overlap_text ? "block" : "none",
					}}
				>
					ECO Sticker
				</div>
				<div className={styles["emission-container"]}>
					<div
						className={styles["remark-container"]}
						id="introductory"
						style={{
							display: other_condition.introductory ? "block" : "none",
						}}
					>
						ECO Sticker เบื้องต้น
					</div>
					{/* CO2*/}
					{/*  */}

					{/*  */}
					<div
						id="co2"
						className={styles[newVersionChrome ? "emissionCO2-block-new-version" : "emissionCO2-block"]}
					>
						{standard_main.co2}
					</div>

					{/* ENERGY CONSUMPTION */}
					<div
						className={styles[newVersionChrome ? "energy-consumption-block-new-version" : "energy-consumption-block"]}
						id="combined"
					>
						{standard_main.combined}
					</div>

					{/* ENERGY CONSUMPTION (km/L) */}
					<div
						className={
							styles[newVersionChrome ? "combined-energy-consumption-block-new-version" : "combined-energy-consumption-block"]
						}
						id="combined_km_l"
					>
						{standard_main.combined_km_l}
					</div>

					{/* สภาวะในเมือง */}
					<div
						className={styles[newVersionChrome ? "energy-urban-block-new-version" : "energy-urban-block"]}
						id="in_city"
					>
						{standard_main.in_city}
					</div>

					{/* สภาวะนอกเมือง */}
					<div
						className={styles[newVersionChrome ? "energy-ex-urban-block-new-version" : "energy-ex-urban-block"]}
						id="out_city"
					>
						{standard_main.out_city}
					</div>
				</div>

				{/* อัตราการใช้นำมันอ้างอิง*/}
				<div className={styles["slider-container"]}>
					<div
						id="fuel_block"
						style={{
							left: parseFloat(standard_main.combined) > 10 ? 550 : parseFloat(standard_main.combined) * 10 * 4.819,
						}}
						className={styles["slider-fuel-block"]}
					>
						<img
							alt="icon_oil"
							src={iconOil}
						></img>
						<div
							id="fuel_txt"
							className={styles["slider-fuel-text"]}
						>
							{standard_main.combined}
						</div>
					</div>
				</div>

				<div className={styles["standard-section-container"]}>
					{/* มาตรฐานด้านสิ่งแวดล้อม container*/}
					<div className={styles["environment-standard-container"]}>
						{/*  มอก. */}
						<div className={styles.standard}>
							<i
								id="tisi"
								className={`${fa["fa"]} ${fa[standard.tisi ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
								style={{ color: "#42b52c", fontSize: "35px" }}
							></i>
						</div>

						{/*  EURO4 */}
						<div className={styles.euro4}>
							<i
								id="euro4"
								className={`${fa["fa"]} ${fa[standard.euro4 ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
								style={{ color: "#42b52c", fontSize: "35px" }}
							></i>
						</div>

						{/*  EURO5 */}
						<div className={styles.euro5}>
							<i
								id="euro5"
								className={`${fa["fa"]} ${fa[standard.euro5 ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
								style={{ color: "#42b52c", fontSize: "35px" }}
							></i>
						</div>

						{/*  EURO6 */}
						<div className={styles.euro6}>
							<i
								id="euro6"
								className={`${fa["fa"]} ${fa[standard.euro6 ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
								style={{ color: "#42b52c", fontSize: "35px" }}
							></i>
						</div>
					</div>

					{/* มาตรฐานด้วนความปลอดภัย Container*/}
					<div className={styles["security-standard-container"]}>
						<div className={styles["brake-standard-container"]}>
							{/*  ABS+ESC */}
							<div className={styles.abs}>
								<i
									id="abs_esc"
									className={`${fa["fa"]} ${fa[standard.abs_esc ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
									style={{ color: "#42b52c", fontSize: "35px" }}
								></i>
							</div>

							{/*  UN R13 */}
							<div className={styles.un_r13}>
								<i
									id="un_r13"
									className={`${fa["fa"]} ${fa[standard.un_r13 ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
									style={{ color: "#42b52c", fontSize: "35px" }}
								></i>
							</div>

							{/*  UN R13H */}
							<div className={styles.un_r13h}>
								<i
									id="un_r13h"
									className={`${fa["fa"]} ${fa[standard.un_r13h ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
									style={{ color: "#42b52c", fontSize: "35px" }}
								></i>
							</div>
						</div>

						<div className={styles["passenger-standard-container"]}>
							{/*  UN R94 */}
							<div className={styles.un_r94}>
								<i
									id="un_r94"
									className={`${fa["fa"]} ${fa[standard.un_r94 ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
									style={{ color: "#42b52c", fontSize: "35px" }}
								></i>
							</div>

							{/*  UN R95 */}
							<div className={styles.un_r95}>
								<i
									id="un_r95"
									className={`${fa["fa"]} ${fa[standard.un_r95 ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
									style={{ color: "#42b52c", fontSize: "35px" }}
								></i>
							</div>
						</div>
						<div className={styles["electric-standard-container"]}>
							{/*  UN R100 */}
							<div className={styles.un_r100}>
								<i
									id="un_r100"
									className={`${fa["fa"]} ${fa[standard.un_r100 ? "fa-check-square-o" : "fa-square-o"]} ${fa["fa-3x"]}`}
									style={{ color: "#42b52c", fontSize: "35px" }}
								></i>
							</div>

							{/* un_r100tisi */}
							<div className={styles.un_r100tisi}>
								<i
									id="un_r100tisi"
									className={`${fa["fa"]} ${fa[standard.un_r100tisi ? "fa-check-square-o" : "fa-square-o"]} ${
										fa["fa-3x"]
									}`}
									style={{ color: "#42b52c", fontSize: "35px" }}
								></i>
							</div>
						</div>
					</div>

					{/*  QR CODE */}
					<div className={styles["qr-container"]}>
						<div
							id="water-mark-qr"
							className={styles["water-mark-qr"]}
							style={{
								display: other_condition.overlap_text ? "block" : "none",
							}}
						>
							ECO Sticker
						</div>
						<div
							className={styles["qr-text-block"]}
							id="qr_code_id"
						>
							{basic_information.qr_code_id}
						</div>
						<div className={styles["qr-img-block"]}>
							<img
								alt="qr-logo"
								id="qr-logo"
								className={styles["qr-logo"]}
								src={ecoLogo}
							/>
							{qr_src && (
								<img
									alt="qr-img"
									id="qr-img"
									src={qr_src}
								/>
							)}
						</div>
					</div>
				</div>
				{/* <div> */}
				<div
					style={{ display: size_sticker === "A5" && "none" }}
					className={styles["equipment-section-container"]}
				>
					{/*  ข้อมูลพื้นฐาน */}
					<div className={styles["basic-information-block"]}>
						<div>
							ยี่ห้อ&nbsp;&nbsp;:&nbsp;
							<span id="brand">{basic_information.brand}</span>
						</div>

						<div style={{ overflow: "hidden", height: "28px" }}>
							รุ่น&nbsp;
							<span style={{ marginLeft: "12.5px" }}>:&nbsp;</span>
							<span id="model">{basic_information.model}</span>
						</div>

						<div>
							แบบ&nbsp;<span style={{ marginLeft: "3px" }}>:&nbsp;</span>
							<span id="car_style">{basic_information.car_style}</span>
						</div>

						<div>
							หมายเลขตัวถัง (VIN) :&nbsp;
							<span id="vinno">{basic_information.vinno}</span>
						</div>

						<div>
							รหัสโครงรถ&nbsp;
							<span style={{ marginLeft: "46.5px" }}>
								:&nbsp;
								<span id="carriage_code">{basic_information.carriage_code}</span>
							</span>
						</div>

						<div>
							รหัสเครื่องยนต์&nbsp;
							<span style={{ marginLeft: "31.5px" }}>
								:&nbsp;
								<span id="engine_code">{basic_information.engine_code}</span>
							</span>
						</div>

						<div>
							เครื่องยนต์&nbsp;
							<span style={{ marginLeft: "53px" }}>
								:&nbsp;
								<span id="engine_and_capacity_cylinder">
									{basic_information.engine_and_capacity_cylinder}
								</span>
							</span>
						</div>

						<div>
							ระบบเกียร์&nbsp;
							<span style={{ marginLeft: "54px" }}>
								:&nbsp;
								<span id="gear_system_name">{basic_information.gear_system_name}</span>
							</span>
						</div>

						<div>
							น้ำหนักรถ&nbsp;
							<span style={{ marginLeft: "55.5px" }}>
								:&nbsp;
								<span id="total_weight">{basic_information.total_weight}</span>
							</span>
						</div>

						<div>
							ขนาดยาง(หน้า,หลัง)&nbsp;
							<span style={{ marginLeft: "5.5px" }}>
								:&nbsp;
								<span id="tire_size">{basic_information.tire_size}</span>
							</span>
						</div>
						<div>
							จำนวนที่นั่ง&nbsp;
							<span style={{ marginLeft: "50px" }}>
								:&nbsp;
								<span id="car_seats">{basic_information.car_seats}</span>
							</span>
						</div>

						<div>
							ประเภทเชื้อเพลิง&nbsp;
							<span style={{ marginLeft: "23.5px" }}>
								:&nbsp;
								<span id="fuel_name">{basic_information.fuel_name}</span>
							</span>
						</div>

						<div>
							โรงงานที่ผลิต&nbsp;:&nbsp;
							<span id="factory">{basic_information.factory}</span>
						</div>
					</div>

					<div className={styles["importer-container"]}>
						<div className={styles["importer-img-block"]}>
							{/*  LOGO ผู้ผลิต / ผู้นำเข้า */}
							{logo_src && (
								<img
									alt="company-logo"
									id="company-logo"
									src={logo_src}
								/>
							)}
						</div>

						{/*  ข้อมูลผู้ผลิต / ผู้นำเข้า */}
						<div className={styles["importer-text-block"]}>
							<div>
								<div>
									ชื่อ&nbsp;: <span id="name">&nbsp; {company.name}</span>
								</div>
								<div>
									ที่อยู่&nbsp;: <span id="address">{company.address}</span>
								</div>
								<div>
									เว็บไซต์&nbsp;:{" "}
									<span
										id="website"
										className={styles.fontwww}
									>
										&nbsp;{company.website}
									</span>
								</div>
							</div>
						</div>
					</div>

					{/*  อุปกรณ์ที่ติดตั้งจากโณงงาน */}
					<div className={styles["equipment-block"]}>
						<ol id="equipment_factory">
							{equipment_factory.map((data, idx) => (
								<li key={`${data}+${idx}`}>{data}</li>
							))}
						</ol>
					</div>

					{/*  ราคาขายปลีกแนะนำ */}
					<div className={styles["recommend-price-block"]}>
						<span id="price">{basic_information.price}</span>
						<span className={styles["price-suffix"]}>&nbsp;บาท</span> (&nbsp;
						<span id="tax_rate">{basic_information.tax_rate}</span>
						<span className={styles["price-suffix"]}>{basic_information.tax_rate !== "ไม่มีอัตรา" && "%"}&nbsp;</span>)
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default Sticker;
