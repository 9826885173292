export const SELECT_COMPARE_CONFIG = {
    MIN: 2,
    MAX: 4
}

export const CAR_TYPE_NAME = {
    ICE: 'รถยนต์ใช้น้ำมัน',
    BEV: 'รถยนต์ไฟฟ้า',
    PHEV: 'รถยนต์ไฮบริด'
}

export const CAR_TYPE_UNIT = {
    ICE: 'ลิตร/100กม.',
    BEV: 'วัตต์/100กม.',
    PHEV: 'วัตต์/100กม.'
}
