import classNames from 'classnames';
import React from 'react';
import styles from "./ModalFilter.module.scss";

const ModalFilter = ({ refs, open, title, onCloseModal, children }) => {
    return (
        <div ref={refs} className={classNames(styles.modalBackdrop, { [styles.modalOpen]: open })}>
            <div className={styles.modalContainer}>
                <div className={styles.modalContent}>
                    <div className={styles.modalContentClose} onClick={() => onCloseModal(false)}><i className="fa-solid fa-xmark"></i></div>
                    <div className={styles.modalHeader}>
                        <span className={styles.modalHeaderTopic}>{title}</span>
                        <div className={styles.modalHeaderBar} />
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalFilter;