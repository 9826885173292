import React, { useEffect } from 'react';
import { Link, useLocation, useMatch, useResolvedPath } from 'react-router-dom';
import styles from './Header.module.scss';
import Logo from '../../assets/carDetail/backdrop/new-logo.png';
import HeaderBackdrop from '../../assets/carDetail/backdrop/header.png';
import { MENU_LIST_LEFT, MENU_LIST_RIGHT } from '../../configs/menuConfig';
import classNames from 'classnames';
import { Container, Hidden, Stack } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import MenuMobile from '../layout/MenuMobile';
import useDetailContext from 'src/contexts/detailContext';

const NavbarItem = ({ children, to, className }) => {
	let resolved = useResolvedPath(to);
	let match = useMatch({ path: resolved.pathname, end: true });
	return (
		<Link to={to} className={classNames(className, { [styles.active]: match })}>
			{children}
		</Link>
	);
};

const Header = () => {
	const [{ data, isLoaded, error }] = useDetailContext();

	const [hide, setHide] = useState(false);

	const onScroll = () => {
		const offsetPage = parseInt(window.scrollY);
		if (offsetPage > 0) {
			setHide(true);
		} else {
			setHide(false);
		}
	};

	const [open, setOpen] = useState(false);

	const location = useLocation();

	useEffect(() => {
		if (open) {
			setOpen(false);
		}
	}, [location]);

	useEffect(() => {
		if (window.innerWidth > 1024) {
			window.addEventListener('scroll', onScroll);
		}
		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	return (
		<>
			<div
				className={classNames(styles.navbarContainer, {
					[styles.navbarLoaded]: isLoaded,
				})}
			>
				<Hidden lgDown>
					<Container
						style={{
							padding: 0,
							backgroundImage: `url(${HeaderBackdrop})`,
							backgroundPosition: 'bottom left',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							overflow: 'hidden',
						}}
						className={styles.containerBackdrop}
					>
						{/* <div style={{ width: "100%", background: "#33333",height:'63px' }}></div> */}
						<div
							className={classNames(styles.topSection, {
								[styles.topSectionHide]: hide && !error.fetchError,
							})}
						>
							<div className={classNames(styles.navbarItemSide, styles.left)}>
								{MENU_LIST_LEFT.map((item, key) => {
									return (
										<NavbarItem key={key} to={item.path} className={styles.navbarItem}>
											{item.title}
										</NavbarItem>
									);
								})}
							</div>
							<NavbarItem to="/" className={styles.navbarItemCenter}>
								<img src={Logo} className={styles.navbarItemLogo} />
							</NavbarItem>
							<div className={styles.navbarItemSide}>
								{MENU_LIST_RIGHT.map((item, key) => {
									return (
										<NavbarItem key={key} to={item.path} className={styles.navbarItem}>
											{item.title}
										</NavbarItem>
									);
								})}
							</div>
						</div>
						<div
							className={classNames(styles.middleSection, {
								[styles.middleSectionLoaded]: isLoaded && !error.fetchError,
								[styles.middleSectionHide]: hide,
							})}
						>
							<div className={styles.middleLineSection} />
						</div>
						<div
							className={classNames(styles.bottomSection, {
								[styles.bottomSectionLoaded]: isLoaded && !error.fetchError,
								[styles.middleSectionHide]: hide,
							})}
						>
							<div className={styles.constainer}>
								<NavbarItem
									to="/"
									className={classNames(styles.logoAfterHide, { [styles.middleSectionHide]: hide })}
								>
									<img src={Logo} className={styles.navbarItemLogo} />
								</NavbarItem>
								<div className={styles.containLeft}>
									<div className={styles.brand}>{data?.brand}</div>
									<div className={styles.model}>รุ่น {data?.model}</div>
								</div>
								<div className={styles.containRight}>
									<div className={styles.recommendedRetailPriceText}>ราคาขายปลีกที่แนะนำ</div>
									<div className={styles.recommendedRetailPriceNumberBox}>
										<div className={styles.recommendedRetailPriceNumber}>
											{data?.recomend_retail_price_new}
										</div>
										<span>บาท</span>
									</div>

									<div className={styles.approveDate}>
										อนุมัติเมื่อ{' '}
										{data?.approve_date
											? moment(data.approve_date).format('DD/MM/yyyy')
											: 'ไม่พบข้อมูล'}
									</div>
								</div>
							</div>
							{/* {data?.remark_price
								? data?.remark_price.replace(/\s/g, '') && (
										<div className={styles.remarkBlock}>
											<div className={styles.remarkTitle}>***หมายเหตุ&nbsp;: &nbsp;</div>
											<div className={styles.remarkDetail}>{data?.remark_price}</div>
										</div>
								  )
								: ''} */}
						</div>

						{/* <NavbarDetailSm /> */}
					</Container>
				</Hidden>
				<Hidden lgUp smDown>
					<Container
						style={{
							padding: 0,
							backgroundImage: `url(${HeaderBackdrop})`,
							backgroundPosition: 'bottom left',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							overflow: 'hidden',
						}}
						className={styles.containerBackdrop}
					>
						<div
							className={classNames(styles.bottomSection, {
								[styles.bottomSectionLoaded]: isLoaded && !error.fetchError,
							})}
						>
							<div className={styles.constainer}>
								<div className={styles.containLeft}>
									<div className={styles.brand}>{data?.brand}</div>
									<div className={styles.model}>รุ่น {data?.model}</div>
								</div>
								<NavbarItem to="/" className={styles.logoAfterHide}>
									<img src={Logo} className={styles.navbarItemLogo} />
								</NavbarItem>
								<div className={styles.containRight}>
									<div className={styles.recommendedRetailPriceText}>ราคาขายปลีกที่แนะนำ</div>
									<div className={styles.recommendedRetailPriceNumberBox}>
										<div className={styles.recommendedRetailPriceNumber}>
											{data?.recomend_retail_price_new}
										</div>
										<span>บาท</span>
									</div>

									<div className={styles.approveDate}>
										อนุมัติเมื่อ{' '}
										{data?.approve_date
											? moment(data.approve_date).format('DD/MM/yyyy')
											: 'ไม่พบข้อมูล'}
									</div>
								</div>
							</div>
							{/* {data?.remark_price
								? data?.remark_price.replace(/\s/g, '') && (
										<div className={styles.remarkBlock}>
											<div className={styles.remarkTitle}>***หมายเหตุ&nbsp;: &nbsp;</div>
											<div className={styles.remarkDetail}>{data?.remark_price}</div>
										</div>
								  )
								: ''} */}
						</div>
					</Container>
				</Hidden>
				<Hidden smUp>
					<div
						style={{
							padding: 0,
							backgroundImage: `url(${HeaderBackdrop})`,
							backgroundPosition: 'bottom left',
							backgroundSize: 'cover',
							backgroundRepeat: 'no-repeat',
							overflow: 'hidden',
							borderRadius: 0,
							width: '100%',
						}}
						className={styles.containerBackdropSm}
					>
						<Stack
							width="100%"
							direction="row"
							alignItems="center"
							p={2}
							className={styles.navbarContainerSm}
						>
							<div className={classNames(styles.navbarItemSide, styles.sm)}>
								<div className={styles.navbarMenu} onClick={() => setOpen(true)}>
									<i className="fa-solid fa-bars-staggered"></i>
								</div>
							</div>
							<NavbarItem to="/" className={classNames(styles.navbarItemCenter, styles.sm)}>
								<img src={Logo} className={styles.navbarItemLogo} />
							</NavbarItem>
							<div className={styles.navbarItemSide}></div>
							<MenuMobile open={open} handleClose={setOpen} />
						</Stack>
					</div>
				</Hidden>
			</div>
			{/* <Hidden smDown> */}
			<Stack
				direction="column"
				justifyContent="center"
				className={classNames(styles.containerSm, {
					[styles.bottomSectionLoaded]: isLoaded && !error.fetchError,
				})}
			>
				<Stack direction="row" p={3} pt="60px" width="100%" className={styles.containerDetailCarSm}>
					<div className={styles.containLeft}>
						<div className={styles.brand}>{data?.brand}</div>
						<div className={styles.model}>รุ่น {data?.model}</div>
					</div>
					<div className={styles.containRight}>
						<div className={styles.recommendedRetailPriceText}>ราคาขายปลีกที่แนะนำ</div>
						<div className={styles.recommendedRetailPriceNumberBox}>
							<div className={styles.recommendedRetailPriceNumber}>{data?.recomend_retail_price_new}</div>
							<span>บาท</span>
						</div>

						<div className={styles.approveDate}>
							อนุมัติเมื่อ{' '}
							{data?.approve_date ? moment(data.approve_date).format('DD/MM/yyyy') : 'ไม่พบข้อมูล'}
						</div>
					</div>
				</Stack>
				{/* {data?.remark_price
					? data?.remark_price.replace(/\s/g, '') && (
							<div className={styles.remarkBlock}>
								<b className={styles.remarkTitle}>***หมายเหตุ&nbsp;: &nbsp;</b>
								{data?.remark_price}
							</div>
					  )
					: ''} */}
			</Stack>
			{/* </Hidden> */}
		</>
	);
};

export default Header;
