import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import ModalFilter from './ModalFilter';
import styles from "./SortFilter.module.scss";

const PRICE_SORT = [
    {
        text: 'น้อยไปมาก',
        value: 1
    },
    {
        text: 'มากไปน้อย',
        value: 2
    }
]

const IMPORT_SORT = [
    {
        text: 'เก่าไปล่าสุด',
        value: 1
    },
    {
        text: 'ล่าสุดไปเก่า',
        value: 2
    }
]

const SortFilter = ({ open, sorted, onSelect, onCloseSort }) => {
    const modalRef = useRef(null)
    const [sort, setSort] = useState({})

    const handleUpdateSort = () => {
        onSelect({ ...sort })
        onCloseSort(false)
    }

    const handleClearAllSort = () => {
        onSelect({})
        onCloseSort(false)
    }

    const handleSelectSort = ({ key, value }) => {
        if (value == sort[key]) {
            let temp = { ...sort }
            delete temp[key]
            setSort({ ...temp })
        } else {
            setSort({ ...sort, [key]: value })
        }
    }

    useEffect(() => {
        let elem = document.getElementsByTagName('body')
        modalRef?.current?.scrollTo(0, 0)
        if (open) {
            elem[0].classList.add("on-modale-open")
            setSort(sorted)
        } else {
            elem[0].classList.remove("on-modale-open")
        }

    }, [open])

    return (
        <ModalFilter refs={modalRef} open={open} title='เรียงลำดับการแสดงรถยนต์' onCloseModal={onCloseSort}>
            <div>
                <div className={styles.sortItem}>
                    <div className={styles.sortItemHeader}>
                        <div className={styles.sortItemHeaderLine} />
                        <span className={styles.sortItemHeaderText}>ราคา</span>
                    </div>
                    <div className={styles.sortOption}>
                        {
                            PRICE_SORT.map((item, key) => {
                                return (
                                    <div
                                        key={key}
                                        className={classNames(styles.sortOptionItem, { [styles.sortOptionSelected]: sort?.['recomend_retail_price'] === item.value })}
                                        onClick={() => handleSelectSort({ key: 'recomend_retail_price', value: item.value })}
                                    >

                                        {
                                            sort?.['recomend_retail_price'] === item.value ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-regular fa-circle"></i>
                                        }
                                        {item.text}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div className={styles.sortItem}>
                    <div className={styles.sortItemHeader}>
                        <div className={styles.sortItemHeaderLine} />
                        <span className={styles.sortItemHeaderText}>วันที่นำเข้า</span>
                    </div>
                    <div className={styles.sortOption}>
                        {
                            IMPORT_SORT.map((item, key) => {
                                return (
                                    <div
                                        key={key}
                                        className={classNames(styles.sortOptionItem, { [styles.sortOptionSelected]: sort?.['create_date'] === item.value })}
                                        onClick={() => handleSelectSort({ key: 'create_date', value: item.value })}
                                    >

                                        {
                                            sort?.['create_date'] === item.value ? <i className="fa-solid fa-circle-check"></i> : <i className="fa-regular fa-circle"></i>
                                        }
                                        {item.text}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <div className={styles.sortFooter}>
                <button className={styles.sortFooterBtn} onClick={() => handleUpdateSort()}>
                    ค้นหา
                </button>
                <span
                    className={classNames(styles.clearAllSort, { [styles.disabled]: Object.keys(sorted).length == 0 })}
                    onClick={() => handleClearAllSort()}
                >ล้างการเรียงลำดับทั้งหมด</span>
            </div>
        </ModalFilter>
    );
};

export default SortFilter;