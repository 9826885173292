import React, { useEffect, useState } from 'react';
import { Page } from '../components/GobalElement';
import HeaderPage from '../components/HeaderPage';
import HeaderTopic from '../components/HeaderTopic';
import SearchInput from '../components/SearchInput';
import styles from './StickerPage.module.scss';
import { ClickAwayListener, Collapse, Container } from '@mui/material';
import { styled } from '@mui/styles';

import Bg from '../assets/header_bg_2.png';
import Car1 from '../assets/car_draw.png';
import Qrcode from '../assets/qr_code.png';
import classNames from 'classnames';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useUrlHelper } from '../hooks/useUrlHelper';

const Car = styled('div')({
	backgroundImage: `url(${Car1})`,
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '100%',
	maxWidth: 850,
	height: 350,
	'@media screen and (max-width: 900px)': {
		maxWidth: '100%',
		backgroundSize: 'contain',
	},
	'@media screen and (max-width: 700px)': {
		height: 250,
	},
	'@media screen and (max-width: 550px)': {
		height: 200,
	},
	'@media screen and (max-width: 450px)': {
		height: 170,
	},
});

const QrcodeBox = styled('div')({
	backgroundImage: `url(${Qrcode})`,
	backgroundPosition: 'center',
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: 130,
	height: 130,
	'@media screen and (max-width: 750px)': {
		width: 100,
		height: 100,
	},
	'@media screen and (max-width: 550px)': {
		width: 80,
		height: 80,
	},
});

export const SelectType = ({ selected, onChange, option }) => {
	const [selectedVal, setSelectedVal] = useState({});
	const [open, setOpen] = useState(false);

	const handleOnOpenCollape = () => {
		setOpen(!open);
	};

	const handleSelect = (target) => {
		onChange(target);
	};

	useEffect(() => {
		if (typeof selected == 'string') {
			setSelectedVal(option.find((item) => item.val === selected));
		} else {
			setSelectedVal(selected);
		}
	}, []);

	return (
		<ClickAwayListener onClickAway={() => open && setOpen(false)}>
			<div className={styles.selectContainer}>
				<Collapse
					className={classNames(styles.selectOptionContainer, { [styles.selectOpened]: open })}
					in={open}
					timeout={0}
				>
					<div className={styles.selectOptionList}>
						{option?.length > 0 ? (
							<>
								{option.map((item, key) => (
									<div
										key={key}
										onClick={() => handleSelect(item)}
										className={classNames(styles.selectOptionItem, {
											[styles.selected]: item.val == selectedVal.val,
										})}
									>
										{item.text}
									</div>
								))}
							</>
						) : (
							<div className={styles.selectOptionItemNone}>ไม่มีตัวเลือก</div>
						)}
					</div>
				</Collapse>
				<div
					className={classNames(styles.selectFilter, { [styles.selectOpened]: open })}
					onClick={handleOnOpenCollape}
				>
					<span className={styles.selectSelected}>{selectedVal.text}</span>
					<div className={styles.selectBtnSection}>
						{open ? <i className="fa-solid fa-angle-up"></i> : <i className="fa-solid fa-angle-down"></i>}
					</div>
				</div>
			</div>
		</ClickAwayListener>
	);
};

const StickerPage = () => {
	const [type, setType] = useState({
		text: 'หมายเลขตัวถังประจำรถ (VIN)',
		val: 'vinno',
	});

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const select_field = searchParams.get('type');
	const id = searchParams.get('id');

	useEffect(() => {
		if (select_field && id) {
			navigate(`/sticker-search?type=${select_field}&id=${id}`);
		}
	}, []);

	const handleSearch = (text) => {
		navigate(`/sticker-search?type=${type.val}&id=${text}`);
	};

	return (
		<Page title="สติ๊กเกอร์">
			<HeaderPage classmName={styles.headOverride} bg={Bg}>
				<div className={styles.headContainer}>
					<SearchInput
						onSearch={handleSearch}
						placeholder={type.text}
						onBlurSubmit={false}
						frontComp={
							<SelectType
								selected={type}
								onChange={setType}
								option={[
									{
										text: 'หมายเลขตัวถังประจำรถ (VIN)',
										val: 'vinno',
									},
									{
										text: 'ไอดี ( ID )',
										val: 'eco_sticker_id',
									},
								]}
							/>
						}
						mask={
							type.val === 'eco_sticker_id'
								? {
										len: 6,
										maskText: '000000',
								  }
								: ''
						}
					/>
				</div>
			</HeaderPage>
			<HeaderTopic topic="ป้าย Ecosticker" />
			<div className={styles.pageContent}>
				<Container>
					<div className={styles.pageContainer}>
						<div className={styles.headerText}>ECOSTICKER</div>
						<div className={styles.headerLine}></div>
						<span className={styles.headerLabel}>หมายเลขตัวถังประจำรถ (VIN) / ไอดี ( ID )</span>
						<Car />
						<QrcodeBox />
						<span className={styles.contentDetail}>
							หมายเลขทะเบียน (ECO Sticker ID) และ QR Code บนป้ายข้อมูลรถยนต์ตามมาตรฐานสากล (ECO Sticker)
							จะมีความเฉพาะสำหรับรถยนต์แต่ละรุ่น โดยกระทรวงอุตสาหกรรมจะออก ID/QR Code ให้กับ ECO Sticker
							เมื่อผู้ผลิตหรือผู้นำเข้าได้ยื่นข้อมูลและผลการทดสอบสมรรถนะจริงของรถยนต์รุ่นดังกล่าวอย่างครบถ้วน
						</span>
						<span className={styles.contentRemark}>
							<b>***หมายเหตุ</b> : หากผู้ซื้อรถยนต์พบว่า คุณสมบัติของรถยนต์คันที่ซื้อ หรือ VIN
							บนรถยนต์นั้นไม่ตรงกับข้อมูลบน ECO Sticker ก็สามารถแจ้งตำแหน่งได้
						</span>
					</div>
				</Container>
			</div>
		</Page>
	);
};

export default StickerPage;
