import axios from "axios";

const CancelToken = axios.CancelToken;
export let request = [];

const handleAxios = (param) => {
    const request_id = request?.length ?? 0
    return new Promise(async (resolve, reject) => {
        axios({
            ...param,
            headers: {
                'Content-Type': 'application/json',
                ...param?.headers
            },
            cancelToken: new CancelToken(function executor(c) {
                request.push(c)
            }),
        }).then((response) => {
            request = request.splice(request_id + 1, 1)
            resolve(response)
        }).catch((e) => {
            if (axios.isCancel(e)) {
                console.log('Request canceled:', e.message);
            }
            request = request.splice(request_id + 1, 1)
            reject(e)
        })
    })
}

export default handleAxios