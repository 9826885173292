const regexDetectComma = new RegExp(/\B(?=(\d{3})+(?!\d))/, 'g')
const regexDetectZerosWast = new RegExp(/\.?0+$/, 'i')

export const numberWithCommas = (num, digit = 2) => {
    if (!num || typeof parseFloat(num) != 'number') return '-';
    return parseFloat(num).toFixed(digit).toString().replace(regexDetectComma, ",");
}

export const numberWithCommaOnly = (num) => {
    if (!num || typeof parseFloat(num) != 'number' || num == '-') return '-';
    let temp = parseFloat(num).toFixed(10).toString().replace(regexDetectZerosWast, '')
    return temp.replace(regexDetectComma, ",");
}


export const numberWithCommaOnlyCO2 = (num) => {
    if (!num || typeof parseFloat(num) != 'number' || num == '-') return '0';
    let temp = parseFloat(num).toFixed(10).toString().replace(regexDetectZerosWast, '')
    return temp.replace(regexDetectComma, ",");
}