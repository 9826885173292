import React from 'react'
import styles from "./CarListLoading.module.scss";
import Img from '../../assets/car_loandig.png'

const CarListLoading = () => {
    return (
        <div className={styles.loadingContainer}>
            <img src={Img} className={styles.loadingContent} />
            <div className={styles.loadingDot}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <span className={styles.loadingText}>กำลังโหลดข้อมูล</span>
        </div>
    )
}

export default CarListLoading