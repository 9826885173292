import { Icon } from '@iconify/react';
import { Card, CardActions, Collapse, IconButton, Paper, styled, Typography } from '@mui/material';
import { useSnackbar, SnackbarContent } from 'notistack';
import React, { forwardRef, useCallback, useState } from 'react';

const IconButtonNoti = styled(IconButton)(() => ({
    color: '#ccc',
    fontSize: '0.95rem'
}));

const PaperNoti = styled(Paper)(() => ({
    padding: '0 15px 15px'
}));

const DetailPanal = styled('div')(() => ({
    padding: "5px 10px",
    borderRadius: 5
}));

const StateIconContainer = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    paddingRight: 5
}));

const iconMap = {
    success: {
        icon: "mdi:checkbox-marked-circle-outline",
        color: "#43a047",
        bg: "#43a0471a"
    },
    error: {
        icon: "mdi:close-circle-outline",
        color: "#d32f2f",
        bg: "#d32f2f1a"
    },
    warning: {
        icon: "mdi:alert-outline",
        color: "#ff9800",
        bg: "#ff98001a"
    },
    info: {
        icon: "mdi:information-outline",
        color: "#2196f3",
        bg: "#2196f31a"
    },
    default: {
        bg: "#fff"
    }
}

const StateIcon = ({ variant }) => {
    const state = iconMap[variant]
    return <StateIconContainer sx={{ color: state.color }}><Icon icon={state.icon} /></StateIconContainer>
}

export const ErrorSnackBarContent = ({ detail }) => (
	<span style={{ fontSize: 14 }}>
		{detail || 'ระบบขัดข้อง กรุณาติดต่อเจ้าหน้าที่'}
	</span>
);

const SnackbarContents = forwardRef(({ id, option }, ref) => {
    const classes = {};
    const { closeSnackbar } = useSnackbar();
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = useCallback(() => {
        setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const handleDismiss = useCallback(() => {
        closeSnackbar(id);
    }, [id, closeSnackbar]);

    const state = iconMap[option.variant] ?? iconMap['default']

    return (
        <SnackbarContent ref={ref} style={{ maxWidth: 320 }}>
            <Card sx={{ width: '100%' }}>
                <CardActions sx={{ paddingLeft: 2 }}>
                    {
                        option?.variant && (
                            <StateIcon variant={option.variant} />
                        )
                    }
                    <Typography variant="subtitle2">{option.text}</Typography>
                    <div style={{ marginLeft: 'auto' }}>
                        {
                            option?.content && (
                                <IconButtonNoti
                                    aria-label="Show more"
                                    onClick={handleExpandClick}
                                >
                                    <Icon icon="ic:round-keyboard-arrow-down" />
                                </IconButtonNoti>
                            )
                        }
                        <IconButtonNoti onClick={handleDismiss}>
                            <Icon icon="ic:sharp-close" />
                        </IconButtonNoti>
                    </div>
                </CardActions>
                {
                    option?.content && (
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <PaperNoti>
                                <DetailPanal sx={{ backgroundColor: state.bg }}>
                                    {option.content}
                                </DetailPanal>
                            </PaperNoti>
                        </Collapse>
                    )
                }
            </Card>
        </SnackbarContent>
    );
});

export default SnackbarContents;