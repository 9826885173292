import { styled } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import styles from "./HeaderPage.module.scss";
import DefaultBg from '../assets/head_img.png';

const HeaderPage = ({ children, classmName, bg }) => {
    const HeaderContainer = styled('div')({
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        backgroundImage: bg ? `url(${bg})` : `url(${DefaultBg})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    });

    return (
        <HeaderContainer className={classNames(styles.headerContainer, {
            [classmName]: classmName
        })}>
            {children}
        </HeaderContainer>
    );
};

export default HeaderPage;