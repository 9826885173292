import { Container } from '@mui/material';
import React from 'react';
import { Page } from '../../components/GobalElement';
import styles from "./Articles.module.scss";
import YouTube from 'react-youtube'
import pic25 from '../../assets/acticle/pic-25.png'
import pic26 from '../../assets/acticle/pic-26.png'
import pic27 from '../../assets/acticle/pic-27.png'
import pic28 from '../../assets/acticle/pic-28.png'
import pic29 from '../../assets/acticle/pic-29.png'

const WhatIsInternationalStandard = () => {
    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 0,
        },
    };
    return (
        <Page title='มาตรฐานสากลใน Ecosticker มีอะไรบ้าง'>
            <div className={styles.pageContent}>
                <Container>
                    <div className={styles.pageContainer}>
                        <span className={styles.pageTopic}>มาตรฐานสากลใน Ecosticker มีอะไรบ้าง</span>
                        <span className={styles.pageTopicSecondary}>UN R83</span>
                        <span className={styles.pageSpan}>มาตรฐานสากล</span>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                <span className={styles.textGreen}>ข้อกำหนดทางเทคนิค UN R83</span> คือ การทดสอบมาตรฐานมลพิษของรถยนต์สำหรับรถยนต์นั่ง รถยนต์บรรทุก และรถยนต์นั่งที่ดัดแปลงมาจากรถยนต์บรรทุก ซึ่งทาง<span className={styles.textGreen}>สหประชาชาติ (UN)</span> ได้มีการพัฒนาและปรับปรุงข้อกำหนดมาตรฐานมลพิษให้ดีขึ้นอย่างต่อเนื่อง (ล่าสุด เป็นระดับ <span className={styles.textGreen}>Euro 6</span> (ดีที่สุด)) โดยในส่วนของประเทศไทยนั้น สำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรมได้กำหนดให้รถยนต์ทุกคันจะต้องผ่านการทดสอบมาตรฐานมลพิษตาม มอก. (มาตรฐานบังคับ) ซึ่งเทียบได้กับมาตรฐาน Euro 4 ของสหประชาชาติ (UN)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>การทดสอบมาตรฐานมลพิษของรถยนต์เครื่องยนต์เบนซิน (แก๊สโซลีน) และรถยนต์เครื่องยนต์เบนซิน ตาม UN R83 จะมีความแตกต่างกัน โดยในส่วนของรถยนต์ที่ใช้เครื่องยนต์เบนซิน (แก๊สโซลีน) นั้น จะประกอบด้วยการทดสอบ 7 ลักษณะ ดังนี้</span>
                            <span className={styles.paraghapBreak} />
                            <span>ลักษณะที่ 1 : ปริมาณสารมลพิษภายหลังการติดเครื่องขณะเย็น</span>
                            <span className={styles.paraghapBreak} />
                            <span>ลักษณะที่ 2 : ปริมาณคาร์บอนมอนอกไซด์ในขณะเครื่องยนต์เดินเบา</span>
                            <span className={styles.paraghapBreak} />
                            <span>ลักษณะที่ 3 : ปริมาณสารมลพิษจากห้องข้อเหวี่ยง</span>
                            <span className={styles.paraghapBreak} />
                            <span>ลักษณะที่ 4 : ปริมาณสารมลพิษไอระเหย มีค่ามาตรฐานของการทดสอบรับรองเฉพาะแบบการ</span>
                            <span className={styles.paraghapBreak} />
                            <span>ลักษณะที่ 5 : ความทนทานของอุปกรณ์ควบคุมสารมลพิษ</span>
                            <span className={styles.paraghapBreak} />
                            <span>ลักษณะที่ 6 : การทดสอบปริมาณสารมลพิษจากรถยนต์ที่อุณหภูมิต่ำ (-7 °C)</span>
                            <span className={styles.paraghapBreak} />
                            <span>ลักษณะที่ 7 : การทดสอบระบบวินิจฉัยอุปกรณ์ควบคุมสารมลพิษ</span>
                            <span className={styles.paraghapBreak} />
                        </div>
                        <div className={styles.videoSection}>
                            <YouTube videoId="UEfxVsoxKwc" opts={opts} containerClassName={styles.videoContent} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <span className={styles.pageTopicSecondary}>UN R101</span>
                        <span className={styles.pageSpan}>มาตรฐานสากล</span>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                <span className={styles.textGreen}>ข้อกำหนดทางเทคนิค UN R101</span> คือ การทดสอบหาอัตราใช้น้ำมันเชื้อเพลิง โดยใช้ Gas Analyzer ซึ่งเป็น Flow Meter ที่มีความเที่ยงตรงที่สุด โดยการทดสอบจะกระทำในห้องปฏิบัติการที่ได้ควบคุจะกระทำโดยการนำรถยนต์ทดสอบ มาวิ่งบนแชสซีส์ไดนาโมมิเตอร์ในห้องปฏิบัติการ ณ ระดับความเร็วต่างๆ ตามช่วงเวลาที่กำหนดในรูป
                            </span>
                        </div>
                        <div className={styles.imgSection}>
                            <img src={pic25} className={styles.imgTop} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                การทดสอบแบ่งออกเป็น 2 ช่วง โดยช่วงแรกจะเป็นการจำลองการขับรถยนต์ตามสภาวะในเมือง (Urban Condition) จำนวน 4 วัฎจักร รวมระยะเวลา 780 วินาที ส่วนช่วงที่สอง เป็นการจำลองการขับรถยนต์ตามสภาวะนอกเมือง (Extra-Urban Condition) จำนวน 1 วัฎจักร รวมระยะเวลา 400 วินาที รวมเวลาที่ใช้ทั้งสิ้นเป็น 1,180 วินาที โดยความเร็วจริงของการขับเคลื่อนสามารถจะคลาดเคลื่อนจากความเร็วที่กำหนดไม่เกิน ±2 กิโลเมตรต่อชั่วโมง ในระหว่างการขับเคลื่อนรถยนต์ทดสอบ จะมีการเก็บตัวอย่างการปล่อยไอเสียจากรถยนต์ทดสอบซึ่งประกอบด้วย ไฮโดรคาร์บอน (HC), ก๊าซคาร์บอนมอนอกไซด์ (CO) และก๊าซคาร์บอนไดออกไซด์ (CO2) เพื่อนำปริมาณการปล่อยไอเสียจากรถยนต์ และความหนาแน่นของเชื้อเพลิงทดสอบ (Reference Fuel) มาใช้ คำนวณหาอัตราการใช้เชื้อเพลิงในแต่ละสภาวะต่อไป
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                เมื่อคำนวณหาอัตราใช้น้ำมันเชื้อเพลิงของสภาวะในเมืองและนอกเมืองได้แล้ว ก็จะนำ ค่าอัตราการใช้เชื้อเพลิงทั้งสองสภาวะ พร้อมกับระยะทางเทียบเท่าของแต่ละสภาวะ ไปคำนวณอัตราใช้น้ำมันเชื้อเพลิงหรือปริมาณก๊าซคาร์บอนไดออกไซด์รวม (Combined Condition) ต่อไป อัตราการใช้น้ำมันอ้างอิงที่ได้จากการทดสอบตามมาตรฐาน UN R101 มีลักษณะสำคัญดังนี้
                            </span>
                            <span className={styles.paraghapBreak} />
                            <ul className={styles.paraghapListCircle}>
                                <li>เป็นการทดสอบรถยนต์ภายในห้องปฏิบัติการที่ได้รับการรับรองมาตรฐาน ISO 17025</li>
                                <li>ใช้น้ำมันเชื้อเพลิงอ้างอิงในการทดสอบ (Reference Fuel)</li>
                                <li>มีวิศวกรของสำนักงานมาตรฐานผลิตภัณฑ์อุตสาหกรรม และ/หรือ หน่วยงานความคุมทางเทคนิค (Technical Service) ที่ได้รับการรับรองจากประเทศสมาชิก UN WP29 กำกับดูแลตลอดการทดสอบ</li>
                                <li>ได้รับการตรวจรับรองผลการทดสอบจาก สมอ. และ/หรือ ประเทศสมาชิก UN และมี Emark รับรองผล</li>
                            </ul>
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.videoSection}>
                            <YouTube videoId="aGdTaDSHB58" opts={opts} containerClassName={styles.videoContent} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <span className={styles.pageTopicSecondary}>UN R13H</span>
                        <span className={styles.pageSpan}>มาตรฐานสากล</span>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                <span className={styles.textGreen}>ข้อกำหนดทางเทคนิค UN R13H</span> (The approval of passenger cars with regard to braking) ประกอบด้วยการทดสอบ 7 ลักษณะ ดังนี้
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;1) การทดสอบสมรรถนะห้ามล้อ ขณะระบบเบรกเย็น (Type O) เป็นการทดสอบสมรรถนะของระบบเบรกในขณะที่รถยนต์วิ่งด้วยความเร็ว 100 กิโลเมตรต่อชั่วโมง จนกระทั่งหยุดนิ่ง เพื่อวัดค่าความหน่วงและระยะทางในการหยุดรถ ในแต่ละสภาวะ ดังนี้
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.1 สภาวะปกติที่ระบบเบรกทำงานอย่างสมบูรณ์ ประกอบด้วย การทดสอบรถยนต์ที่ 2 ระดับความเร็ว ได้แก่ ระดับความเร็วปกติ คือ 100 กิโลเมตรต่อชั่วโมง และระดับความเร็วสูง (ร้อยละ 80 ของความเร็วสูงสุด แต่ไม่เกิน 160 กิโลเมตรต่อชั่วโมง)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.2 สภาวะระบบเบรกวงจรซ้ายหรือขวา ไม่ทำงาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.3 สภาวะหม้อลมช่วยเบรกไม่ทำงาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.4 สภาวะระบบห้ามล้อแบบป้องกันการล็อก (ABS : Antilock Braking System) ไม่ทำงาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;1.5 สภาวะระบบห้ามล้อแบบป้องกันการล็อก (ABS : Antilock Braking System)/ ระบบกระจายแรงเบรก (EBD : Electronic Brake Force Distribution) ไม่ทำงาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                ทั้งนี้ การทดสอบข้างต้น จะกระทำทั้งกรณีมวลบรรทุกสูงสุด (Laden) และมวลรถเปล่า (Unladen) รวมทั้ง การทดสอบในกรณีเครื่องยนต์ดับ
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;2) การทดสอบสมรรถนะห้ามล้อ ขณะระบบเบรกร้อน (Type I) เป็นการทดสอบสมรรถนะของระบบเบรกในสภาวะที่มีความร้อนสะสม ทั้งในกรณีความร้อนสะสมสูงและความร้อนสะสมไม่สูง โดยการจำลองสถานการณ์การเบรก จำนวนหลายครั้งอย่างต่อเนื่อง เพื่อทาให้เบรกร้อนขึ้น หลังจากนั้น จึงทดสอบการทำงานของระบบเบรกในขณะที่รถยนต์วิ่งด้วยความเร็ว 100 กิโลเมตรต่อชั่วโมง จนกระทั่งหยุดนิ่ง เพื่อวัดค่าความหน่วงแล้วนำไปเปรียบเทียบกับเกณฑ์มาตรฐาน
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;3) การทดสอบเบรกมือ เป็นการทดสอบความสามารถในการจอดรถบนพื้นเอียง ที่มีระดับความชันร้อยละ 20 (กรณีมวลบรรทุกสูงสุด) และระดับความชันร้อยละ 12 (กรณีมวลบรรทุกสูงสุด+Trailer) เพื่อวัดค่าแรงที่ใช้ในการเบรกจากมือและเท้า รวมทั้งความสามารถในการหยุดรถ ที่ระดับความเร็ว 30 กิโลเมตรต่อชั่วโมง โดยใช้เบรกมือ
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;4) การทดสอบระบบช่วยเหลือการเบรก (BAS : Brake Assist System) เป็นการทดสอบสมรรถนะของระบบช่วยเหลือการเบรก (ระบบควบคุมแรงเบรก)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;5) การทดสอบระบบห้ามล้อแบบป้องกันการล็อก (ABS : Antilock Braking System-ABS) เป็นการทดสอบสมรรถนะของระบบ ABS ซึ่งโดยหลักการแล้ว ประสิทธิภาพในการเบรกเมื่อ ระบบ ABS ทำงาน (ด้วยการจับ-ปล่อยเบรกเป็นบางช่วง) จะต้องไม่ส่งผลให้ความสามารถในการเบรกลดต่ากว่าค่าที่กำหนด (ไม่ต่ากว่าร้อยละ 75 ของแรงเบรกสูงสุด) ทั้งนี้ จะมีการทดสอบสมรรถนะของ ABS ในการหยุดรถบนพื้นผิวที่แตกต่างกัน 5 ลักษณะ ดังนี้
                            </span>
                            <span className={styles.paraghapBreak} /><span>
                                &emsp;&emsp;5.1 การหยุดรถบนพื้นผิวที่มีแรงเสียดทานสูง (Hi-μ)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;5.2 การหยุดรถบนพื้นผิวที่มีแรงเสียดทานต่า (Low-μ)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;5.3 การหยุดรถจากพื้นผิวที่มีแรงเสียดทานสูงไปยังพื้นผิวที่มีแรงเสียดทานต่า
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;5.4 การหยุดรถจากพื้นผิวที่มีแรงเสียดทานต่าไปยังพื้นผิวที่มีแรงเสียดทานสูง
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;&emsp;5.5 การหยุดรถในกรณี ล้อของรถด้านหนึ่งอยู่บนพื้นผิวที่มีแรงเสียดทานสูง และล้อของรถอีกด้านหนึ่งอยู่บนพื้นผิวที่มีแรงเสียดทานต่า
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                นอกจากนี้แล้ว ยังมีการทดสอบการทำงานของระบบไฟเตือน และสภาพการวิ่งของรถยนต์ในขณะเบรกในกรณีที่ระบบ ABS ไม่ทำงาน รวมทั้ง การทดสอบการทำงานของระบบ ABS ภายใต้การรบกวนของคลื่นแม่เหล็กไฟฟ้า (Electromagnetic Compatibility) อีกด้วย
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;6) การทดสอบสัญญาณไฟฉุกเฉิน (Emergency Stop Signal)เป็นการทดสอบการให้สัญญาณไฟฉุกเฉินซึ่งจะต้องทำงาน หากมีการเบรกอย่างแรง (กรณีฉุกเฉิน)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &ensp;&ensp;7) การทดสอบระบบควบคุมเสถียรภาพแบบอิเล็กทรอนิกส์ (Electronic Stability Control System-ESC System) เป็นการทดสอบสมรรถนะของระบบ ESC โดยการสร้างสถานการณ์การหลบหลีกกะทันหันในขณะที่รถยนต์วิ่งด้วยความเร็ว 80 กิโลเมตรต่อชั่วโมง ด้วยการใช้ระบบอัตโนมัติควบคุมการหักเลี้ยวรถยนต์ไปกลับอย่างกะทันหัน (องศาการเลี้ยวสูงสุดที่ 270 องศา) ทั้งนี้ รถยนต์ที่ผ่านมาตรฐานจะต้องไม่เสียหลักและยังคงรักษาทิศทางการเคลื่อนที่ได้อย่างสมบูรณ์ หรือ มีการเบี่ยงเบนได้ไม่เกินค่าที่กำหนด
                            </span>
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.videoSection}>
                            <YouTube videoId="j8aXQCsHUzw" opts={opts} containerClassName={styles.videoContent} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <span className={styles.pageTopicSecondary}>UN R94 + R95</span>
                        <span className={styles.pageSpan}>มาตรฐานสากล</span>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                <span className={styles.textGreen}>ข้อกำหนดทางเทคนิค UN R94</span> (Protection of the occupants in the event of a frontal collision) คือ การทดสอบเพื่อพิสูจน์ความปลอดภัยของยานยนต์ในเรื่องการปกป้องผู้ขับขี่ และผู้โดยสารจากการชนด้านหน้า
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                ข้อกำหนดทางเทคนิค UN R94 จะเป็นการทดสอบโดยรถยนต์ทดสอบ ซึ่งมีหุ่นจำลอง (Dummy)ของผู้ขับขี่และผู้โดยสาร เคลื่อนที่โดยใช้เครื่องมือฉุดลาก ด้วยความเร็วระหว่าง 56–57 กิโลเมตรต่อชั่วโมง พุ่งเข้าชนแบบจำลองหัวรถยนต์ที่สามารถยุบตัวได้แบบเยื้องศูนย์ด้านหน้า 40% ของรถยนต์ (พื้นที่ปะทะด้านคนขับคิดเป็นร้อยละ 40 ของความกว้างรถยนต์)
                            </span>

                        </div>
                        <div className={styles.imgSection}>
                            <img src={pic26} className={styles.imgTopSecondary} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>ภายหลังการชน จะมีการตรวจสอบดังนี้</span>
                            <ul>
                                <li>สภาพของหุ่นจำลอง (ผู้ขับขี่ และผู้โดยสาร) ได้แก่ การบาดเจ็บที่หัว ,การบาดเจ็บที่คอ, โมเมนต์ดัดที่คอ, การรับแรงที่หน้าอก, Viscous Criterion, แรงกดที่หน้าแข้ง, การเคลื่อนตัวของข้อต่อหัวเข่า และการรับแรงที่ขา ดังนี้</li>
                            </ul>
                        </div>
                        <div className={styles.imgSection}>
                            <img src={pic27} className={styles.imgTopSecondary} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <ul>
                                <li>การรั่วของน้ำมันเชื้อเพลิง (Fuel Leakage) และระบบป้อนน้ำมันเชื้อเพลิงสามารถเกิดขึ้นได้ แต่ต้องไม่เกินกว่า 30 กรัมต่อนาที</li>
                                <li>การเคลื่อนที่ของพวงมาลัย (Steering Movement) ต้องไม่สูงเกินกว่า 80 มิลลิเมตรในแนวดิ่ง และต้องไม่เคลื่อนตัวไปในข้างหลังในแนวระดับ 100 มิลลิเมตร)</li>
                                <li>ต้องเปิดประตูได้อย่างน้อยหนึ่งประตูและสามารถนาหุ่นจำลองออกจากรถทดสอบได้</li>
                                <li>ต้องปลดล๊อกระบบ Restraint โดยใช้แรงไม่เกิน 60 นิวตัน</li>
                            </ul>
                        </div>
                        <div className={styles.paraghapBox}>
                            <span>
                                <span className={styles.textGreen}>ข้อกำหนดทางเทคนิค UN R95</span> (Protection of the Occupants in the event of a Lateral Collision) คือการทดสอบเพื่อพิสูจน์ความปลอดภัยของยานยนต์ในเรื่องการปกป้อง ผู้ขับขี่จากการชนด้านข้าง
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                ข้อกำหนดทางเทคนิค UN R95 จะเป็นการทดสอบโดยนาแบบจำลองหัวรถยนต์ที่สามารถยุบตัวได้ (Deformable Barrier) เคลื่อนที่พุ่งเข้าชนรถยนต์ทดสอบซึ่งจอดอยู่นิ่ง ในแนวตั้งฉากด้านข้าง (ด้านผู้ขับขี่) ด้วยความเร็ว 50 กิโลเมตรต่อชั่วโมง
                            </span>
                        </div>
                        <div className={styles.imgSection}>
                            <img src={pic28} className={styles.imgTopSecondary} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>ภายหลังการชน จะมีการตรวจสอบดังนี้</span>
                            <ul>
                                <li>สภาพของหุ่นจำลอง (ผู้ขับขี่) ได้แก่ การบาดเจ็บที่หัว ,การบาดเจ็บที่หน้าอก, Soft Tissue Criterion, แรงกดหน้าท้อง และแรงกดที่หัวหน่าว ดังนี้</li>
                            </ul>
                        </div>
                        <div className={styles.imgSection}>
                            <img src={pic29} className={styles.imgTop} />
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <ul>
                                <li>การรั่วของน้ำมันเชื้อเพลิง (Fuel Leakage) และระบบป้อนน้ำมันเชื้อเพลิงสามารถเกิดขึ้นได้ แต่ต้องไม่เกินกว่า 30 กรัมต่อนาที</li>
                                <li>ต้องสามารถนาหุ่นจำลองออกจากรถทดสอบได้</li>
                                <li>ต้องสามารถปลดล็อคระบบป้องกัน (Protective System) ได้</li>
                                <li>ไม่มีการเสียรูปของอุปกรณ์ภายในที่ทาให้แหลมคมและมีอัตราเสี่ยงต่อการบาดเจ็บ</li>
                            </ul>
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.videoSection}>
                            <YouTube videoId="Lp52T9kL6Cg" opts={opts} containerClassName={styles.videoContent} />
                        </div>
                        <div className={styles.sectionBreak} />

                        <span className={styles.pageTopicSecondary}>UN R100</span>
                        <span className={styles.pageSpan}>มาตรฐานสากล</span>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>การทดสอบระบบส่งกำลังด้วยไฟฟ้าของรถยนต์ ตามมาตรฐาน <span className={styles.textGreen}>UN R100</span></span>
                            <span>
                                รถยนต์ที่มีระบบส่งกำลังด้วยไฟฟ้า ควรมีการทดสอบมาตรฐานด้านความปลอดภัยเกี่ยวกับระบบส่งกำลังด้วยไฟฟ้าของรถยนต์ ในการป้องกันไฟฟ้าช็อก (Electric Shock) จากการสัมผัสโดยตรง อาทิ การป้องกันตามมาตรฐาน IPXXD ด้วยสายทดสอบ (Test wire) สำหรับชิ้นส่วนที่มีไฟฟ้าภายในห้องโดยสารหรือในห้องเก็บสัมภาระ และการป้องกันตามมาตรฐาน IPXXB ด้วยนิ้วทดสอบ (Test finger) สำหรับชิ้นส่วนที่ไฟฟ้าในบริเวณอื่นนอกเหนือจากภายในห้องโดยสารหรือในห้องเก็บสัมภาระ การป้องกันจากการสัมผัสโดยอ้อม และความต้านทานการแยกกันทางไฟฟ้า
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                ในส่วนการทดสอบมาตรฐานด้านความปลอดภัยทางไฟฟ้าของระบบกักเก็บพลังงานไฟฟ้าที่อัดประจุซ้ำได้ (Rechargeable Energy Storage Systems - REESS) มีดังนี้
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>1. การทดสอบการสั่นสะเทือน (Vibration Test)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบสมรรถนะด้านความปลอดภัยของ REESS ภายใต้สภาพแวดล้อมการสั่นสะเทือน ซึ่งน่าจะเกิดขึ้นกับ REESS ในระหว่างการใช้งานปกติของรถยนต์ เช่น การขับเคลื่อนบนพื้นผิวการจราจร ทดสอบโดยการสั่นสะเทือน REESS ในทิศทางแนวตั้งด้วยคลื่นรูปแบบไซน์ที่มีการกวาดแบบลอการิธึ่มระหว่างความถี่ 7 Hz และ 50 Hz และกวาดกลับไปที่ความถี่ 7 Hz ภายใน 15 นาที ทำซ้ำ 12 ครั้ง ภายในเวลารวม 3 ชั่วโมง
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>2. การทดสอบวัฏจักรและการเปลี่ยนแปลงอุณหภูมิฉับพลัน (Thermal Shock and Cycling Test)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบความทนทานของ REESS ต่อการเปลี่ยนแปลงอุณหภูมิทันทีทันใด การเร่งการเปลี่ยนแปลงอุณหภูมิอย่างรวดเร็วซึ่งน่าจะเกิดขึ้นกับ REESS ระหว่างอายุการใช้งาน ทดสอบโดยเก็บ REESS ไว้ที่อุณหภูมิประมาณ 60 °C หรือสูงกว่าอย่างน้อย 6 ชั่วโมง จากนั้นเปลี่ยนอุณหภูมิจัดเก็บที่ประมาณ – 40 °C หรือต่ำกว่าอย่างน้อย 6 ชั่วโมง โดยช่วงเวลาระหว่างการเปลี่ยนแปลงอุณหภูมิสูงสุดและต่ำสุดต้องไม่เกิน 30 นาที ทำซ้ำอย่างน้อย 5 รอบ
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>3. การเปลี่ยนแปลงความเร่งฉับพลัน (Mechanical Shock)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบสมรรถนะด้านความปลอดภัยของ REESS ภายใต้โหลดแรงเฉื่อย (Inertial Loads) ซึ่งอาจเกิดขึ้นในระหว่างการชนของยานพาหนะ REESS จะถูกเร่งหรือหน่วงให้สอดคล้องกับขอบเขตความเร่งที่กำหนด
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>4. ความสมบูรณ์เชิงกล (Mechanical Integrity)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบสมรรถนะด้านความปลอดภัยของ REESS ภายใต้ภาระสัมผัส (Contact Loads) ซึ่งอาจเกิดขึ้นในระหว่างสถานการณ์การชนของยานพาหนะ ทดสอบโดย REESS จะต้องถูกบดระหว่างตัวต้านทาน (Resistance) และแผ่นบดด้วยแรงอย่างน้อย 100 kN (ประมาณ 10 ตัน) หรือทดสอบแบบพลวัต โดย REESS ที่ติดตั้งในรถยนต์ที่ทำการทดสอบการชนตาม UN R94 สำหรับการชนด้านหน้า และ UN R95 สำหรับการชนด้านข้าง
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>5. การป้องกันการลัดวงจรภายนอก (External short circuit protection)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบสมรรถนะของการป้องกันการลัดวงจร ในการขัดขวางหรือจำกัดกระแสไฟฟ้าลัดวงจรที่อาจเกิดกับ REESS ทดสอบโดยการทำให้เกิดการลัดวงจรต่อเนื่องจนกระทั่งสามารถยืนยันการทำงานของการป้องกันการลัดวงจร
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>6. การป้องกันการอัดประจุไฟฟ้าเกิน (Overcharge protection)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบสมรรถนะของการป้องกันการอัดประจุไฟฟ้าเกิน ทดสอบด้วยอัตราการอัดประจุกระแสไฟฟ้าอย่างน้อย 1/3 C (C Rate) อย่างต่อเนื่องจนกระทั่ง REESS ถูกขัดขวางหรือจำกัดการอัดประจุโดยอัตโนมัติ หรือจนกระทั่ง REESS ได้รับการอัดประจุเป็น 2 เท่าของความจุการอัดประจุพิกัด
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>7. การป้องกันการคายประจุไฟฟ้าเกิน (Over-Discharge Protection)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบสมรรถนะการป้องกันการคายประจุไฟฟ้าเกิน ทดสอบด้วยอัตราการคายประจุกระแสไฟฟ้าอย่างน้อย 1/3 C (C Rate) อย่างต่อเนื่องจนกระทั่ง REESS ถูกขัดขวางหรือจำกัดการคายประจุโดยอัตโนมัติ หรือจนกระทั่ง REESS ได้รับการคายประจุจนถึง 25% ของระดับแรงดันที่ระบุ (Nominal Voltage)
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>8. การป้องกันอุณหภูมิสูงเกิน (Over-Temperature Protection)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบสมรรถนะของมาตรการการป้องกันความร้อนภายใน REESS ที่สูงเกิน (Internal Overheating) ระหว่างการใช้งานรวมทั้งภาวะที่ระบบระบายความร้อน (ถ้ามี) บกพร่อง ทดสอบโดยการอัดประจุและคายประจุไฟฟ้าอย่างต่อเนื่องด้วยกระแสไฟคงที่ ซึ่งจะเพิ่มอุณหภูมิของเซลล์ภายใน REESS อย่างรวดเร็วเท่าที่เป็นไปได้ และวาง REESS ในเตาอบพาความร้อน (Convective Oven) หรือตู้ควบคุมสภาวะ (Climatic Chamber) ซึ่งอุณหภูมิจะค่อย ๆ เพิ่มขึ้นจนกระทั่งถึงอุณหภูมิที่กำหนด การทดสอบจะสิ้นสุดเมื่อ REESS ขัดขวางหรือจำกัดการอัดประจุหรือคายประจุเพื่อป้องกันการเพิ่มขึ้นของอุณหภูมิ หรืออุณหภูมิของ REESS เสถียรตลอดระยะเวลา 2 ชั่วโมง
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>9. การทนไฟ (Fire Resistance)</span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;เพื่อตรวจสอบการทนไฟของ REESS จากภายนอกรถยนต์ เช่น การรั่วไหลของน้ำมันเชื้อเพลิงจากยานพาหนะ เพื่อให้เวลาผู้ขับขี่หรือผู้โดยสารมีเวลาเพียงพอในการอพยพ ทดสอบที่ตัวรถหรือทดสอบเฉพาะชิ้นส่วน โดยเปลวไฟที่ใช้ในการเผาต้องมาจากน้ำมันเชื้อเพลิงสำหรับรถยนต์
                            </span>
                        </div>
                        <div className={styles.sectionBreak} />
                        <div className={styles.paraghapBox}>
                            <span>
                                เกณฑ์การยอมรับของการทดสอบที่ 1 - 8
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                &emsp;1) ระหว่างการทดสอบต้องไม่พบการรั่วไหลของอิเล็กโทรไลต์ (Electrolyte) การติดไฟ (Fire) และการระเบิด (Explosion)
                            </span>
                            <span>
                                &emsp;2) สำหรับ REESS ที่มีแรงดันสูง (High Voltage) มากกว่า 60 V และน้อยกว่า 1,500 V สำหรับไฟฟ้ากระแสตรง หรือ มากกว่า 30 V และน้อยกว่าหรือเท่ากับ 1,000 V สำหรับไฟฟ้ากระแสสลับ จะต้องไม่พบรอยแตกและความต้านทานการแยกกันทางไฟฟ้า (Isolation Resistance) ที่ถูกวัดหลังการทดสอบตามที่กำหนดต้องไม่น้อยกว่า 100 Ω/V
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>
                                เกณฑ์การยอมรับของการทดสอบที่ 9
                            </span>
                            <span className={styles.paraghapBreak} />
                            <span>&emsp;ต้องไม่แสดงร่องรอยของการระเบิดในอุปกรณ์ที่ทำการทดสอบ</span>
                        </div>
                    </div>
                </Container>
            </div>
        </Page>
    );
};

export default WhatIsInternationalStandard;