import { Grid, Stack, styled } from "@mui/material";
import React, { useState } from "react";
import styles from "./ListCar.module.scss";

import CarDefault from "../assets/car_draw.png";
import { numberWithCommas } from "../helper/Number";
import classNames from "classnames";
import useCompareContext from "../contexts/compareContext";
import { Link, useNavigate } from "react-router-dom";
import LazyLoad from "react-lazy-load";

const ListCar = ({ data }) => {
	const [selected, setSelected] = useCompareContext();
	const [loading, setLoading] = useState(true);
	const navigator = useNavigate();

	const CarImg = styled("div")({
		backgroundImage: `url(${CarDefault})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
		height: 160,
		width: "100%",
		position: "absolute",
		opacity: loading ? 1 : 0,
		transitionDuration: "1s",
	});

	const handleOnSelectCar = (item) => {
		let key = selected.findIndex((item) => item.id === data.id) ?? -1;
		if (key >= 0) {
			let temp = selected;
			temp.splice(key, 1);
			setSelected([...temp]);
		} else if (key == -1 && selected.length < 4) {
			setSelected([...selected, item]);
		}
	};

	const handleOnViewDetail = (id) => {
		navigator({
			pathname: `/detail/${id}`,
		});
	};

	return (
		<Grid item xs={1} sm={1} md={1} display="flex" justifyContent="center">
			<div className={styles.listBox}>
				<LazyLoad>
					<div className={styles.listImgBox}>
						<img
							src={data.logo_url ? data.logo_url : CarDefault}
							className={styles.listImg}
							onLoad={() => setLoading(false)}
						/>
						<CarImg />
					</div>
				</LazyLoad>
				<div className={styles.listContent}>
					<span className={styles.listContentBrand}>{data.brand}</span>
					<span className={styles.listContentModel}>{data.model}</span>
					<span className={styles.listContentPrice}>
						ราคา {numberWithCommas(data.recomend_retail_price)} บาท
					</span>
					<span className={styles.listContentPrice}>ผู้ผลิต/ผู้นำเข้า:</span>
					<span className={styles.listContentPrice}>{data.company_name}</span>
					<br />
					<Stack direction="row" spacing={1} marginTop="auto">
						<Link
							to={`/detail/${data.id}`}
							className={classNames(styles.listContentBtn, styles.listBtnDetail)}
							// onClick={() => handleOnViewDetail(data.id)}
						>
							รายละเอียด
						</Link>
						<button
							className={classNames(styles.listContentBtn, styles.listBtnCompare)}
							onClick={() => handleOnSelectCar({ id: data.id, name: data.brand, model: data.model })}
						>
							{selected.find((item) => item.id === data.id) ? (
								<i className="fa-solid fa-circle-check"></i>
							) : (
								<i className="fa-regular fa-circle"></i>
							)}
							เปรียบเทียบ
						</button>
					</Stack>
				</div>
			</div>
		</Grid>
	);
};

export default ListCar;
