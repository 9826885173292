import React, { useEffect, useState, useCallback } from "react";
import { getStickerData, getStickerDataByID } from "../../helper/allAPI";
import ReactDOM from "react-dom";
import { jsPDF } from "jspdf";
import { toPng } from "html-to-image";

import StickerICE from "./templates/StickerICE";
import StickerPHEV from "./templates/StickerPHEV";
import StickerBEV from "./templates/StickerBEV";

// new sticker
import StickerICENew from "./templates/StickerICENew";
import StickerPHEVNew from "./templates/StickerPHEVNew";
import StickerBEVNew from "./templates/StickerBEVNew";

import ecoLogo from "./images/icon-eco-sticker.jpeg";
import iconPlug from "./images/icon-plug.png";
import iconOil from "./images/icon-oil.png";

const NotiContent = ({ detail }) => <span style={{ fontSize: 14 }}>{detail}</span>;

const useSticker = () => {
	const [isGenerating, setIsGenerating] = useState(false);

	useEffect(() => {
		const parentDiv = document.querySelector("div");
		const containerDiv = document.createElement("div");

		containerDiv.setAttribute("id", "container");
		containerDiv.setAttribute("style", "height: 0px;overflow:hidden;font-family:ChatThai;");

		const listDiv = document.createElement("li");
		const ecoLogoElement = document.createElement("img");
		const plugElement = document.createElement("img");
		const oilElement = document.createElement("img");
		ecoLogoElement.src = ecoLogo;
		plugElement.src = iconPlug;
		oilElement.scr = iconOil;

		containerDiv.appendChild(listDiv);
		parentDiv.appendChild(containerDiv);

		return () => {
			containerDiv.remove();
		};
	}, []);

	const memoizedExportAsImage = useCallback(async (elemId) => {
		const use = document.getElementsByName(elemId)[0];
		let image = null;
		if (isSafari()) {
			image = await toPng(use).then(async () => {
				return await toPng(use).then(async () => {
					return await toPng(use);
				});
			});
		} else {
			image = await toPng(use, {
				pixelRatio: 1,
				canvasWidth: 3350,
				canvasHeight:4576,
			});
			// image = await toPng(use);
		}
		return image;
	}, []);

	const renderDOM = useCallback(
		(Element, item, container) => {
			return new Promise((resolve, _) => {
				ReactDOM.render(<Element id={item.id} data={item} />, container, async () => {
					try {
						let approval_at_latest_value = new Date(item?.basic_information?.approval_at_latest);
						var given_date = "2024-01-31";
						given_date = new Date(given_date);
						given_date.setHours(0, 0, 0, 0);
						const res = await memoizedExportAsImage(
							item.id,
							approval_at_latest_value > given_date ||
								item?.basic_information?.approval_at_latest === null
						);
						resolve(res);
					} catch (e) {
						_(e);
					}
				});
			});
		},
		[memoizedExportAsImage]
	);

	const getLogoAsURL = (id, data, container, src, StickerElem) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = src;
			img.setAttribute("crossorigin", "anonymous");

			img.onload = () => {
				const oc = document.createElement("canvas");
				const octx = oc.getContext("2d");
				oc.width = 184;
				oc.height = (184 * img.height) / img.width;

				octx.drawImage(img, 0, 0, oc.width, oc.height);
				const res = oc.toDataURL();

				ReactDOM.render(<StickerElem id={id} data={{ ...data, logo_src: res }} />, container);

				resolve(res);
			};

			img.onerror = () => {
				reject({ message: "cannot load company logo" });
			};
		});
	};

	const getQrAsURL = (src) => {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.src = src;
			img.setAttribute("crossorigin", "anonymous");

			img.onload = () => {
				const oc = document.createElement("canvas");
				const octx = oc.getContext("2d");
				oc.width = 184;
				oc.height = (184 * img.height) / img.width;

				octx.drawImage(img, 0, 0, oc.width, oc.height);
				const res = oc.toDataURL();
				resolve(res);
			};

			img.onerror = () => {
				reject({ message: "cannot load QR Code" });
			};
		});
	};

	const previewSticker = async (value) => {
		setIsGenerating(true);
		const containerDiv = document.getElementById("container");
		let containerPreview = document.getElementById("container_preview");

		if (containerPreview) {
			containerPreview.remove();
		}

		containerPreview = document.createElement("div");
		containerPreview.setAttribute("id", `container_preview`);
		containerDiv.appendChild(containerPreview);

		try {
			let {
				data: { data },
			} = await getStickerData(value);

			let StickerElem = null;
			let approval_at_latest_value = new Date(data?.basic_information?.approval_at_latest);
			var given_date = "2024-01-31";
			given_date = new Date(given_date);
			given_date.setHours(0, 0, 0, 0);
			if (approval_at_latest_value > given_date || data?.basic_information?.approval_at_latest === null) {
				switch (data.type_sticker) {
					case "ICE":
						StickerElem = StickerICENew;
						break;
					case "PHEV":
						StickerElem = StickerPHEVNew;
						break;
					case "BEV":
						StickerElem = StickerBEVNew;
						break;
					default:
				}
			} else {
				switch (data.type_sticker) {
					case "ICE":
						StickerElem = StickerICE;
						break;
					case "PHEV":
						StickerElem = StickerPHEV;
						break;
					case "BEV":
						StickerElem = StickerBEV;
						break;
					default:
				}
			}

			const logo_src = await getLogoAsURL("preview", data, containerPreview, data.path_file.logo, StickerElem);
			const qr_src = await getQrAsURL(data.path_file.qr_code);
			const item = {
				...data,
				logo_src: logo_src,
				qr_src: qr_src,
				id: "preview",
			};

			const result = await renderDOM(StickerElem, item, containerPreview);
			setIsGenerating(false);

			return result;
		} catch (e) {
			setIsGenerating(false);
			console.log(e.message);
		}
	};

	const getSticker = useCallback(
		async (id, showWatermark = false) => {
			let result = "";
			setIsGenerating(true);

			const generateSticker = async (id) => {
				try {
					let {
						data: { data },
					} = await getStickerDataByID(id);

					let StickerElem = null;
					let approval_at_latest_value = new Date(data?.basic_information?.approval_at_latest);
					var given_date = "2024-01-31";
					given_date = new Date(given_date);
					given_date.setHours(0, 0, 0, 0);
					if (approval_at_latest_value > given_date || data?.basic_information?.approval_at_latest === null) {
						console.log(approval_at_latest_value, given_date);
						switch (data.type_sticker) {
							case "ICE":
								StickerElem = StickerICENew;
								break;
							case "PHEV":
								StickerElem = StickerPHEVNew;
								break;
							case "BEV":
								StickerElem = StickerBEVNew;
								break;
							default:
						}
					} else {
						switch (data.type_sticker) {
							case "ICE":
								StickerElem = StickerICE;
								break;
							case "PHEV":
								StickerElem = StickerPHEV;
								break;
							case "BEV":
								StickerElem = StickerBEV;
								break;
							default:
						}
					}

					const childContainer = document.createElement("div");
					childContainer.setAttribute("id", `container_${id}`);
					const containerDiv = document.getElementById("container");
					containerDiv.appendChild(childContainer);

					const logo_src = await getLogoAsURL(id, data, childContainer, data.path_file.logo, StickerElem);
					const qr_src = await getQrAsURL(data.path_file.qr_code);
					const item = {
						...data,
						logo_src: logo_src,
						qr_src: qr_src,
						id: id,
						other_condition: {
							...data.other_condition,
							overlap_text: showWatermark,
						},
					};

					const result = await renderDOM(StickerElem, item, childContainer);
					childContainer.remove();
					setIsGenerating(false);
					return Promise.resolve(result);
				} catch (error) {
					setIsGenerating(false);
					return Promise.reject(error);
				}
			};

			if (typeof id === "string") {
				result = await generateSticker(id);
			} else {
				result = await Promise.all(id.map(async (id) => await generateSticker(id)));
			}
			return result;
		},
		[renderDOM]
	);

	const isSafari = () => {
		let userAgent = navigator.userAgent;
		if (userAgent.indexOf("Safari") !== -1 && userAgent.indexOf("Chrome") === -1) {
			return true;
		} else {
			return false;
		}
	};

	const generatePDF = (image, fileName) => {
		const makeid = () => {
			var text = "sticker_";
			var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

			for (var i = 0; i < 7; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));

			return text;
		};

		const doc = new jsPDF();
		var width = doc.internal.pageSize.getWidth();
		var height = doc.internal.pageSize.getHeight();
		doc.addImage(image, "JPEG", 0, 0, width, height);

		const blob = doc.output("bloburl");
		// const fakeLink = window.document.createElement("a");
		// fakeLink.style = "display:none;";
		// fakeLink.download = fileName ?? makeid();

		// fakeLink.href = blob;

		// document.body.appendChild(fakeLink);
		// fakeLink.click();
		// document.body.removeChild(fakeLink);

		// fakeLink.remove();
		return blob;
	};

	return {
		genPDF: generatePDF,
		previewSticker: previewSticker,
		isGenerating: isGenerating,
		getSticker: getSticker,
	};
};

export default useSticker;
//
