import React, { useEffect, useRef, useState } from 'react';

import useCompareContext from '../contexts/compareContext';

import { Page } from '../components/GobalElement';
import TableCompare from '../components/TableCompare';

import styles from './ComparisonTablePage.module.scss';

// mockup data
import Car1 from '../assets/mockup/car-1-1.png';
import Car2 from '../assets/mockup/car-2-1.png';
import Car3 from '../assets/mockup/car-3-1.png';
import { Link, useNavigate } from 'react-router-dom';
import { numberWithCommaOnly, numberWithCommaOnlyCO2, numberWithCommas } from '../helper/Number';
import moment, { min } from 'moment';
import ScrollContainer from 'react-indiana-drag-scroll';
import { getCompareCarData } from '../helper/allAPI';
import CarListLoading from '../components/loading/CarListLoading';
import { Skeleton, Stack } from '@mui/material';
import { request } from '../helper/Axios';
import { CAR_TYPE_NAME, CAR_TYPE_UNIT } from '../configs/comparisonConfig';
import classNames from 'classnames';

const border_span = {
	padding: '3px 40px',
	borderRadius: 5,
	border: '1px solid green',
	textDecoration: 'underline',
	color: 'green',
};
const star_span = {
	color: 'green',
	textDecoration: 'underline',
};

const CarType = ({ type }) => {
	return (
		<div
			className={classNames(styles.carTypeSpan, {
				[styles.typeICE]: type == 'ICE',
				[styles.typeBEV]: type == 'BEV',
				[styles.typePHEV]: type == 'PHEV',
			})}
		>
			{CAR_TYPE_NAME[type]}
		</div>
	);
};

const CarImage = ({ img, type }) => {
	const [isLoading, setIsLoading] = useState(true);
	const [tempImg, setTempImg] = useState('');
	const [mainImg, setMainImg] = useState('');

	const switchImageTemp = () => {
		if (type == 'PHEV') {
			return Car3;
		} else if (type == 'BEV') {
			return Car2;
		} else {
			return Car1;
		}
	};
	const handleOnError = () => {
		setMainImg(switchImageTemp());
	};

	useEffect(() => {
		setMainImg(img);
		setTempImg(switchImageTemp());
	}, []);

	return (
		<div className={styles.imgBox}>
			<img
				src={tempImg}
				className={classNames(styles.imgTemp, {
					[styles.imgTempLoaded]: !isLoading,
				})}
			/>
			{mainImg && (
				<img
					src={mainImg + '&mode=thumbnail'}
					className={styles.img}
					onError={() => handleOnError()}
					onLoad={() => setIsLoading(false)}
				/>
			)}
		</div>
	);
};

const StickyContent = ({ data }) => {
	const stickyRef = useRef(null);
	const stickyConstentRef = useRef(null);
	const [sticky, setSticky] = useState(false);

	const onScroll = () => {
		const elem = stickyRef?.current;
		const elemBox = stickyConstentRef?.current;
		let offsetHeight = 105;
		if (window.innerWidth < 700) {
			offsetHeight = 65;
		} else if (window.innerWidth < 900) {
			offsetHeight = 85;
		}

		const offsetPage = parseInt(window.scrollY) + offsetHeight;
		const offsetElem =
			parseInt(elem?.offsetTop) + parseInt(elem?.offsetHeight) + parseInt(elem?.offsetParent?.offsetTop);
		if (offsetPage > offsetElem) {
			elemBox.style.top = offsetPage - parseInt(elem?.offsetParent?.offsetTop) + 'px';
			setSticky(true);
		} else {
			setSticky(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', onScroll);

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
	}, []);

	return (
		<div ref={stickyRef} className={styles.stickyBox}>
			<div
				ref={stickyConstentRef}
				className={classNames(styles.stickyConstent, {
					[styles.stickyShow]: sticky,
				})}
			>
				<span className={styles.lableConstent}>{data.brand}</span>
				<span className={styles.lableOverflow}>รุ่น : {data.model}</span>
			</div>
			<span className={styles.lableSecondary}>รุ่น : {data.model}</span>
		</div>
	);
};

const getConditionHighligth = (key, data, min, isStar, isMax) => {
	const min_key = `${isMax ? 'max' : 'min'}_${key}`;
	if (min?.[min_key] == '-' || min?.[min_key] == '') {
		return {};
	}
	if (data?.[key] == min?.[min_key]) {
		return isStar ? star_span : border_span;
	}
	return {};
};

const TABLE_TOPIC_LIST = [
	{
		elem: (data) => (
			<div className={styles.flexColumn}>
				<CarImage img={data?.logo_link} type={data?.cartype_name} />
				<span className={styles.lable}>{data.brand}</span>
				<Link to={`/detail/${data.id}`} className={styles.link}>
					รายละเอียดเพิ่มเติม <i className="fa-solid fa-angle-right"></i>
				</Link>
			</div>
		),
	},
	{
		elem: (data) => (
			<div className={styles.flexColumn}>
				<StickyContent data={data} />
			</div>
		),
		nonePadding: true,
	},
	{
		elem: (data) => (
			<div className={styles.flexColumn}>
				<CarType type={data.cartype_name} />
			</div>
		),
		nonePadding: true,
	},
	{
		elem: (data, min) => {
			const isMin = getConditionHighligth('recomend_retail_price_new', data, min.min, true);
			return (
				<div className={styles.flexColumn}>
					<span style={isMin} className={styles.lableSecondary}>
						{Object.keys(isMin).length > 0 && (
							<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
						)}
						ราคา {data.recomend_retail_price_new} บาท
					</span>
					{data.remark_price && <span className={styles.lableRemark}>{data.remark_price}</span>}
				</div>
			);
		},
		nonePadding: true,
	},
	{
		name: 'วันที่อนุมัติ',
		elem: (data) =>
			data?.approve_date ? (
				<span className={styles.mSpan}>{moment(data.approve_date).format('D/M/yyyy')}</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'คาดการณ์อัตราสรรพสามิต',
		selector: 'tax_rate_new',
		size: 'l',
	},
	{
		name: 'ผลิต/นำเข้า',
		selector: 'factory',
	},
	{
		name: 'รุ่นปีที่ผลิต',
		selector: 'model_year',
		size: 'l',
	},
	{
		name: 'ปีที่เริ่มผลิต',
		selector: 'year',
		size: 'l',
	},
	{
		name: 'เครื่องยนต์',
		selector: 'engine_name',
		size: 'm',
	},
	{
		name: 'ประเภทเชื้อเพลิงที่ใช้ได้',
		selector: 'fuel_name',
		size: 's',
	},
	{
		name: 'ปริมาตรความจุกระบอกสูบ',
		elem: (data) =>
			['', '0', ' '].includes(data.capacity_cylinder) ? (
				<div className={styles.dashSpan} />
			) : (
				<span className={styles.mSpan}>{numberWithCommaOnly(data.capacity_cylinder)}</span>
			),
		unit: 'ซีซี',
		size: 's',
	},
	{
		name: 'ระบบเกียร์',
		selector: 'gear_name',
		size: 's',
	},
	{
		name: 'จำนวนระดับเกียร์',
		selector: 'gear_speed',
		size: 's',
	},
	{
		elem: () => (
			<div className={styles.flexColumn}>
				<span className={styles.mainTopic}>แบตเตอรี่</span>
				<span className={styles.secondaryTopic}>( รถยนต์ไฟฟ้า )</span>
				<div className={styles.line} />
			</div>
		),
		colSpan: true,
	},
	{
		name: 'ยี่ห้อแบตเตอรี่',
		selector: 'battery_brand',
	},
	{
		name: 'มอเตอร์',
		selector: 'motor',
	},
	{
		name: 'ประเภทแบตเตอรี่',
		selector: 'battery_type',
	},
	{
		name: 'แรงดันแบตเตอรี่',
		// selector: 'nominal_voltage',
		elem: (data, highlight) => {
			const isMax = getConditionHighligth('nominal_voltage', data, highlight.max, false, true);
			return data.cartype_name != 'ICE' ? (
				data?.nominal_voltage && data?.nominal_voltage != '-' && (
					<span style={isMax} className={styles.mSpan}>
						{numberWithCommaOnly(data.nominal_voltage)}
					</span>
				)
			) : (
				<span className={styles.dashSpan} />
			);
		},
		unit: 'Volt',
	},
	{
		name: 'ความจุแบตเตอรี่',
		// selector: 'battery_capacity',
		elem: (data, highlight) => {
			const isMax = getConditionHighligth('battery_capacity', data, highlight.max, false, true);
			return data.cartype_name != 'ICE' ? (
				data?.nominal_voltage && data?.nominal_voltage != '-' && (
					<span style={isMax} className={styles.mSpan}>
						{numberWithCommaOnly(data.battery_capacity)}
					</span>
				)
			) : (
				<span className={styles.dashSpan} />
			);
		},
		unit: 'Ah',
	},
	{
		name: 'ประเภทชาร์จเจอร์',
		selector: 'type_charge',
	},
	{
		name: 'Charger Voltage',
		selector: 'on_board_charger',
	},
	{
		elem: () => (
			<div className={styles.flexColumn}>
				<span className={styles.mainTopic}>พลังงาน และ สิ่งแวดล้อม</span>
				<div className={styles.line} />
			</div>
		),
		colSpan: true,
	},
	{
		name: 'ปริมาณออกไซต์ของไนโตรเจน (NOx)',
		elem: (data, min) => {
			const isMin = getConditionHighligth('no_x_amount', data, min.min);
			return data?.no_x_amount && data?.no_x_amount != '-' ? (
				<span style={isMin} className={styles.mSpan}>
					{numberWithCommaOnly(data.no_x_amount)}
				</span>
			) : (
				<span className={styles.dashSpan} />
			);
		},
		unit: 'กรัม/กม.',
	},
	{
		name: 'ปริมาณการปล่อยฝุ่นละออง/มลพิษอนุภาค (PM)',
		elem: (data, min) => {
			const isMin = getConditionHighligth('particulate', data, min.min);
			return data?.particulate && data?.particulate != '-' ? (
				<span style={isMin} className={styles.mSpan}>
					{data.particulate}
				</span>
			) : (
				<span className={styles.dashSpan} />
			);
		},

		unit: 'กรัม/กม.',
	},
	{
		name: 'อัตราการปล่อยก๊าซ CO2',
		elem: (data, min) => {
			const isMin = getConditionHighligth('emissions_CO2', data, min.min);
			return data?.emissions_CO2 && data?.emissions_CO2 != '-' && data?.emissions_CO2 != '0' ? (
				<span style={isMin} className={styles.mSpan}>
					{numberWithCommaOnlyCO2(data.emissions_CO2)}
				</span>
			) : (
				<span style={border_span} className={styles.mSpan}>
					0
				</span>
			);
		},
		unit: 'กรัม/กม.',
	},
	{
		name: 'อัตราการใช้พลังงานรวม (Combined)',
		elem: (data, min) => {
			const isMin = getConditionHighligth('energy_combined_rate', data, min.min);
			if (data.cartype_name == 'ICE') {
				return (data?.energy_combined_rate && data?.energy_combined_rate != '-') || '0' ? (
					<span style={isMin} className={styles.mSpan}>
						{numberWithCommaOnly(data.energy_combined_rate)}
					</span>
				) : (
					<span className={styles.dashSpan} />
				);
			} else {
				return data?.energy_combined_rate && !['-', '0'].includes(data?.energy_combined_rate) ? (
					<span style={isMin} className={styles.mSpan}>
						{numberWithCommaOnly(data.energy_combined_rate)}
					</span>
				) : (
					<span className={styles.dashSpan} />
				);
			}
		},
		unit: CAR_TYPE_UNIT,
	},
	{
		name: 'อัตราการใช้พลังงานนอกเมือง (Extra-Urban)',
		elem: (data, min) => {
			if (data.cartype_name == 'ICE') {
				const isMin = getConditionHighligth('rate_energy_ex_urban', data, min.min);
				return data?.rate_energy_ex_urban && data?.rate_energy_ex_urban != '-' ? (
					<span style={isMin} className={styles.mSpan}>
						{numberWithCommaOnly(data.rate_energy_ex_urban)}
					</span>
				) : (
					<span className={styles.dashSpan} />
				);
			} else {
				const isMin = getConditionHighligth('rate_energy_ex_elec', data, min.min);
				return data?.rate_energy_ex_elec && data?.rate_energy_ex_elec != '-' ? (
					<span style={isMin} className={styles.mSpan}>
						{numberWithCommaOnly(data.rate_energy_ex_elec)}
					</span>
				) : (
					<span className={styles.dashSpan} />
				);
			}
		},
		unit: CAR_TYPE_UNIT,
	},
	{
		name: 'อัตราการใช้พลังงานในเมือง (Urban)',
		elem: (data, min) => {
			if (data.cartype_name == 'ICE') {
				const isMin = getConditionHighligth('rate_energy_urban', data, min.min);
				return data?.rate_energy_urban && data?.rate_energy_urban != '-' ? (
					<span style={isMin} className={styles.mSpan}>
						{numberWithCommaOnly(data.rate_energy_urban)}
					</span>
				) : (
					<span className={styles.dashSpan} />
				);
			} else {
				const isMin = getConditionHighligth('rate_energy_urban_elec', data, min.min);
				return data?.rate_energy_urban_elec && data?.rate_energy_urban_elec != '-' ? (
					<span style={isMin} className={styles.mSpan}>
						{numberWithCommaOnly(data.rate_energy_urban_elec)}
					</span>
				) : (
					<span className={styles.dashSpan} />
				);
			}
		},
		unit: CAR_TYPE_UNIT,
	},
	{
		name: 'ค่าใช้จ่ายด้านพลังงานต่อเดือน',
		elem: (data, min) => {
			const isMin = getConditionHighligth('price_per_m', data, min.min);
			return data?.price_per_m && data.price_per_m != '-' ? (
				<span style={isMin} className={styles.mSpan}>
					{data.price_per_m} บาท
				</span>
			) : (
				<span className={styles.dashSpan} />
			);
		},
		unit: 'บาท',
	},
	{
		elem: () => (
			<div className={styles.flexColumn}>
				<span className={styles.mainTopic}>มาตรฐานการตรวจสอบ</span>
				<div className={styles.line} />
			</div>
		),
		colSpan: true,
	},
	{
		name: 'มาตรฐาน มอก.',
		elem: (data) =>
			data?.check_car_standard?.tis == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน Euro 4',
		elem: (data) =>
			data?.check_car_standard?.euro_4 == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน Euro 5',
		elem: (data) =>
			data?.check_car_standard?.euro_5 == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน Euro 6',
		elem: (data) =>
			data?.check_car_standard?.euro_6 == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน ABS+ESC',
		elem: (data) =>
			data?.check_car_standard?.abs_esc == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน UN R13',
		elem: (data) =>
			data?.check_car_standard?.un_reg_13 == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน UN R13H',
		elem: (data) =>
			data?.check_car_standard?.un_reg_13h == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน UN R94',
		elem: (data) =>
			data?.check_car_standard?.un_reg_94 == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน UN R95',
		elem: (data) =>
			data?.check_car_standard?.un_reg_95 == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
	{
		name: 'มาตรฐาน UN R100',
		elem: (data) =>
			data?.check_car_standard?.un_reg_100 == '1' ? (
				<span style={{ color: 'green' }} className={styles.mSpan}>
					<i className="fa-solid fa-star" style={{ color: 'gold', marginRight: 5 }}></i>
					ผ่านการทดสอบ
				</span>
			) : (
				<span className={styles.dashSpan} />
			),
	},
];

const ComparisonTablePage = () => {
	const [loading, setLoading] = useState(true);
	const [car, setCar] = useState([]);
	const [selected] = useCompareContext();
	const navigate = useNavigate();
	const [minArr, setMinArr] = useState([]);

	const fetchDataCar = async () => {
		try {
			let id = [];
			selected.map((item) => {
				id.push(item.id);
			});
			const res = await getCompareCarData({ id });
			setLoading(false);
			setCar(res.data.data);
		} catch ({ response }) {
			// alert(JSON.stringify(response))
		}
	};

	//   set value highlight data
	useEffect(() => {
		setupdataArr();
	}, [car]);

	const replaceComma = (number) => {
		if (number == '' || number == '-' || number == '0') return number;
		const val = `${number}`.replace(/,/g, '');
		return parseFloat(val);
	};

	const getMin = (key, data, compareData) => {
		const oldData = data?.[`min_${key}`];
		const newData = compareData?.[key];
		if (oldData == '' || oldData == '-') {
			return oldData;
		}

		return replaceComma(oldData) < replaceComma(newData) ? oldData : newData;
	};

	const getMax = (key, data, compareData) => {
		const oldData = data?.[`max_${key}`];
		if (oldData == '' || oldData == '-' || data.cartype_name == 'ICE') {
			return oldData;
		}
		const newData = compareData?.[key];
		return replaceComma(oldData) > replaceComma(newData) ? oldData : newData;
	};
	//
	const setupdataArr = () => {
		let dataMin = {};
		let dataMax = {};
		car.map((items) => {
			dataMin = {
				min_no_x_amount: getMin('no_x_amount', dataMin, items),
				min_particulate: getMin('particulate', dataMin, items),
				min_recomend_retail_price_new: getMin('recomend_retail_price_new', dataMin, items),
				min_emissions_CO2: getMin('emissions_CO2', dataMin, items),
				min_rate_energy: getMin('rate_energy', dataMin, items),
				min_rate_energy_elec: getMin('rate_energy_elec', dataMin, items),
				min_rate_energy_ex_elec: getMin('rate_energy_ex_elec', dataMin, items),
				min_rate_energy_ex_urban: getMin('rate_energy_ex_urban', dataMin, items),
				min_rate_energy_urban: getMin('rate_energy_urban', dataMin, items),
				min_rate_energy_urban_elec: getMin('rate_energy_urban_elec', dataMin, items),
				min_price_per_m: getMin('price_per_m', dataMin, items),
				min_energy_combined_rate: getMin('energy_combined_rate', dataMin, items),
			};
			dataMax = {
				max_nominal_voltage: getMax('nominal_voltage', dataMax, items),
				max_battery_capacity: getMax('battery_capacity', dataMax, items),
			};
		});
		setMinArr({ min: dataMin, max: dataMax });
	};

	useEffect(() => {
		if (selected?.length == 0) {
			navigate('/');
		}
		fetchDataCar();
		return () => {
			if (request.length > 0) {
				request[0]();
			}
		};
	}, []);

	return (
		<Page title="ตารางเปรียบเทียบรถยนต์">
			<div className={styles.headerText}>ตารางเปรียบเทียบ</div>
			{loading ? (
				<div className={styles.loadingContainer}>
					<CarListLoading />
					<Stack
						sx={{ width: '100%', maxWidth: 1380 }}
						direction="row"
						spacing={3}
						marginTop={3}
						alignItems="center"
						justifyContent="center"
					>
						{Array.from({ length: selected.length }).map((item, index) => (
							<Stack
								key={index}
								sx={{ width: '100%', maxWidth: 300 }}
								spacing={2}
								alignContent="center"
								justifyContent="center"
								direction="column"
							>
								<Skeleton variant="rectangular" sx={{ width: '100%' }} height={120} />
								<Skeleton width="80%" variant="rectangular" height={30} />
								<Stack direction="column">
									<Skeleton />
									<Skeleton width="60%" />
								</Stack>
							</Stack>
						))}
					</Stack>
				</div>
			) : (
				<ScrollContainer hideScrollbars={false} className={styles.scrollContrainer}>
					<TableCompare
						columns={car.length ?? 2}
						content={TABLE_TOPIC_LIST}
						data={car}
						highlight={minArr}
						gridClassName={styles.grid}
					/>
				</ScrollContainer>
			)}
		</Page>
	);
};

export default ComparisonTablePage;
