import React from 'react';
import styles from './Section-1.module.scss';
import mockImage from '../../assets/carDetail/backdrop/icons/non-image.png';
import { useState } from 'react';
import ScrollContainer from 'react-indiana-drag-scroll';
import classNames from 'classnames';
import LazyLoad from 'react-lazy-load';

const ImageWithCover = ({ src, type }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);

	return (
		<div className={styles.showingImageBox}>
			<LazyLoad height="100%" width="100%">
				<img
					src={src}
					onError={({ currentTarget }) => {
						currentTarget.src = mockImage;
						setIsLoaded(true);
						setIsError(true);
					}}
					onLoad={() => {
						setIsLoaded(true);
					}}
					className={styles.showingImage}
				/>
			</LazyLoad>
			<div
				className={classNames(styles.imageLoadingCover, {
					[styles.loaded]: isLoaded,
				})}
			>
				<i className="fa-regular fa-image fa-flip"></i>
			</div>
		</div>
	);
};

const ImageItemWithCover = ({ src, callback, className, imgKey }) => {
	const [isLoaded, setIsLoaded] = useState(false);
	const [isError, setIsError] = useState(false);

	return (
		<div className={className} onClick={() => callback(imgKey)}>
			<LazyLoad height="100%" width="100%">
				<img
					src={src}
					onLoad={() => {
						setIsLoaded(true);
					}}
					onError={({ currentTarget }) => {
						currentTarget.src = mockImage;
						setIsLoaded(true);
						setIsError(true);
					}}
				/>
			</LazyLoad>

			<div
				className={classNames(styles.imageLoadingCover, {
					[styles.loaded]: isLoaded,
				})}
			>
				<i className="fa-regular fa-image fa-flip"></i>
			</div>
		</div>
	);
};

const CarImageViewer = ({ list, remark, type }) => {
	const [actived, setActived] = useState(0);

	const handleActiveImage = (index) => {
		setActived(index);
	};

	const handleScrollToNextSection = () => {
		window.scrollTo(0, 1);
	};

	return (
		<div className={styles.containerViewer}>
			<ImageWithCover src={list[actived]} type={type} />
			<div className={styles.listImageBox}>
			{remark
				? remark.replace(/\s/g, '') && (
						<div className={styles.remarkBlock}>
							<b className={styles.remarkTitle}>***หมายเหตุ&nbsp;: &nbsp;</b>
							{remark}
						</div>
				  )
				: ''}
				<div className={styles.listImage}>
					<ScrollContainer hideScrollbars className={styles.scrollContrainer}>
						{list?.map((img, key) => (
							<ImageItemWithCover
								key={key}
								src={img + '&mode=thumbnail'}
								imgKey={key}
								className={classNames(styles.listImageItem, {
									[styles.listImageItemActive]: actived == key,
								})}
								callback={handleActiveImage}
							/>
						))}
					</ScrollContainer>
				</div>
				<div className={styles.nextSection} onClick={() => handleScrollToNextSection()}>
					<i className="fa-solid fa-angles-down"></i>&emsp;รายละเอียดเพิ่มเติม
				</div>
			</div>
		</div>
	);
};

export default CarImageViewer;
