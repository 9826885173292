import classNames from 'classnames';
import React from 'react';
import useDetailContext from 'src/contexts/detailContext';
import CarImageViewer from './CarImageViewer';
import styles from './Section-1.module.scss';

const Section1 = ({ parentClass }) => {
	const [{ data }] = useDetailContext();
	return (
		<div className={classNames(styles.containerSection1, styles?.[parentClass])}>
			<CarImageViewer
				list={[...([data?.logo_link] || []), ...(data?.pic_car_external || [])]}
				remark={data?.remark_price}
				type={data?.cartype_name}
			/>
		</div>
	);
};

export default Section1;
