import handleAxios from './Axios';

const BASE_URL_BACKEND = 'https://car.go.th/api/';
const BASE_URL_BACKEND_PRODUCTION = 'https://car.go.th/api/';
const BASE_URL_BACKEND_V2 = `${BASE_URL_BACKEND}v2/landing-page/`;
const BASE_URL_BACKEND_STICKER = `${BASE_URL_BACKEND}v1/`;
export const BASE_URL_FONTEND_STICKER = `http://172.168.223.5:6001/`;

export const getDataCarList = async (data = '') => {
	const res = await handleAxios({
		url: BASE_URL_BACKEND_V2 + `cars${data ? '?' + data : ''}`,
	});
	return res;
};

export const getFilterList = async () => {
	const res = await handleAxios({
		url: BASE_URL_BACKEND_V2 + 'option-data',
	});
	return res;
};

export const getCompareCarData = async (data) => {
	const res = await handleAxios({
		url: BASE_URL_BACKEND_V2 + 'compare',
		method: 'POST',
		data,
	});
	return res;
};

export const getCarDetail = async (id = '') => {
	const res = await handleAxios({
		url: BASE_URL_BACKEND_V2 + id,
	});
	return res;
};

export const searchSticker = async (queryString) => {
	const res = await handleAxios({
		url: BASE_URL_BACKEND_V2 + `eco-sticker-car?${queryString}`,
		method: 'GET',
	});
	return res;
};

export const getStickerData = async (data) => {
	let response = await handleAxios({
		url: BASE_URL_BACKEND_STICKER + 'eco-sticker/by-car-req',
		method: 'POST',
		data: data,
	});

	return response;
};

export const getStickerDataByID = async (id) => {
	let response = await handleAxios({
		url: BASE_URL_BACKEND_STICKER + `eco-sticker/public/by-car-id?car_id=${id}`,
		method: 'GET',
	});

	return response;
};

export const getDetailtyre = async (data) => {
	return await handleAxios({
		url: `https://tyre.go.th/api/api/v2/car_connect/get_tire_request_from_qr_running`,
		method: "POST",
		data: data,
	});
};

export const getNewsOnLandingPage = async queryParam => {
	return await handleAxios({
		url: BASE_URL_BACKEND_STICKER + `news/landing-page?${queryParam}`,
		method: "GET",
	});
}

export const getNewsById = async id =>{
	return await handleAxios({
		url: BASE_URL_BACKEND_STICKER + `news/landing-page/${id}`,
		method: "GET",
	});
}


export const getEuroContent = async (queryParam) =>{
	return await handleAxios({
		url: BASE_URL_BACKEND_STICKER + `landing-page/car-euro?${queryParam}`,
		method: "GET",
	});
}