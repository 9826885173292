import classNames from 'classnames';
import React from 'react';
import styles from "./HeaderTopic.module.scss";

const HeaderTopic = ({ classmName, topic }) => {
    return (
        <div className={styles.topicContainer}>
            <div className={classNames(styles.topicBox, {
                [classmName]: classmName
            })}>
                <div className={styles.topicLine}></div>
                <span className={styles.topicText}>{topic}</span>
            </div>
        </div>

    );
};

export default HeaderTopic;