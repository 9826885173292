import { linkClasses } from "@mui/material";
import React, { useEffect, useState } from "react";
import styles from "./SearchInput.module.scss";

const SearchInput = ({ onSearch, textSearch, placeholder, frontComp, mask, onBlurSubmit = true }) => {
	const [inputText, setInputText] = useState(textSearch);
	const handleOnSubmit = (e) => {
		e.preventDefault();
		onSearch(inputText ?? '');
	};

	const makeOnKeydown = (evt) => {
		let fieldVal = evt.target.value;
		let key = evt.keyCode;
		let char = String.fromCharCode(96 <= key && key <= 105 ? key - 48 : key);

		if (evt.which != 32 && !isNaN(parseInt(char))) {
			let temp = paddy(fieldVal + char, mask.len, mask.maskText);
			setInputText(temp);
			return false;
		} else if (key == 8) {
			let temp = paddyShift(fieldVal, mask.len, mask.maskText);
			setInputText(temp);
			return false;
		} else {
			return false;
		}
	};

	const paddy = (val, len, maskText = "0") => {
		return (maskText + val).slice(-len);
	};

	const paddyShift = (val, len, maskText = "0") => {
		return (maskText + val.slice(0, val.length - 1)).slice(-len);
	};

	const makeOnPast = (evt) => {
		let temp = paddy(evt.clipboardData.getData("Text"), mask.len, mask.maskText);
		setInputText(temp);
	};

	useEffect(() => {
		setInputText(mask ? textSearch ?? mask.maskText : textSearch);
	}, []);

	return (
		<div className={styles.inputContainer}>
			{frontComp && frontComp}
			<form className={styles.formContainer} onSubmit={handleOnSubmit}>
				<input
					className={styles.inputBox}
					value={inputText ?? ''}
					onChange={(e) => {
						if (!mask) setInputText(e.target.value);
					}}
					placeholder={placeholder ? placeholder : "ค้นหา(ยี่ห้อ รุ่นรถยนต์ ผู้ผลิต ผู้นำเข้า)"}
					name="search"
					autoComplete="off"
					onBlur={(e) => {
						if (onBlurSubmit) {
							handleOnSubmit(e);
						}
					}}
					onKeyDown={(e) => {
						if (mask) makeOnKeydown(e);
					}}
					onPaste={(e) => {
						if (mask) makeOnPast(e);
					}}
				/>
				<div className={styles.inputBtnSection}>
					<button className={styles.inputBtnSearch}>
						<i className="fa-solid fa-magnifying-glass"></i>
					</button>
				</div>
			</form>
		</div>
	);
};

export default SearchInput;
