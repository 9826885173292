import { createContext, useContext, useState } from 'react';

export const DetailContext = createContext();

export const DetailProvider = (props) => {
	const [detailContext, setDetailContext] = useState({
		data: {},
		sticker: '',
		isLoaded: false,
		error: {
			fetchError: false,
			stickerError: false,
			getTyreError: false,
		},
		isGenerating: false,
	});

	return <DetailContext.Provider value={[detailContext, setDetailContext]}>{props.children}</DetailContext.Provider>;
};

const useDetailContext = () => useContext(DetailContext);

export default useDetailContext;
