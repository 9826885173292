import { Container, Stack, Typography } from "@mui/material";
import React from "react";
import { Page } from "src/components/GobalElement";
import styles from "./Articles.module.scss";
import { styled } from "@mui/styles";
import { Box } from "@mui/system";

const DATA_CONTENT_SECTION_1 = [
	{
		url: "https://www.youtube.com/embed/EthqGmlmxEU",
		title: "Eco Sticker ป้ายข้อมูลรถยนต์ตามมาตรฐานสากล",
		// <iframe width="560" height="315" src="https://www.youtube.com/embed/EthqGmlmxEU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	},
	{
		url: "https://www.youtube.com/embed/g4AaKZxXs1o",
		title: "ECO Sticker ยกระดับความคุ้มครองผู้บริโภคสู่ความโปร่งใส",
		// <iframe width="560" height="315" src="https://www.youtube.com/embed/g4AaKZxXs1o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	},
	{
		url: "https://www.youtube.com/embed/jQa0c1mbYSs",
		title: "ปัจจัยที่มีผลต่ออัตราการใช้น้ำมัน",
		// <iframe width="560" height="315" src="https://www.youtube.com/embed/jQa0c1mbYSs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
	},
];

const NewsEcoSticker = () => {
	return (
		<Page title="ECO Sticker">
			<div className={styles.pageContent}>
				<Container>
					<Stack spacing={4}>
						<Typography
							// textAlign={"center"}
							variant="span"
							fontSize={48}
							fontWeight={400}
							color={"#008000"}
						>
							ECO Sticker ยกระดับการคุ้มครองผู้บริโภคในประเทศไทย สู่ความโปร่งใส
						</Typography>
						<Typography sx={{ textIndent: "50px" }} fontSize={18} fontWeight={400} variant="p">
							ในอดีต ผู้บริโภคในประเทศไม่สามารถเปรียบเทียบสมรรถนะของรถยนต์แต่ละรุ่น เช่น อัตราการใช้น้ำมัน
							อัตราการปล่อยก๊าซคาร์บอนไดออกไซด์ (CO2) และมาตรฐานความปลอดภัย เป็นต้น ในปัจจุบัน
							ป้ายข้อมูลรถยนต์ตามมาตรฐานสากล หรือ ECO Sticker
							จะช่วยให้ผู้บริโภคและประชาชนทั่วไปได้รับข้อมูลสมรรถนะรถยนต์ที่เที่ยงตรง โปร่งใส
							และเป็นมาตรฐานเดียวกัน สามารถนำไปเปรียบเทียบสมรรถนะของรถยนต์แต่ละรุ่น ในด้าน “สะอาด ประหยัด
							ปลอดภัย” เพื่อประโยชน์ต่อการพิจารณาเลือกซื้อรถยนต์ได้
						</Typography>
						<Stack direction={"row"} justifyContent={"center"} spacing={2}>
							{DATA_CONTENT_SECTION_1.map((item, i) => (
								<CardContainer>
									<Stack justifyContent={"center"}>
										<iframe
											height={"100%"}
											width="100%"
											src={item.url}
											title="YouTube video player"
											frameborder="0"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
											allowfullscreen
										/>
										<Typography
											p={2}
											variant="p"
											fontSize={22}
											fontWeight={400}
											textAlign={"center"}
										>
											{item.title}
										</Typography>
									</Stack>
								</CardContainer>
							))}
						</Stack>
					</Stack>
				</Container>
			</div>
		</Page>
	);
};

const CardContainer = styled(Box)({
	width: 350,
	overflow: "hidden",
	boxShadow: "rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px",
	borderRadius: 5,
});

export default NewsEcoSticker;
