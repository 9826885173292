import { useRoutes } from "react-router-dom";

// layouts
import MainLayout from "../layouts";
import ComparisonLayout from "../layouts/ComparisonLayout";

// pages
import {
	ComparisonPage,
	ComparisonTablePage,
	StickerPage,
	AboutUsPage,
	AboutEcoSticker,
	AnnouncementWithAPI,
	AutomotiveEventWithAPI,
	Announcement,
	AutomotiveEvent,
	EcoStickerPage,
	WhatIsEcoSticker,
	WhatIsTIS,
	EcoStickerBenefit,
	EcoStickerAbility,
	EcoStickerSafety,
	WhatIsTax,
	WhatIsInternationalStandard,
	CarDetailV2,
} from "../pages";
import NotFound from "../components/NotFound";
import DetailLayout from "src/layouts/DetailLayout";
import KnowingEcoSticker from "src/pages/Articles/KnowingEcoSticker";
import FlowReuestEcoSticker from "src/pages/Articles/FlowReuestEcoSticker";
import MobileApplication from "src/pages/Articles/MobileApplication";
import NewsEcoSticker from "src/pages/Articles/NewsEcoSticker";

// ----------------------------------------------------------------------

const Router = () => {
	return useRoutes([
		{
			element: <MainLayout />,
			children: [
				{
					element: <ComparisonLayout />,
					children: [
						{ index: true, element: <ComparisonPage /> },
						{ path: "car-comparison-table", element: <ComparisonTablePage /> },
					],
				},
				{
					path: "article",
					children: [
						{ index: true, element: <AboutEcoSticker /> },
						{ path: "news-eco-sticker", element: <NewsEcoSticker /> },
						{ path: "what-is-eco-sticker", element: <WhatIsEcoSticker /> },
						{ path: "knowing-eco-sticker", element: <KnowingEcoSticker /> },
						{ path: "what-is-tis", element: <WhatIsTIS /> },
						{ path: "eco-sticker-benefit", element: <EcoStickerBenefit /> },
						{ path: "eco-sticker-safety", element: <EcoStickerSafety /> },
						{ path: "eco-sticker-ability", element: <EcoStickerAbility /> },
						{ path: "what-is-tax", element: <WhatIsTax /> },
						{ path: "what-is-international-standard", element: <WhatIsInternationalStandard /> },
						// new articla
						{ path: "flow-request-eco", element: <FlowReuestEcoSticker /> },
						{ path: "mobile-application", element: <MobileApplication /> },
					],
				},
				{ path: "sticker", element: <StickerPage /> },
				{ path: "about-us", element: <AboutUsPage /> },
				// { path: 'article', element: <MainPage /> },
				// {
				// 	path: "news",
				// 	children: [
				// 		{ index: true, element: <Announcement /> },
				// 		{ path: "event/:id", element: <AutomotiveEventWithAPI /> },
				// 	],
				// },
				{
					path: 'news',
					children: [
						{ index: true, element: <AnnouncementWithAPI /> },
						{ path: 'event/:id', element: <AutomotiveEventWithAPI /> },
					],
				},
				// { path: 'detail/:id', element: <CarDetail /> },
				{ path: "sticker-search", element: <EcoStickerPage /> },
				{ path: "*", element: <NotFound code="404" title="ไม่พบหน้า" /> },
			],
		},
		{ path: "detail/", element: <DetailLayout />, children: [{ path: ":id", element: <CarDetailV2 /> }] },
	]);
};

export default Router;
