import classNames from 'classnames';
import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { MENU_LIST_LEFT, MENU_LIST_RIGHT } from '../../configs/menuConfig';
import styles from "./MenuMobile.module.scss";
import Logo from '../../assets/eco_logo_png.png'

const MenuMobile = ({ open, handleClose }) => {
    return (
        <div className={classNames(styles.modalBackdrop, { [styles.modalOpen]: open })}>
            <div className={styles.modalContainer}>
                <Link to='/' className={styles.navbarItemLogo}>
                    <img src={Logo} className={styles.navbarItemLogoImg} />
                </Link>
                <div className={styles.modalContent}>
                    <div className={styles.modalContentHome}>
                        <NavbarItem data={{
                            title: 'หน้าแรก',
                            path: '/',
                            icon: 'fa-solid fa-house'
                        }} className={styles.navbarItemHome} />
                    </div>
                    {
                        MENU_LIST_LEFT.map((item, key) => {
                            return (
                                <NavbarItem key={key} data={item} className={styles.navbarItem} />
                            )
                        })
                    }
                    {
                        MENU_LIST_RIGHT.map((item, key) => {
                            return (
                                <NavbarItem key={key} data={item} className={styles.navbarItem} />
                            )
                        })
                    }
                    <div className={styles.modalContentClose}>
                        <div className={styles.navbarItemClose} onClick={() => handleClose(false)}>
                            <i className='fa-solid fa-xmark' />
                            <span>ปิด</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const NavbarItem = ({ data, className }) => {
    let resolved = useResolvedPath(data.path);
    let match = useMatch({ path: resolved.pathname, end: true });
    return (
        <Link to={data.path} className={classNames(className, { [styles.active]: match })}>
            <i className={data.icon} />
            <span>{data.title}</span>
        </Link>
    )
}

export default MenuMobile;