export const serializeToUrl = (obj) => {
    let str = [];
    for (let p in obj)
        if (obj.hasOwnProperty(p) && obj[p] != '') {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return str.join("&");
}

export const serializeSortToUrl = (sort) => {
    let str = [];
    for (let p in sort)
        if (sort.hasOwnProperty(p) && sort[p] != '') {
            let sorted = sort[p] == 1 ? 'asc' : 'desc'
            str.push(p + ":" + sorted);
        }
    return str.join(",");
}