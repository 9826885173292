import { createContext, useContext, useState } from "react";

export const CompareContext = createContext()

export const CompareProvider = props => {
    const [compareContext, setCompareContext] = useState([])

    return (
        <CompareContext.Provider value={[compareContext, setCompareContext]}>
            {props.children}
        </CompareContext.Provider>)
}

const useCompareContext = () => useContext(CompareContext);

export default useCompareContext;