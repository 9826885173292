import { styled } from "@mui/styles";
import { forwardRef, useEffect } from "react";
import styles from "./GobalElement.module.scss";

export const RootStyle = ({ children }) => {
    return (
        <div className={styles.rootStyle}>{children}</div>
    )
}

export const MainStyle = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
    minHeight: 'calc(100vh - 105px)',
}));

export const Container = ({ children }) => {
    return (
        <div className={styles.container}>{children}</div>
    )
}

export const Page = forwardRef(({ title, children }, ref) => {
    useEffect(() => {
        if(title) document.title = title
    }, [title])

    return (
        <div className={styles.page} ref={ref}>{children}</div>
    )
})

